import { useEffect, useState } from "react";

import { MARKETS_BLOCK_NUMBER_MAP } from "../config/env";
import { useApolloClients } from "../context/ApolloClientsContext";
import { useCurrentChain } from "../hooks/useCurrentChain";
import { globalBaseState } from "../state/global/slice";
import { useAppSelector } from "../state/hooks";
import { isLessThan, multipliedBy, minus } from "../utils";
import { useMarketsQuery } from "./__generated_tradeV2__/types-and-hooks";
import { isEmpty } from "lodash";
import { useMultiTokensV2 } from "../hooks/useRequest";
import { useAllTokens } from "hooks/useAllTokens";

const useMarketGraph = () => {
  const { blockHeightBefore24h } = useAppSelector(globalBaseState);
  const { clientTradeV2 } = useApolloClients();
  const { currentChainId } = useCurrentChain();
  const { appTimer } = useAppSelector(globalBaseState);
  const allTokens = useAllTokens();
  const [markets, setMarkets] = useState([]);

  const { data, refetch } = useMarketsQuery({
    variables: {
      block24h: Number(
        isLessThan(
          blockHeightBefore24h,
          MARKETS_BLOCK_NUMBER_MAP[currentChainId]
        )
          ? MARKETS_BLOCK_NUMBER_MAP[currentChainId]
          : blockHeightBefore24h
      ),
    },
    client: clientTradeV2,
  });

  useEffect(() => {
    refetch();
  }, [appTimer]);

  useEffect(() => {
    if (isEmpty(data)) {
      return;
    }
    const { markets = [], past = [] } = data;

    const refactorMarkets = markets.map((item) => {
      const before24HData = past.find((pool) => pool.id === item.id);

      const openInterestVolumeBase = Math.max(
        Number(item.globalPosition.longSize),
        Number(item.globalPosition.shortSize)
      );
      return {
        ...item,
        volume24H: minus(item?.volumeUSD, before24HData?.volumeUSD || 0),
      };
    });
    setMarkets(refactorMarkets);
  }, [data]);

  return { data: markets };
};

export default useMarketGraph;
