import React from "react";
import Paper from "@mui/material/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Typography, useTheme } from "@mui/material";
import tokenomicsBg from "../../assets/index/tokenomics/tokenomics-bg.svg";
import { useAppBreakpoints } from "hooks/useAppBreakpoints";

export default function Token() {
  const theme = useTheme();
  const { isMatchMobile } = useAppBreakpoints();
  return (
    <div className="w-full flex justify-center pb-5 relative sm:pb-0">
      {!isMatchMobile && (
        <div className="absolute -z-0 -top-5 sm:-top-8">
          <img
            src={tokenomicsBg}
            alt=""
            className="z-[100] w-[1267px] h-[733px] sm:w-[467px] sm:h-[407px]"
          />
        </div>
      )}

      <div className="content flex flex-col relative z-1">
        <div>
          <div className="block-title text-center font-bold">
            Be part of Equation Ecosystem
          </div>
        </div>
        <div className="grid grid-cols-5 gap-1 sm:gap-1.5 mt-3.75 sm:mt-1.5">
          <div className="space-y-1.5 col-span-3 sm:col-span-5 sm:space-y-0.75">
            <div className="text-[28px] font-bold sm:text-lg leading-2.5 sm:leading-1 sm:text-center">
              Tokenomics
            </div>
            <div className="w-full text-xl sm:w-full sm:text-center pr-4.5 sm:pr-0 sm:text-base">
              <div className="text-textSecondary70 font-normal sm:text-center leading-normal space-y-0.75">
                <div>
                  In traditional Tokenomics models, the complex interests
                  between community users, the founding team, and early
                  investors often lead to disagreements and divergent consensus
                  during the operation and governance of the protocol. This
                  greatly hampers the sustainable development of the protocol.
                </div>
                <div>
                  Equation adopts an innovative hybrid Tokenomics model that
                  combines Fungible Tokens (FT) and Non-Fungible Tokens (NFT).
                  This hybrid model establishes exclusive incentive channels for
                  different roles in the ecosystem, aiming to unify the
                  long-term development goals of Equation while safeguarding the
                  interests of liquidity providers and community users.
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-1.5 sm:space-y-0.75 col-span-2 sm:col-span-5">
            <div className="text-[28px] font-bold sm:text-lg leading-2.5 sm:leading-1 sm:text-center">
              Community
            </div>
            <div className="col-span-2 font-normal text-textSecondary70 text-xl sm:text-center sm:text-base leading-normal">
              Join our community to stay updated to our pre-launch exclusive
              events.
            </div>
            <div className="grid grid-cols-2 gap-1 sm:gap-0.75 sm:mt-1">
              <Paper
                component="a"
                href="https://twitter.com/EquationDAO"
                target="_blank"
                className="flex items-center px-2 sm:px-1 py-1.5 sm:-y-0.75 space-x-0.5"
                sx={{
                  borderRadius: "4px",
                  "&:hover": {
                    background: `linear-gradient(0, rgba(255,255,255,0.05), rgba(255,255,255,0.05), rgba(255,255,255,0.05)), ${theme.palette.background.paper}`,
                  },
                }}
              >
                <FontAwesomeIcon
                  icon={brands("twitter")}
                  className="text-3xl sm:text-2xl"
                />
                <span className="text-textSecondary70 text-2xl sm:text-base">
                  Twitter
                </span>
              </Paper>
              <Paper
                component="a"
                href="https://discord.gg/equationdao"
                target="_blank"
                className="flex items-center px-2 sm:px-1 py-1.5 sm:-y-0.75 space-x-0.5 "
                sx={{
                  borderRadius: "4px",
                  "&:hover": {
                    background: `linear-gradient(0, rgba(255,255,255,0.05), rgba(255,255,255,0.05), rgba(255,255,255,0.05)), ${theme.palette.background.paper}`,
                  },
                }}
              >
                <FontAwesomeIcon
                  icon={brands("discord")}
                  className="text-3xl sm:text-2xl"
                />
                <span className="text-textSecondary70 text-2xl sm:text-base">
                  Discord
                </span>
              </Paper>
              <Paper
                component="a"
                href="https://t.me/EquationDao"
                target="_blank"
                className="flex items-center px-2 sm:px-1 py-1.5 sm:-y-0.75 space-x-0.5 "
                sx={{
                  borderRadius: "4px",
                  "&:hover": {
                    background: `linear-gradient(0, rgba(255,255,255,0.05), rgba(255,255,255,0.05), rgba(255,255,255,0.05)), ${theme.palette.background.paper}`,
                  },
                }}
              >
                <FontAwesomeIcon
                  icon={brands("telegram")}
                  className="text-3xl sm:text-2xl "
                />
                <span className="text-textSecondary70 text-2xl sm:text-base">
                  Telegram
                </span>
              </Paper>
              <Paper
                component="a"
                href="https://medium.com/@EquationDAO"
                target="_blank"
                className="flex items-center px-2 sm:px-1 py-1.5 sm:-y-0.75 space-x-0.5 rounded"
                sx={{
                  borderRadius: "4px",
                  "&:hover": {
                    background: `linear-gradient(0, rgba(255,255,255,0.05), rgba(255,255,255,0.05), rgba(255,255,255,0.05)), ${theme.palette.background.paper}`,
                  },
                }}
              >
                <FontAwesomeIcon
                  icon={brands("medium")}
                  className="text-3xl sm:text-2xl"
                />
                <span className="text-textSecondary70 text-2xl sm:text-base">
                  Medium
                </span>
              </Paper>
            </div>
            <div>
              <div className="col-span-2 text-textSecondary70 text-xl sm:text-center sm:text-base leading-normal">
                For collaboration -{" "}
                <Typography
                  component="a"
                  variant="inherit"
                  className="underline-offset-4 underline decoration-1"
                  href="mailto:market@equation.org"
                >
                  market@equation.org
                </Typography>
              </div>
              <div className="col-span-2 text-textSecondary70 text-xl sm:text-center sm:text-base leading-normal">
                For brand resources -{" "}
                <Typography
                  component="a"
                  variant="inherit"
                  className="underline-offset-4 underline decoration-1"
                  target="_blank"
                  href="https://github.com/EquationDAO/equation-assets"
                >
                  Media Kit
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
