// import React from "react";
import Box from "@mui/material/Box";
import { styled, useTheme, alpha, lighten } from "@mui/material/styles";
import cn from "classnames";
import topicIcon from "../../assets/index/topic/topic-support.svg";
import topicMobileIcon from "../../assets/index/topic/topic-support-m.svg";

import { useMediaQuery, Typography } from "@mui/material";
import abdkIcon from "../../assets/index/abdk-icon.svg";
import abdkWord from "../../assets/index/abdk-word.svg";
import FreeTradingFeeWarning from "../components/FreeTradingFeeWarning";
import Button from "@mui/material/Button";
import { useAppBreakpoints } from "../../hooks/useAppBreakpoints";

const Content = styled("div")(({ theme }) => ({
  "@media screen and (max-width: 768px)": {
    paddingTop: `16px`,
    paddingBottom: `0`,
  },
  "@media screen and (min-width: 961px) and (max-width: 1200px)": {
    paddingTop: `60px`,
    paddingBottom: `70px`,
  },
}));

const Topic = (props: any) => {
  const theme = useTheme();
  // const { address, isConnected } = useAccount();
  const matchSmall = useMediaQuery("(max-width: 768px)");
  const { isMatchMobile } = useAppBreakpoints();

  return (
    <Box className={cn("w-full relative flex justify-center pt-4 sm:pt-0.75")}>
      <div className="absolute -z-0 sm:w-full sm:top-2">
        <img
          src={isMatchMobile ? topicMobileIcon : topicIcon}
          className="w-auto z-[100]"
        />
      </div>
      <Content className="content flex-col relative pt-3 sm:pt-1.75">
        <div className="sm:col-span-6">
          <div
            className="text-[60px] text-center sm:text-left text-textPrimary leading-1 sm:leading-[1.34] font-bold sm:text-[30px]"
            style={{ fontFamily: "Noto Sans Hatran" }}
          >
            <Box
              component="span"
              sx={{
                background: "linear-gradient(45deg, #C7EE00, #1DFDE2)",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Zero Trading Fee
            </Box>{" "}
            Perpetual DEX
          </div>
          <div className="text-textSecondary90 gap-y-0.25 mt-2 sm:mt-1 text-[24px] leading-[1.34] sm:text-base font-normal">
            <div className="space-x-0.25 text-center sm:text-left">
              <span>Up to 150x Leverage and Loss Compensation Mechanism</span>
              <FreeTradingFeeWarning />
            </div>
            <div className="space-x-0.25 text-center mt-0.5 sm:text-left">
              <span>No sign up or deposit required</span>
            </div>
          </div>
          <div className="mt-3.75 flex justify-center items-center gap-[26px] sm:flex-col font-normal">
            <Typography
              component="a"
              href="https://github.com/EquationDAO/equation-contracts-v3/blob/main/contracts/audits/ABDK_Equation_EquationDAOV3_v_1_0.pdf"
              target="_blank"
              className="flex items-center justify-center sm:justify-center px-1.5 sm:px-0.5 sm:py-0.75 space-x-0.625 sm:space-x-0.5 text-xl sm:text-base sm:w-full"
              sx={{
                borderRadius: "4px",
                height: matchSmall ? 50 : 56,
                background: alpha("#FFFFFF", 0.06),
                backdropFilter: "blur(50px)",
                "&:hover": {
                  background: `linear-gradient(0, rgba(255,255,255,0.08), rgba(255,255,255,0.08), rgba(255,255,255,0.08)), ${theme.palette.background.paper}`,
                },
              }}
            >
              <span>Audit</span>
              <div className="flex items-center space-x-0.625 text-sm sm:space-x-0.25">
                <img src={abdkIcon} alt="" />
                <div className="">
                  <img src={abdkWord} alt="" />
                  <Typography variant="inherit" color="text.secondary" noWrap>
                    Trusted by Uniswap, Aave, GMX
                  </Typography>
                </div>
              </div>
            </Typography>
            <Button
              component="a"
              color="primary"
              variant="contained"
              size="large"
              fullWidth={isMatchMobile}
              href="https://equation.trade?version=v3"
              target="_blank"
              sx={{
                height: isMatchMobile ? 44 : 50,
                fontWeight: 500,
                fontSize: isMatchMobile ? `16px !important` : 20,
              }}
            >
              Launch v3
            </Button>
          </div>
        </div>
      </Content>
    </Box>
  );
};

export default Topic;
