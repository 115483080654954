import { Typography, useMediaQuery } from "@mui/material";
import Tooltip from "../../components/Common/Tooltip";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

const FreeTradingFeeWarning = () => {
  const matchSmall = useMediaQuery("(max-width: 768px)");
  return (
    <Tooltip
      title={
        <Typography variant="body2">
          All eligible users who have incurred losses will be compensated once
          every 30 days.{" "}
          <Typography
            component="a"
            variant="inherit"
            href="https://equation.trade/earn/loss?version=v3"
            className="underline-offset-4 underline decoration-1 hover:text-primary"
            target="_blank"
          >
            Learn more »
          </Typography>
        </Typography>
      }
    >
      <Typography
        component="span"
        color="inherit"
        fontSize={matchSmall ? "inherit" : 20}
      >
        <ErrorOutline
          color="inherit"
          fontSize={matchSmall ? "small" : "inherit"}
          className="rotate-180 -mt-0.25"
        />
      </Typography>
    </Tooltip>
  );
};

export default FreeTradingFeeWarning;
