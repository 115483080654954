import { useCallback, useMemo, useEffect } from "react";

import { IS_USER_WHITE_MODEL, USER_WHITE_LIST } from "config/constants";
import { SUPPORTED_CHAIN_IDS } from "config/constants/connector/chains";
import { NATIVE_CURRENCY } from "config/constants/tokens";
import {
  Chain,
  useAccount,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { globalBaseState, setChain } from "state/global/slice";
import { useAppDispatch, useAppSelector } from "../state/hooks";

// 获取当前Chain，以及是否是支持的链
export function useCurrentChain() {
  const { chain } = useAppSelector(globalBaseState);
  const { chain: walletCurrentChain } = useNetwork();
  const checkChain = useCallback(
    (chainId: number) => {
      return SUPPORTED_CHAIN_IDS.some((item) => item === chainId);
    },
    [chain]
  );
  return useMemo(() => {
    return {
      currentChain: chain,
      currentChainId: chain.id,
      isSupportedChain: SUPPORTED_CHAIN_IDS.some(
        (item) => item === (walletCurrentChain && walletCurrentChain.id)
      ),
      checkChain,
    };
  }, [chain, checkChain, walletCurrentChain]);
}

// 判断登录态，即在正确的链下，已连接钱包
export function useCheckLogin() {
  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  return useMemo(() => {
    // if (
    //   address &&
    //   IS_USER_WHITE_MODEL &&
    //   USER_WHITE_LIST.indexOf(address || "") === -1
    // ) {
    //   disconnect();
    // }
    return (
      SUPPORTED_CHAIN_IDS.some((item) => item === chain?.id) && isConnected
    );
  }, [chain, isConnected, address]);
}

export function useCurrentNativeCurrency() {
  const localChain = useAppSelector((state) => state.global.chain);

  const nativeSymbol = useMemo(() => {
    if (localChain.nativeCurrency) {
      return localChain.nativeCurrency.symbol;
    } else {
      return "ETH";
    }
  }, [localChain]);

  const nativeCurrency = useMemo(() => {
    return NATIVE_CURRENCY[localChain.id];
  }, [localChain]);

  const checkNativeAddress = useCallback(
    (address: string) => {
      if (!address) {
        return false;
      }
      if (address.toLowerCase() === nativeSymbol.toLowerCase()) {
        return true;
      }
      return false;
    },
    [nativeSymbol]
  );

  return {
    nativeSymbol,
    nativeCurrency,
    checkNativeAddress,
  };
}

export function useChangeChain() {
  const dispatch = useAppDispatch();
  const { isConnected } = useAccount();
  const { chain } = useAppSelector(globalBaseState);
  const { switchNetwork, switchNetworkAsync, pendingChainId, isSuccess } =
    useSwitchNetwork();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setChain(chain));
    }
  }, [chain, dispatch, isSuccess, pendingChainId]);

  const onChangeChain = (chain: Chain) => {
    if (isConnected && switchNetwork) {
      switchNetworkAsync?.(chain.id);
    } else {
      dispatch(setChain(chain));
    }
  };

  const onChangeNetwork = (chain: Chain) => {
    switchNetworkAsync?.(chain.id).then((res) => {
      dispatch(setChain(chain));
    });
  };

  return {
    onChangeChain,
    onChangeNetwork,
  };
}
