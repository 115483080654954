import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigDecimal: { input: any; output: any };
  BigInt: { input: any; output: any };
  Bytes: { input: any; output: any };
  /** 8 bytes signed integer */
  Int8: { input: any; output: any };
  /** A string representation of microseconds UNIX timestamp (16 digits) */
  Timestamp: { input: any; output: any };
};

export enum Aggregation_Interval {
  Day = "day",
  Hour = "hour",
}

export type BlockChangedFilter = {
  number_gte: Scalars["Int"]["input"];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars["Bytes"]["input"]>;
  number?: InputMaybe<Scalars["Int"]["input"]>;
  number_gte?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Candle1D = {
  __typename?: "Candle1D";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle1D_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle1D_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle1D_Filter>>>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle1D_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Open = "open",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle1H = {
  __typename?: "Candle1H";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle1H_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle1H_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle1H_Filter>>>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle1H_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Open = "open",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle1M = {
  __typename?: "Candle1M";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle1M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle1M_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle1M_Filter>>>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle1M_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Open = "open",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle4H = {
  __typename?: "Candle4H";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle4H_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle4H_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle4H_Filter>>>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle4H_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Open = "open",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle5M = {
  __typename?: "Candle5M";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle5M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle5M_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle5M_Filter>>>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle5M_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Open = "open",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle15M = {
  __typename?: "Candle15M";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle15M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle15M_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle15M_Filter>>>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle15M_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Open = "open",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle30M = {
  __typename?: "Candle30M";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle30M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle30M_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle30M_Filter>>>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle30M_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Open = "open",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

/** entity that represents LPs' request to decrease liquidity position */
export type DecreaseLiquidityPositionRequest = {
  __typename?: "DecreaseLiquidityPositionRequest";
  /** the min margin after to accept */
  acceptableMinMargin: Scalars["BigDecimal"]["output"];
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** liquidity to increase */
  liquidityDelta: Scalars["BigDecimal"]["output"];
  /** margin used to open the position */
  marginDelta: Scalars["BigDecimal"]["output"];
  /** in which market to decrease liquidity position */
  market: Market;
  /** margin receiver */
  receiver: Scalars["Bytes"]["output"];
  /** revert reason of the request */
  revertReason: Scalars["Bytes"]["output"];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Status;
};

export type DecreaseLiquidityPositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableMinMargin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  acceptableMinMargin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<DecreaseLiquidityPositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidityDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidityDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<DecreaseLiquidityPositionRequest_Filter>>>;
  receiver?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  receiver_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  revertReason?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  revertReason_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  status?: InputMaybe<Status>;
  status_in?: InputMaybe<Array<Status>>;
  status_not?: InputMaybe<Status>;
  status_not_in?: InputMaybe<Array<Status>>;
};

export enum DecreaseLiquidityPositionRequest_OrderBy {
  AcceptableMinMargin = "acceptableMinMargin",
  Account = "account",
  CancelledHash = "cancelledHash",
  CreatedHash = "createdHash",
  ExecutedHash = "executedHash",
  ExecutionFee = "executionFee",
  Id = "id",
  Index = "index",
  LiquidityDelta = "liquidityDelta",
  MarginDelta = "marginDelta",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Receiver = "receiver",
  RevertReason = "revertReason",
  Status = "status",
}

/** entity that represents Traders' request to close/decrease a position */
export type DecreasePositionRequest = {
  __typename?: "DecreasePositionRequest";
  /** worst trade price trader can accept */
  acceptableTradePrice: Scalars["BigDecimal"]["output"];
  /** worst trade price trader can accept, as a Q64.96 */
  acceptableTradePriceX96: Scalars["BigInt"]["output"];
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** delta of the margin to decrease */
  marginDelta: Scalars["BigDecimal"]["output"];
  /** in which market to decrease position */
  market: Market;
  /** margin receiver */
  receiver: Scalars["Bytes"]["output"];
  /** revert reason of the request */
  revertReason: Scalars["Bytes"]["output"];
  /** side of the request, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** size of the base token to decrease */
  sizeDelta: Scalars["BigDecimal"]["output"];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Status;
};

export type DecreasePositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableTradePrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  acceptableTradePriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  acceptableTradePrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  acceptableTradePrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<DecreasePositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  marginDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<DecreasePositionRequest_Filter>>>;
  receiver?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  receiver_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  revertReason?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  revertReason_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  sizeDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  sizeDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  status?: InputMaybe<Status>;
  status_in?: InputMaybe<Array<Status>>;
  status_not?: InputMaybe<Status>;
  status_not_in?: InputMaybe<Array<Status>>;
};

export enum DecreasePositionRequest_OrderBy {
  AcceptableTradePrice = "acceptableTradePrice",
  AcceptableTradePriceX96 = "acceptableTradePriceX96",
  Account = "account",
  CancelledHash = "cancelledHash",
  CreatedHash = "createdHash",
  ExecutedHash = "executedHash",
  ExecutionFee = "executionFee",
  Id = "id",
  Index = "index",
  MarginDelta = "marginDelta",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Receiver = "receiver",
  RevertReason = "revertReason",
  Side = "side",
  SizeDelta = "sizeDelta",
  Status = "status",
}

export type GlobalLiquidationFund = {
  __typename?: "GlobalLiquidationFund";
  /** market address */
  id: Scalars["Bytes"]["output"];
  /** the global liquidation fund */
  liquidationFund: Scalars["BigDecimal"]["output"];
  /** the global liquidity */
  liquidity: Scalars["BigDecimal"]["output"];
  /** pointer to market */
  market: Market;
};

export type GlobalLiquidationFund_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalLiquidationFund_Filter>>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  liquidationFund?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFund_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFund_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFund_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationFund_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFund_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFund_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFund_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<GlobalLiquidationFund_Filter>>>;
};

export enum GlobalLiquidationFund_OrderBy {
  Id = "id",
  LiquidationFund = "liquidationFund",
  Liquidity = "liquidity",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
}

export type GlobalLiquidityPosition = {
  __typename?: "GlobalLiquidityPosition";
  /** total liquidity fee */
  fee: Scalars["BigDecimal"]["output"];
  /** the accumulated trading fee growth per liquidity unit, as a Q192.64 */
  feeGrowthX64: Scalars["BigInt"]["output"];
  /** total funding fee */
  fundingFee: Scalars["BigDecimal"]["output"];
  /** the accumulated funding fee growth per liquidity unit, as a Q192.64 */
  fundingFeeGrowthX64: Scalars["BigInt"]["output"];
  /** market address */
  id: Scalars["Bytes"]["output"];
  /** the size of the net position held by all LPs in the liquidation buffer */
  liquidationBufferNetSize: Scalars["BigDecimal"]["output"];
  /** total liquidity held by all LPs */
  liquidity: Scalars["BigDecimal"]["output"];
  /** total margin held by all LPs */
  margin: Scalars["BigDecimal"]["output"];
  /** pointer to market */
  market: Market;
  /** the size of the net position held by all LPs */
  netSize: Scalars["BigDecimal"]["output"];
  /** the accumulated PnL growth per liquidity unit, as a Q192.64 */
  pnlGrowthX64: Scalars["BigInt"]["output"];
  /** the previous settlement point price */
  previousSPPrice: Scalars["BigDecimal"]["output"];
  /** the previous settlement point price, as a Q64.96 */
  previousSPPriceX96: Scalars["BigInt"]["output"];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** the accumulated unrealized PnL growth per liquidity unit, as a Q192.64 */
  unrealizedPnLGrowthX64: Scalars["BigInt"]["output"];
};

export type GlobalLiquidityPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalLiquidityPosition_Filter>>>;
  fee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  feeGrowthX64?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  feeGrowthX64_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFeeGrowthX64?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingFeeGrowthX64_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  liquidationBufferNetSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  netSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  netSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<GlobalLiquidityPosition_Filter>>>;
  pnlGrowthX64?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  pnlGrowthX64_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  previousSPPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  previousSPPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousSPPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousSPPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousSPPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  previousSPPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousSPPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousSPPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousSPPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  previousSPPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  previousSPPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  previousSPPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  previousSPPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  previousSPPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  previousSPPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  previousSPPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  unrealizedPnLGrowthX64?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  unrealizedPnLGrowthX64_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

export enum GlobalLiquidityPosition_OrderBy {
  Fee = "fee",
  FeeGrowthX64 = "feeGrowthX64",
  FundingFee = "fundingFee",
  FundingFeeGrowthX64 = "fundingFeeGrowthX64",
  Id = "id",
  LiquidationBufferNetSize = "liquidationBufferNetSize",
  Liquidity = "liquidity",
  Margin = "margin",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  NetSize = "netSize",
  PnlGrowthX64 = "pnlGrowthX64",
  PreviousSpPrice = "previousSPPrice",
  PreviousSpPriceX96 = "previousSPPriceX96",
  Side = "side",
  UnrealizedPnLGrowthX64 = "unrealizedPnLGrowthX64",
}

export type GlobalPosition = {
  __typename?: "GlobalPosition";
  /**
   * the change in funding rate, a positive value means longs pay shorts,
   * when a negative value means shorts pay longs
   */
  fundingRate: Scalars["BigDecimal"]["output"];
  /**
   * the change in funding rate, a positive value means longs pay shorts,
   * when a negative value means shorts pay longs, as a Q160.96
   */
  fundingRateX96: Scalars["BigInt"]["output"];
  /** market address */
  id: Scalars["Bytes"]["output"];
  /** the timestamp of the last funding rate adjustment */
  lastAdjustFundingRateTime: Scalars["BigInt"]["output"];
  /** the sum of all position liquidity */
  liquidity: Scalars["BigDecimal"]["output"];
  /** the funding rate growth per unit of long position sizes, as a Q96.96 */
  longFundingRateGrowthX96: Scalars["BigInt"]["output"];
  /** the sum of long position size */
  longSize: Scalars["BigDecimal"]["output"];
  /** the sum of all position liquidity */
  margin: Scalars["BigDecimal"]["output"];
  /** pointer to market */
  market: Market;
  /** the maximum available size of all positions */
  maxSize: Scalars["BigDecimal"]["output"];
  /** the maximum available size of per position */
  maxSizePerPosition: Scalars["BigDecimal"]["output"];
  /** the previous funding rate growth per unit of long position sizes */
  previousLongFundingRateGrowthX96: Scalars["BigInt"]["output"];
  /** the previous funding rate growth per unit of short position sizes */
  previousShortFundingRateGrowthX96: Scalars["BigInt"]["output"];
  /** the funding rate growth per unit of short position sizes, as a Q96.96 */
  shortFundingRateGrowthX96: Scalars["BigInt"]["output"];
  /** the sum of short position size */
  shortSize: Scalars["BigDecimal"]["output"];
};

export type GlobalPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalPosition_Filter>>>;
  fundingRate?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRateX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingRateX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingRate_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingRate_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  lastAdjustFundingRateTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastAdjustFundingRateTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  longFundingRateGrowthX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  longFundingRateGrowthX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  longSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  longSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  maxSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSizePerPosition?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSizePerPosition_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSizePerPosition_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSizePerPosition_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  maxSizePerPosition_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSizePerPosition_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSizePerPosition_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSizePerPosition_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  maxSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  maxSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<GlobalPosition_Filter>>>;
  previousLongFundingRateGrowthX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousLongFundingRateGrowthX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousLongFundingRateGrowthX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousLongFundingRateGrowthX96_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  previousLongFundingRateGrowthX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousLongFundingRateGrowthX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousLongFundingRateGrowthX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousLongFundingRateGrowthX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  previousShortFundingRateGrowthX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousShortFundingRateGrowthX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousShortFundingRateGrowthX96_gte?: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  previousShortFundingRateGrowthX96_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  previousShortFundingRateGrowthX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  previousShortFundingRateGrowthX96_lte?: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  previousShortFundingRateGrowthX96_not?: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  previousShortFundingRateGrowthX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  shortFundingRateGrowthX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  shortFundingRateGrowthX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  shortSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  shortSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum GlobalPosition_OrderBy {
  FundingRate = "fundingRate",
  FundingRateX96 = "fundingRateX96",
  Id = "id",
  LastAdjustFundingRateTime = "lastAdjustFundingRateTime",
  Liquidity = "liquidity",
  LongFundingRateGrowthX96 = "longFundingRateGrowthX96",
  LongSize = "longSize",
  Margin = "margin",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  MaxSize = "maxSize",
  MaxSizePerPosition = "maxSizePerPosition",
  PreviousLongFundingRateGrowthX96 = "previousLongFundingRateGrowthX96",
  PreviousShortFundingRateGrowthX96 = "previousShortFundingRateGrowthX96",
  ShortFundingRateGrowthX96 = "shortFundingRateGrowthX96",
  ShortSize = "shortSize",
}

/** entity that represents LPs' request to increase liquidity position */
export type IncreaseLiquidityPositionRequest = {
  __typename?: "IncreaseLiquidityPositionRequest";
  /** the min margin after to accept */
  acceptableMinMargin: Scalars["BigDecimal"]["output"];
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** liquidity to increase */
  liquidityDelta: Scalars["BigDecimal"]["output"];
  /** margin used to open the position */
  marginDelta: Scalars["BigDecimal"]["output"];
  /** in which market to increase liquidity position */
  market: Market;
  /** revert reason of the request */
  revertReason: Scalars["Bytes"]["output"];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Status;
};

export type IncreaseLiquidityPositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableMinMargin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  acceptableMinMargin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableMinMargin_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<IncreaseLiquidityPositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidityDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidityDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<IncreaseLiquidityPositionRequest_Filter>>>;
  revertReason?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  revertReason_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  status?: InputMaybe<Status>;
  status_in?: InputMaybe<Array<Status>>;
  status_not?: InputMaybe<Status>;
  status_not_in?: InputMaybe<Array<Status>>;
};

export enum IncreaseLiquidityPositionRequest_OrderBy {
  AcceptableMinMargin = "acceptableMinMargin",
  Account = "account",
  CancelledHash = "cancelledHash",
  CreatedHash = "createdHash",
  ExecutedHash = "executedHash",
  ExecutionFee = "executionFee",
  Id = "id",
  Index = "index",
  LiquidityDelta = "liquidityDelta",
  MarginDelta = "marginDelta",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  RevertReason = "revertReason",
  Status = "status",
}

/** entity that represents Traders' request to open/increase a position */
export type IncreasePositionRequest = {
  __typename?: "IncreasePositionRequest";
  /** worst trade price trader can accept */
  acceptableTradePrice: Scalars["BigDecimal"]["output"];
  /** worst trade price trader can accept, as a Q64.96 */
  acceptableTradePriceX96: Scalars["BigInt"]["output"];
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** must be non-zero for opening position */
  marginDelta: Scalars["BigDecimal"]["output"];
  /** in which market to increase position */
  market: Market;
  /** revert reason of the request */
  revertReason: Scalars["Bytes"]["output"];
  /** side of the request, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** size of the base token to increase */
  sizeDelta: Scalars["BigDecimal"]["output"];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Status;
};

export type IncreasePositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableTradePrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  acceptableTradePriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  acceptableTradePrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  acceptableTradePrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<IncreasePositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  marginDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<IncreasePositionRequest_Filter>>>;
  revertReason?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  revertReason_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  revertReason_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  sizeDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  sizeDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  status?: InputMaybe<Status>;
  status_in?: InputMaybe<Array<Status>>;
  status_not?: InputMaybe<Status>;
  status_not_in?: InputMaybe<Array<Status>>;
};

export enum IncreasePositionRequest_OrderBy {
  AcceptableTradePrice = "acceptableTradePrice",
  AcceptableTradePriceX96 = "acceptableTradePriceX96",
  Account = "account",
  CancelledHash = "cancelledHash",
  CreatedHash = "createdHash",
  ExecutedHash = "executedHash",
  ExecutionFee = "executionFee",
  Id = "id",
  Index = "index",
  MarginDelta = "marginDelta",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  RevertReason = "revertReason",
  Side = "side",
  SizeDelta = "sizeDelta",
  Status = "status",
}

export type LiquidationFundPosition = {
  __typename?: "LiquidationFundPosition";
  /** the account of the position */
  account: Scalars["Bytes"]["output"];
  id: Scalars["ID"]["output"];
  /** the liquidity of the position */
  liquidity: Scalars["BigDecimal"]["output"];
  /** the market of the position */
  market: Market;
};

export type LiquidationFundPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<LiquidationFundPosition_Filter>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<LiquidationFundPosition_Filter>>>;
};

export enum LiquidationFundPosition_OrderBy {
  Account = "account",
  Id = "id",
  Liquidity = "liquidity",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
}

export type LiquidityPosition = {
  __typename?: "LiquidityPosition";
  /** the owner of the liquidity position */
  account: Scalars["Bytes"]["output"];
  /** the close block of the liquidity position */
  closeBlockNumber: Scalars["BigInt"]["output"];
  /** the close time of the liquidity position */
  closeTime: Scalars["BigInt"]["output"];
  /** the close tx hash of the liquidity position */
  closeTxHash: Scalars["Bytes"]["output"];
  /** the entry block of the liquidity position */
  entryBlockNumber: Scalars["BigInt"]["output"];
  /** the entry time of the liquidity position */
  entryTime: Scalars["BigInt"]["output"];
  /** the entry tx hash of the liquidity position */
  entryTxHash: Scalars["Bytes"]["output"];
  /** accumulated trading fee of the liquidity position */
  fee: Scalars["BigDecimal"]["output"];
  /** the accumulated trading fee growth per liquidity unit, as a Q192.64 */
  feeGrowthX64: Scalars["BigInt"]["output"];
  /** the address that receives the liquidation execution fee */
  feeReceiver: Scalars["Bytes"]["output"];
  /** accumulated funding fee of the liquidity position */
  fundingFee: Scalars["BigDecimal"]["output"];
  /** the accumulated funding fee growth per liquidity unit, as a Q192.64 */
  fundingFeeGrowthX64: Scalars["BigInt"]["output"];
  /** format: <market address>:<account> */
  id: Scalars["ID"]["output"];
  /** the liquidation execution fee for LP */
  liquidationExecutionFee: Scalars["BigDecimal"]["output"];
  /** the liquidity of the liquidity position */
  liquidity: Scalars["BigDecimal"]["output"];
  /** total decrease liquidity */
  liquidityDecreased: Scalars["BigDecimal"]["output"];
  /** total increase liquidity */
  liquidityIncreased: Scalars["BigDecimal"]["output"];
  /** liquidity position history ID */
  liquidityPositionHistoryID: Scalars["String"]["output"];
  /** the margin of the liquidity position */
  margin: Scalars["BigDecimal"]["output"];
  /** total decrease margin */
  marginDecreased: Scalars["BigDecimal"]["output"];
  /** total increase margin */
  marginIncreased: Scalars["BigDecimal"]["output"];
  /** pointer to market */
  market: Market;
  /** accumulated PnL of the liquidity position */
  pnl: Scalars["BigDecimal"]["output"];
  /** the accumulated PnL growth per liquidity unit, as a Q192.64 */
  pnlGrowthX64: Scalars["BigInt"]["output"];
  /** the realized PnL of the liquidity position */
  realizedPnL: Scalars["BigDecimal"]["output"];
  /** status of the liquidity position, enum{Opened, Closed, Liquidated} */
  status: LiquidityPositionStatus;
  /** the accumulated unrealized PnL growth per liquidity unit, as a Q192.64 */
  unrealizedPnLGrowthX64: Scalars["BigInt"]["output"];
};

export type LiquidityPositionHistory = {
  __typename?: "LiquidityPositionHistory";
  /** the owner of the liquidity position */
  account: Scalars["Bytes"]["output"];
  /** the timestamp of the last closing of the liquidity position */
  closedTime: Scalars["BigInt"]["output"];
  /** the entry block of the liquidity position */
  entryBlockNumber: Scalars["BigInt"]["output"];
  /** the entry time of the liquidity position */
  entryTime: Scalars["BigInt"]["output"];
  /** the entry tx hash of the liquidity position */
  entryTxHash: Scalars["Bytes"]["output"];
  /** accumulated trading fee of the liquidity position */
  fee: Scalars["BigDecimal"]["output"];
  /** accumulated funding fee of the liquidity position */
  fundingFee: Scalars["BigDecimal"]["output"];
  /** format: <transaction hash>:<log index> */
  id: Scalars["ID"]["output"];
  /** the liquidation liquidity of the liquidity position */
  liquidationLiquidity: Scalars["BigDecimal"]["output"];
  /** the liquidation price of the liquidity position */
  liquidationPrice: Scalars["BigDecimal"]["output"];
  /** the liquidity of the liquidity position */
  liquidity: Scalars["BigDecimal"]["output"];
  /** total decrease liquidity */
  liquidityDecreased: Scalars["BigDecimal"]["output"];
  /** total increase liquidity */
  liquidityIncreased: Scalars["BigDecimal"]["output"];
  /** the margin of the liquidity position */
  margin: Scalars["BigDecimal"]["output"];
  /** total decrease margin */
  marginDecreased: Scalars["BigDecimal"]["output"];
  /** total increase margin */
  marginIncreased: Scalars["BigDecimal"]["output"];
  /** pointer to market */
  market: Market;
  /** accumulated PnL of the liquidity position */
  pnl: Scalars["BigDecimal"]["output"];
  /** accumulated realized PnL of the liquidity position */
  realizedPnL: Scalars["BigDecimal"]["output"];
  /**
   * status of the liquidity position history.
   * enum{PartialClosed,Closed,Liquidated,Opened,IncreaseMargin,
   * DecreaseMargin,IncreasePosition,DecreasePosition}
   */
  status: LiquidityPositionHistoryStatus;
};

export enum LiquidityPositionHistoryStatus {
  Closed = "Closed",
  DecreaseMargin = "DecreaseMargin",
  DecreasePosition = "DecreasePosition",
  IncreaseMargin = "IncreaseMargin",
  IncreasePosition = "IncreasePosition",
  Liquidated = "Liquidated",
  Opened = "Opened",
  PartialClosed = "PartialClosed",
}

export type LiquidityPositionHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<LiquidityPositionHistory_Filter>>>;
  closedTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  closedTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryBlockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryBlockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTxHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  entryTxHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  fee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  liquidationLiquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationLiquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationLiquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationLiquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationLiquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationLiquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationLiquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationLiquidity_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidityDecreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidityIncreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidityIncreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDecreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginIncreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginIncreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<LiquidityPositionHistory_Filter>>>;
  pnl?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnl_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnl_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnl_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  pnl_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnl_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnl_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnl_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  status?: InputMaybe<LiquidityPositionHistoryStatus>;
  status_in?: InputMaybe<Array<LiquidityPositionHistoryStatus>>;
  status_not?: InputMaybe<LiquidityPositionHistoryStatus>;
  status_not_in?: InputMaybe<Array<LiquidityPositionHistoryStatus>>;
};

export enum LiquidityPositionHistory_OrderBy {
  Account = "account",
  ClosedTime = "closedTime",
  EntryBlockNumber = "entryBlockNumber",
  EntryTime = "entryTime",
  EntryTxHash = "entryTxHash",
  Fee = "fee",
  FundingFee = "fundingFee",
  Id = "id",
  LiquidationLiquidity = "liquidationLiquidity",
  LiquidationPrice = "liquidationPrice",
  Liquidity = "liquidity",
  LiquidityDecreased = "liquidityDecreased",
  LiquidityIncreased = "liquidityIncreased",
  Margin = "margin",
  MarginDecreased = "marginDecreased",
  MarginIncreased = "marginIncreased",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Pnl = "pnl",
  RealizedPnL = "realizedPnL",
  Status = "status",
}

export enum LiquidityPositionStatus {
  Closed = "Closed",
  Liquidated = "Liquidated",
  Opened = "Opened",
}

export type LiquidityPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<LiquidityPosition_Filter>>>;
  closeBlockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  closeBlockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  closeTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  closeTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  closeTxHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  closeTxHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  entryBlockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryBlockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTxHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  entryTxHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  fee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  feeGrowthX64?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  feeGrowthX64_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  feeGrowthX64_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  feeReceiver?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  feeReceiver_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  fee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFeeGrowthX64?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingFeeGrowthX64_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingFeeGrowthX64_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  liquidationExecutionFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationExecutionFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidityDecreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityDecreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidityIncreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidityIncreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityIncreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidityPositionHistoryID?: InputMaybe<Scalars["String"]["input"]>;
  liquidityPositionHistoryID_contains?: InputMaybe<Scalars["String"]["input"]>;
  liquidityPositionHistoryID_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  liquidityPositionHistoryID_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  liquidityPositionHistoryID_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  liquidityPositionHistoryID_gt?: InputMaybe<Scalars["String"]["input"]>;
  liquidityPositionHistoryID_gte?: InputMaybe<Scalars["String"]["input"]>;
  liquidityPositionHistoryID_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  liquidityPositionHistoryID_lt?: InputMaybe<Scalars["String"]["input"]>;
  liquidityPositionHistoryID_lte?: InputMaybe<Scalars["String"]["input"]>;
  liquidityPositionHistoryID_not?: InputMaybe<Scalars["String"]["input"]>;
  liquidityPositionHistoryID_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  liquidityPositionHistoryID_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  liquidityPositionHistoryID_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  liquidityPositionHistoryID_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  liquidityPositionHistoryID_not_in?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  liquidityPositionHistoryID_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  liquidityPositionHistoryID_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  liquidityPositionHistoryID_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  liquidityPositionHistoryID_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDecreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginIncreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginIncreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<LiquidityPosition_Filter>>>;
  pnl?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnlGrowthX64?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  pnlGrowthX64_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  pnlGrowthX64_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  pnl_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnl_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnl_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  pnl_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnl_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnl_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pnl_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  status?: InputMaybe<LiquidityPositionStatus>;
  status_in?: InputMaybe<Array<LiquidityPositionStatus>>;
  status_not?: InputMaybe<LiquidityPositionStatus>;
  status_not_in?: InputMaybe<Array<LiquidityPositionStatus>>;
  unrealizedPnLGrowthX64?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  unrealizedPnLGrowthX64_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  unrealizedPnLGrowthX64_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

export enum LiquidityPosition_OrderBy {
  Account = "account",
  CloseBlockNumber = "closeBlockNumber",
  CloseTime = "closeTime",
  CloseTxHash = "closeTxHash",
  EntryBlockNumber = "entryBlockNumber",
  EntryTime = "entryTime",
  EntryTxHash = "entryTxHash",
  Fee = "fee",
  FeeGrowthX64 = "feeGrowthX64",
  FeeReceiver = "feeReceiver",
  FundingFee = "fundingFee",
  FundingFeeGrowthX64 = "fundingFeeGrowthX64",
  Id = "id",
  LiquidationExecutionFee = "liquidationExecutionFee",
  Liquidity = "liquidity",
  LiquidityDecreased = "liquidityDecreased",
  LiquidityIncreased = "liquidityIncreased",
  LiquidityPositionHistoryId = "liquidityPositionHistoryID",
  Margin = "margin",
  MarginDecreased = "marginDecreased",
  MarginIncreased = "marginIncreased",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Pnl = "pnl",
  PnlGrowthX64 = "pnlGrowthX64",
  RealizedPnL = "realizedPnL",
  Status = "status",
  UnrealizedPnLGrowthX64 = "unrealizedPnLGrowthX64",
}

export type Market = {
  __typename?: "Market";
  /** market base config */
  baseConfig: MarketBaseConfig;
  candles1D: Array<Candle1D>;
  candles1H: Array<Candle1H>;
  candles1M: Array<Candle1M>;
  candles4H: Array<Candle4H>;
  candles5M: Array<Candle5M>;
  candles15M: Array<Candle15M>;
  candles30M: Array<Candle30M>;
  /** market fee rate config */
  feeRateConfig: MarketFeeRateConfig;
  /** pointer to global liquidation fund */
  globalLiquidationFund: GlobalLiquidationFund;
  /** pointer to global liquidity position */
  globalLiquidityPosition: GlobalLiquidityPosition;
  /** pointer to global position */
  globalPosition: GlobalPosition;
  /** address of the market */
  id: Scalars["Bytes"]["output"];
  /** accumulated usage of liquidation fund */
  liquidationFundGovUsed: Scalars["BigDecimal"]["output"];
  liquidationFundPositions: Array<LiquidationFundPosition>;
  liquidityPositions: Array<LiquidityPosition>;
  positions: Array<Position>;
  /** market price config */
  priceConfig: MarketPriceConfig;
  /** pointer to price state */
  priceState: PriceState;
  /** pointer to price state 1H */
  priceState1Hs: Array<PriceState1H>;
  /** protocol fee balance of the market */
  protocolFee: Scalars["BigDecimal"]["output"];
  /** symbol of the market */
  symbol: Scalars["String"]["output"];
  /** total protocol fee of the market */
  totalProtocolFee: Scalars["BigDecimal"]["output"];
  /** all time volume */
  volume: Scalars["BigDecimal"]["output"];
  /** all time volume in USD */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type MarketCandles1DArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle1D_Filter>;
};

export type MarketCandles1HArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle1H_Filter>;
};

export type MarketCandles1MArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle1M_Filter>;
};

export type MarketCandles4HArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle4H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle4H_Filter>;
};

export type MarketCandles5MArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle5M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle5M_Filter>;
};

export type MarketCandles15MArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle15M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle15M_Filter>;
};

export type MarketCandles30MArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle30M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle30M_Filter>;
};

export type MarketLiquidationFundPositionsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LiquidationFundPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LiquidationFundPosition_Filter>;
};

export type MarketLiquidityPositionsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LiquidityPosition_Filter>;
};

export type MarketPositionsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Position_Filter>;
};

export type MarketPriceState1HsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceState1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PriceState1H_Filter>;
};

export type MarketBaseConfig = {
  __typename?: "MarketBaseConfig";
  id: Scalars["Bytes"]["output"];
  /** the liquidation execution fee for LP and trader positions */
  liquidationExecutionFee: Scalars["BigInt"]["output"];
  /** the liquidation fee rate for per LP position, denominated in ten thousandths of a bip (i.e. 1e-8) */
  liquidationFeeRatePerLiquidityPosition: Scalars["BigInt"]["output"];
  /** the liquidation fee rate for per trader position, denominated in ten thousandths of a bip (i.e. 1e-8) */
  liquidationFeeRatePerPosition: Scalars["BigInt"]["output"];
  market: Market;
  /** the maximum leverage for per LP position, for example, 100 means the maximum leverage is 100 times */
  maxLeveragePerLiquidityPosition: Scalars["BigInt"]["output"];
  /** the maximum leverage for per trader position, for example, 100 means the maximum leverage is 100 times */
  maxLeveragePerPosition: Scalars["BigInt"]["output"];
  /** the maximum available liquidity used to calculate the maximum size of the trader's position */
  maxPositionLiquidity: Scalars["BigInt"]["output"];
  /** the maximum value of all positions relative to `maxPositionLiquidity`, denominated in ten thousandths of a bip (i.e. 1e-8) */
  maxPositionValueRate: Scalars["BigInt"]["output"];
  /** the maximum size of per position relative to `maxSize`, denominated in ten thousandths of a bip (i.e. 1e-8) */
  maxSizeRatePerPosition: Scalars["BigInt"]["output"];
  /** the minimum entry margin required for per LP position, for example, 10_000_000 means the minimum entry margin is 10 USD */
  minMarginPerLiquidityPosition: Scalars["BigInt"]["output"];
  /**
   * the minimum entry margin required for per trader position, for example,
   * 10_000_000 means the minimum entry margin is 10 USD
   */
  minMarginPerPosition: Scalars["BigInt"]["output"];
};

export type MarketBaseConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MarketBaseConfig_Filter>>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  liquidationExecutionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidationExecutionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  liquidationFeeRatePerLiquidityPosition?: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  liquidationFeeRatePerLiquidityPosition_gt?: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  liquidationFeeRatePerLiquidityPosition_gte?: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  liquidationFeeRatePerLiquidityPosition_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  liquidationFeeRatePerLiquidityPosition_lt?: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  liquidationFeeRatePerLiquidityPosition_lte?: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  liquidationFeeRatePerLiquidityPosition_not?: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  liquidationFeeRatePerLiquidityPosition_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  liquidationFeeRatePerPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  liquidationFeeRatePerPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  maxLeveragePerLiquidityPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  maxLeveragePerLiquidityPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  maxLeveragePerPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxLeveragePerPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxPositionLiquidity?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionLiquidity_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionLiquidity_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionLiquidity_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxPositionLiquidity_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionLiquidity_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionLiquidity_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionLiquidity_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxPositionValueRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionValueRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionValueRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionValueRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxPositionValueRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionValueRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionValueRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPositionValueRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxSizeRatePerPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxSizeRatePerPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxSizeRatePerPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxSizeRatePerPosition_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxSizeRatePerPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxSizeRatePerPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxSizeRatePerPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxSizeRatePerPosition_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minMarginPerLiquidityPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  minMarginPerLiquidityPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  minMarginPerPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minMarginPerPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<MarketBaseConfig_Filter>>>;
};

export enum MarketBaseConfig_OrderBy {
  Id = "id",
  LiquidationExecutionFee = "liquidationExecutionFee",
  LiquidationFeeRatePerLiquidityPosition = "liquidationFeeRatePerLiquidityPosition",
  LiquidationFeeRatePerPosition = "liquidationFeeRatePerPosition",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  MaxLeveragePerLiquidityPosition = "maxLeveragePerLiquidityPosition",
  MaxLeveragePerPosition = "maxLeveragePerPosition",
  MaxPositionLiquidity = "maxPositionLiquidity",
  MaxPositionValueRate = "maxPositionValueRate",
  MaxSizeRatePerPosition = "maxSizeRatePerPosition",
  MinMarginPerLiquidityPosition = "minMarginPerLiquidityPosition",
  MinMarginPerPosition = "minMarginPerPosition",
}

export type MarketFeeRateConfig = {
  __typename?: "MarketFeeRateConfig";
  /** the funding buffer, denominated in ten thousandths of a bip (i.e. 1e-8) */
  fundingBuffer: Scalars["BigInt"]["output"];
  /** a coefficient used to adjust how funding fees are paid to the market, denominated in ten thousandths of a bip (i.e. 1e-8) */
  fundingCoeff: Scalars["BigInt"]["output"];
  id: Scalars["Bytes"]["output"];
  /** the interest rate used to calculate the funding rate, denominated in ten thousandths of a bip (i.e. 1e-8) */
  interestRate: Scalars["BigInt"]["output"];
  /** the liquidity funding fee rate, denominated in ten thousandths of a bip (i.e. 1e-8) */
  liquidityFundingFeeRate: Scalars["BigInt"]["output"];
  market: Market;
  /**
   * a coefficient used to adjust how funding fees are distributed between long and
   * short positions, denominated in ten thousandths of a bip (i.e. 1e-8)
   */
  protocolFundingCoeff: Scalars["BigInt"]["output"];
  /** the protocol funding fee rate as a percentage of funding fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  protocolFundingFeeRate: Scalars["BigInt"]["output"];
};

export type MarketFeeRateConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MarketFeeRateConfig_Filter>>>;
  fundingBuffer?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingBuffer_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingBuffer_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingBuffer_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingBuffer_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingBuffer_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingBuffer_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingBuffer_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingCoeff?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingCoeff_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingCoeff_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingCoeff_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingCoeff_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingCoeff_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingCoeff_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingCoeff_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  interestRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  interestRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidityFundingFeeRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFundingFeeRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFundingFeeRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFundingFeeRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidityFundingFeeRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFundingFeeRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFundingFeeRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFundingFeeRate_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<MarketFeeRateConfig_Filter>>>;
  protocolFundingCoeff?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingCoeff_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingCoeff_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingCoeff_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  protocolFundingCoeff_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingCoeff_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingCoeff_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingCoeff_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  protocolFundingFeeRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingFeeRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingFeeRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingFeeRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  protocolFundingFeeRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingFeeRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingFeeRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFundingFeeRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

export enum MarketFeeRateConfig_OrderBy {
  FundingBuffer = "fundingBuffer",
  FundingCoeff = "fundingCoeff",
  Id = "id",
  InterestRate = "interestRate",
  LiquidityFundingFeeRate = "liquidityFundingFeeRate",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  ProtocolFundingCoeff = "protocolFundingCoeff",
  ProtocolFundingFeeRate = "protocolFundingFeeRate",
}

export type MarketPriceConfig = {
  __typename?: "MarketPriceConfig";
  id: Scalars["Bytes"]["output"];
  /** the index used to store the net position of the liquidation */
  liquidationVertexIndex: Scalars["Int"]["output"];
  market: Market;
  /** the maximum available liquidity used to calculate the premium rate when trader increase or decrease positions */
  maxPriceImpactLiquidity: Scalars["BigInt"]["output"];
  /** the vertexes used to calculate the premium rate when trader increase or decrease positions */
  vertices: Array<VertexConfig>;
};

export type MarketPriceConfigVerticesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<VertexConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<VertexConfig_Filter>;
};

export type MarketPriceConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MarketPriceConfig_Filter>>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  liquidationVertexIndex?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_gt?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_gte?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  liquidationVertexIndex_lt?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_lte?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_not?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  maxPriceImpactLiquidity?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxPriceImpactLiquidity_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  or?: InputMaybe<Array<InputMaybe<MarketPriceConfig_Filter>>>;
  vertices_?: InputMaybe<VertexConfig_Filter>;
};

export enum MarketPriceConfig_OrderBy {
  Id = "id",
  LiquidationVertexIndex = "liquidationVertexIndex",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  MaxPriceImpactLiquidity = "maxPriceImpactLiquidity",
  Vertices = "vertices",
}

export type Market_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Market_Filter>>>;
  baseConfig?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_?: InputMaybe<MarketBaseConfig_Filter>;
  baseConfig_contains?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_gt?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_gte?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  baseConfig_lt?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_lte?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_not?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  baseConfig_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  baseConfig_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  candles1D_?: InputMaybe<Candle1D_Filter>;
  candles1H_?: InputMaybe<Candle1H_Filter>;
  candles1M_?: InputMaybe<Candle1M_Filter>;
  candles4H_?: InputMaybe<Candle4H_Filter>;
  candles5M_?: InputMaybe<Candle5M_Filter>;
  candles15M_?: InputMaybe<Candle15M_Filter>;
  candles30M_?: InputMaybe<Candle30M_Filter>;
  feeRateConfig?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_?: InputMaybe<MarketFeeRateConfig_Filter>;
  feeRateConfig_contains?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_gt?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_gte?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  feeRateConfig_lt?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_lte?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_not?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  feeRateConfig_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  feeRateConfig_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund_?: InputMaybe<GlobalLiquidationFund_Filter>;
  globalLiquidationFund_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidationFund_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidationFund_gt?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund_gte?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  globalLiquidationFund_lt?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund_lte?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund_not?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidationFund_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidationFund_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  globalLiquidationFund_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidationFund_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidationFund_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidationFund_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_?: InputMaybe<GlobalLiquidityPosition_Filter>;
  globalLiquidityPosition_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_gt?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_gte?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  globalLiquidityPosition_lt?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_lte?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_not?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_not_in?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  globalLiquidityPosition_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalPosition?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_?: InputMaybe<GlobalPosition_Filter>;
  globalPosition_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_gt?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_gte?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  globalPosition_lt?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_lte?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  globalPosition_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalPosition_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  liquidationFundGovUsed?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFundGovUsed_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFundGovUsed_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFundGovUsed_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationFundGovUsed_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFundGovUsed_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFundGovUsed_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFundGovUsed_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationFundPositions_?: InputMaybe<LiquidationFundPosition_Filter>;
  liquidityPositions_?: InputMaybe<LiquidityPosition_Filter>;
  or?: InputMaybe<Array<InputMaybe<Market_Filter>>>;
  positions_?: InputMaybe<Position_Filter>;
  priceConfig?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_?: InputMaybe<MarketPriceConfig_Filter>;
  priceConfig_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_gt?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_gte?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceConfig_lt?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_lte?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_not?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceConfig_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceConfig_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState?: InputMaybe<Scalars["String"]["input"]>;
  priceState1Hs_?: InputMaybe<PriceState1H_Filter>;
  priceState_?: InputMaybe<PriceState_Filter>;
  priceState_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceState_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_gt?: InputMaybe<Scalars["String"]["input"]>;
  priceState_gte?: InputMaybe<Scalars["String"]["input"]>;
  priceState_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceState_lt?: InputMaybe<Scalars["String"]["input"]>;
  priceState_lte?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceState_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  protocolFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  protocolFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  symbol?: InputMaybe<Scalars["String"]["input"]>;
  symbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  symbol_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  symbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  symbol_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  symbol_gt?: InputMaybe<Scalars["String"]["input"]>;
  symbol_gte?: InputMaybe<Scalars["String"]["input"]>;
  symbol_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  symbol_lt?: InputMaybe<Scalars["String"]["input"]>;
  symbol_lte?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  symbol_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  symbol_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  symbol_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  totalProtocolFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  totalProtocolFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Market_OrderBy {
  BaseConfig = "baseConfig",
  BaseConfigId = "baseConfig__id",
  BaseConfigLiquidationExecutionFee = "baseConfig__liquidationExecutionFee",
  BaseConfigLiquidationFeeRatePerLiquidityPosition = "baseConfig__liquidationFeeRatePerLiquidityPosition",
  BaseConfigLiquidationFeeRatePerPosition = "baseConfig__liquidationFeeRatePerPosition",
  BaseConfigMaxLeveragePerLiquidityPosition = "baseConfig__maxLeveragePerLiquidityPosition",
  BaseConfigMaxLeveragePerPosition = "baseConfig__maxLeveragePerPosition",
  BaseConfigMaxPositionLiquidity = "baseConfig__maxPositionLiquidity",
  BaseConfigMaxPositionValueRate = "baseConfig__maxPositionValueRate",
  BaseConfigMaxSizeRatePerPosition = "baseConfig__maxSizeRatePerPosition",
  BaseConfigMinMarginPerLiquidityPosition = "baseConfig__minMarginPerLiquidityPosition",
  BaseConfigMinMarginPerPosition = "baseConfig__minMarginPerPosition",
  Candles1D = "candles1D",
  Candles1H = "candles1H",
  Candles1M = "candles1M",
  Candles4H = "candles4H",
  Candles5M = "candles5M",
  Candles15M = "candles15M",
  Candles30M = "candles30M",
  FeeRateConfig = "feeRateConfig",
  FeeRateConfigFundingBuffer = "feeRateConfig__fundingBuffer",
  FeeRateConfigFundingCoeff = "feeRateConfig__fundingCoeff",
  FeeRateConfigId = "feeRateConfig__id",
  FeeRateConfigInterestRate = "feeRateConfig__interestRate",
  FeeRateConfigLiquidityFundingFeeRate = "feeRateConfig__liquidityFundingFeeRate",
  FeeRateConfigProtocolFundingCoeff = "feeRateConfig__protocolFundingCoeff",
  FeeRateConfigProtocolFundingFeeRate = "feeRateConfig__protocolFundingFeeRate",
  GlobalLiquidationFund = "globalLiquidationFund",
  GlobalLiquidationFundId = "globalLiquidationFund__id",
  GlobalLiquidationFundLiquidationFund = "globalLiquidationFund__liquidationFund",
  GlobalLiquidationFundLiquidity = "globalLiquidationFund__liquidity",
  GlobalLiquidityPosition = "globalLiquidityPosition",
  GlobalLiquidityPositionFee = "globalLiquidityPosition__fee",
  GlobalLiquidityPositionFeeGrowthX64 = "globalLiquidityPosition__feeGrowthX64",
  GlobalLiquidityPositionFundingFee = "globalLiquidityPosition__fundingFee",
  GlobalLiquidityPositionFundingFeeGrowthX64 = "globalLiquidityPosition__fundingFeeGrowthX64",
  GlobalLiquidityPositionId = "globalLiquidityPosition__id",
  GlobalLiquidityPositionLiquidationBufferNetSize = "globalLiquidityPosition__liquidationBufferNetSize",
  GlobalLiquidityPositionLiquidity = "globalLiquidityPosition__liquidity",
  GlobalLiquidityPositionMargin = "globalLiquidityPosition__margin",
  GlobalLiquidityPositionNetSize = "globalLiquidityPosition__netSize",
  GlobalLiquidityPositionPnlGrowthX64 = "globalLiquidityPosition__pnlGrowthX64",
  GlobalLiquidityPositionPreviousSpPrice = "globalLiquidityPosition__previousSPPrice",
  GlobalLiquidityPositionPreviousSpPriceX96 = "globalLiquidityPosition__previousSPPriceX96",
  GlobalLiquidityPositionSide = "globalLiquidityPosition__side",
  GlobalLiquidityPositionUnrealizedPnLGrowthX64 = "globalLiquidityPosition__unrealizedPnLGrowthX64",
  GlobalPosition = "globalPosition",
  GlobalPositionFundingRate = "globalPosition__fundingRate",
  GlobalPositionFundingRateX96 = "globalPosition__fundingRateX96",
  GlobalPositionId = "globalPosition__id",
  GlobalPositionLastAdjustFundingRateTime = "globalPosition__lastAdjustFundingRateTime",
  GlobalPositionLiquidity = "globalPosition__liquidity",
  GlobalPositionLongFundingRateGrowthX96 = "globalPosition__longFundingRateGrowthX96",
  GlobalPositionLongSize = "globalPosition__longSize",
  GlobalPositionMargin = "globalPosition__margin",
  GlobalPositionMaxSize = "globalPosition__maxSize",
  GlobalPositionMaxSizePerPosition = "globalPosition__maxSizePerPosition",
  GlobalPositionPreviousLongFundingRateGrowthX96 = "globalPosition__previousLongFundingRateGrowthX96",
  GlobalPositionPreviousShortFundingRateGrowthX96 = "globalPosition__previousShortFundingRateGrowthX96",
  GlobalPositionShortFundingRateGrowthX96 = "globalPosition__shortFundingRateGrowthX96",
  GlobalPositionShortSize = "globalPosition__shortSize",
  Id = "id",
  LiquidationFundGovUsed = "liquidationFundGovUsed",
  LiquidationFundPositions = "liquidationFundPositions",
  LiquidityPositions = "liquidityPositions",
  Positions = "positions",
  PriceConfig = "priceConfig",
  PriceConfigId = "priceConfig__id",
  PriceConfigLiquidationVertexIndex = "priceConfig__liquidationVertexIndex",
  PriceConfigMaxPriceImpactLiquidity = "priceConfig__maxPriceImpactLiquidity",
  PriceState = "priceState",
  PriceState1Hs = "priceState1Hs",
  PriceStateBasisIndexPriceX96 = "priceState__basisIndexPriceX96",
  PriceStateId = "priceState__id",
  PriceStateIndexPriceUsedX96 = "priceState__indexPriceUsedX96",
  PriceStatePendingVertexIndex = "priceState__pendingVertexIndex",
  PriceStatePendingVertexIndexBitmap = "priceState__pendingVertexIndexBitmap",
  PriceStatePremiumRateX96 = "priceState__premiumRateX96",
  ProtocolFee = "protocolFee",
  Symbol = "symbol",
  TotalProtocolFee = "totalProtocolFee",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

/** entity that represents a limit order */
export type Order = {
  __typename?: "Order";
  /** worst trade price user can accept */
  acceptableTradePrice: Scalars["BigDecimal"]["output"];
  /** worst trade price user can accept, as a Q64.96 */
  acceptableTradePriceX96: Scalars["BigInt"]["output"];
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the order cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** timestamp that the order was cancelled */
  cancelledTimestamp: Scalars["BigInt"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** timestamp that the order was created */
  createdTimestamp: Scalars["BigInt"]["output"];
  /** valid if the order executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** timestamp that the order was executed */
  executedTimestamp: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** timestamp that the order was operated(e.g. creation, cancellation, update) */
  lastOperationTimestamp: Scalars["BigInt"]["output"];
  /** margin paid by the user, transferred to market manager when to execute */
  marginDelta: Scalars["BigDecimal"]["output"];
  /** in which market to execute the order */
  market: Market;
  /** valid for decrease orders */
  receiver: Scalars["Bytes"]["output"];
  /** side of the request, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** size delta of base token */
  sizeDelta: Scalars["BigDecimal"]["output"];
  /** status of the order, enum{Created, Cancelled, Executed} */
  status: Scalars["String"]["output"];
  /** whether to trigger when market price is higher than trigger price */
  triggerAbove: Scalars["Boolean"]["output"];
  /** trigger price of the limit order */
  triggerMarketPrice: Scalars["BigDecimal"]["output"];
  /** trigger price of the limit order, as a Q64.96 */
  triggerMarketPriceX96: Scalars["BigInt"]["output"];
  /** type of the order, enum{Increase, Decrease} */
  type: OrderType;
  /** tx hash that the order was last updated at */
  updatedHash: Scalars["Bytes"]["output"];
  /** timestamp that the order was last updated */
  updatedTimestamp: Scalars["BigInt"]["output"];
};

/** entity that represents the order book configuration */
export type OrderBookConfig = {
  __typename?: "OrderBookConfig";
  id: Scalars["ID"]["output"];
  /** minimum fee user must pay for executor to carry out their orders */
  minExecutionFee: Scalars["BigInt"]["output"];
};

export type OrderBookConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OrderBookConfig_Filter>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  minExecutionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minExecutionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<OrderBookConfig_Filter>>>;
};

export enum OrderBookConfig_OrderBy {
  Id = "id",
  MinExecutionFee = "minExecutionFee",
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = "asc",
  Desc = "desc",
}

export enum OrderType {
  Decrease = "Decrease",
  Increase = "Increase",
}

export type Order_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableTradePrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  acceptableTradePriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  acceptableTradePrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  acceptableTradePrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<Order_Filter>>>;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  cancelledTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  createdTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executedTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastOperationTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastOperationTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  marginDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<Order_Filter>>>;
  receiver?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  receiver_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  sizeDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  sizeDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_gt?: InputMaybe<Scalars["String"]["input"]>;
  status_gte?: InputMaybe<Scalars["String"]["input"]>;
  status_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_lt?: InputMaybe<Scalars["String"]["input"]>;
  status_lte?: InputMaybe<Scalars["String"]["input"]>;
  status_not?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  triggerAbove?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerAbove_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  triggerAbove_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerAbove_not_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  triggerMarketPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  triggerMarketPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  triggerMarketPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  triggerMarketPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  type?: InputMaybe<OrderType>;
  type_in?: InputMaybe<Array<OrderType>>;
  type_not?: InputMaybe<OrderType>;
  type_not_in?: InputMaybe<Array<OrderType>>;
  updatedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  updatedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  updatedTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  updatedTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

export enum Order_OrderBy {
  AcceptableTradePrice = "acceptableTradePrice",
  AcceptableTradePriceX96 = "acceptableTradePriceX96",
  Account = "account",
  CancelledHash = "cancelledHash",
  CancelledTimestamp = "cancelledTimestamp",
  CreatedHash = "createdHash",
  CreatedTimestamp = "createdTimestamp",
  ExecutedHash = "executedHash",
  ExecutedTimestamp = "executedTimestamp",
  Id = "id",
  Index = "index",
  LastOperationTimestamp = "lastOperationTimestamp",
  MarginDelta = "marginDelta",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Receiver = "receiver",
  Side = "side",
  SizeDelta = "sizeDelta",
  Status = "status",
  TriggerAbove = "triggerAbove",
  TriggerMarketPrice = "triggerMarketPrice",
  TriggerMarketPriceX96 = "triggerMarketPriceX96",
  Type = "type",
  UpdatedHash = "updatedHash",
  UpdatedTimestamp = "updatedTimestamp",
}

export type Position = {
  __typename?: "Position";
  /** the owner of the position */
  account: Scalars["Bytes"]["output"];
  /** the entry block of the position */
  entryBlockNumber: Scalars["BigInt"]["output"];
  /** the entry price of the position */
  entryPrice: Scalars["BigDecimal"]["output"];
  /** the entry price of the position, as a Q64.96 */
  entryPriceX96: Scalars["BigInt"]["output"];
  /** the entry time of the position */
  entryTime: Scalars["BigInt"]["output"];
  /** the entry tx hash of the position */
  entryTxHash: Scalars["Bytes"]["output"];
  /** accumulated funding fee of the position */
  fundingFee: Scalars["BigDecimal"]["output"];
  /** the snapshot of the funding rate growth at the time the position was opened/closed, as a Q96.96 */
  fundingRateGrowthX96: Scalars["BigInt"]["output"];
  /** format: <address>:<market address>:<side> */
  id: Scalars["ID"]["output"];
  /** the block of the last update for the position */
  lastBlockNumber: Scalars["BigInt"]["output"];
  /** the timestamp of the last update for the position */
  lastBlockTimestamp: Scalars["BigInt"]["output"];
  /** the ID of the last transaction history */
  lastTransactionHistory: TransactionHistory;
  /** the tx hash of the last update for the position */
  lastTxHash: Scalars["Bytes"]["output"];
  /** the liquidity of the position */
  liquidity: Scalars["BigDecimal"]["output"];
  /** the margin of the position */
  margin: Scalars["BigDecimal"]["output"];
  /** total decreased margin */
  marginDecreased: Scalars["BigDecimal"]["output"];
  /** total increased margin */
  marginIncreased: Scalars["BigDecimal"]["output"];
  /** pointer to market */
  market: Market;
  /** position history ID */
  positionHistory: PositionHistory;
  /** accumulated realized PnL of the position */
  realizedPnL: Scalars["BigDecimal"]["output"];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** the size of the position */
  size: Scalars["BigDecimal"]["output"];
};

export type PositionHistory = {
  __typename?: "PositionHistory";
  /** the owner of the position */
  account: Scalars["Bytes"]["output"];
  /** the accumulated liquidity from closing of the position */
  closedLiquidity: Scalars["BigDecimal"]["output"];
  /** the accumulated size from closing of the position */
  closedSize: Scalars["BigDecimal"]["output"];
  /** the timestamp of the last closing of the position */
  closedTime: Scalars["BigInt"]["output"];
  /** the entry price of the position, as a Q64.96 */
  entryPrice: Scalars["BigDecimal"]["output"];
  /** the entry time of the position */
  entryTime: Scalars["BigInt"]["output"];
  /** the entry tx hash of the position */
  entryTxHash: Scalars["Bytes"]["output"];
  /** accumulated funding fee of the position */
  fundingFee: Scalars["BigDecimal"]["output"];
  /** format: <transaction hash>:<log index> */
  id: Scalars["ID"]["output"];
  /** the liquidation fee of the position */
  liquidationFee: Scalars["BigDecimal"]["output"];
  /** the liquidation price of the position */
  liquidationPrice: Scalars["BigDecimal"]["output"];
  /** the liquidation size of the position */
  liquidationSize: Scalars["BigDecimal"]["output"];
  /** position is closed by the liquidator */
  liquidatorClosed: Scalars["Boolean"]["output"];
  /** the margin of the position */
  margin: Scalars["BigDecimal"]["output"];
  /** pointer to market */
  market: Market;
  /** pointer to position */
  position: Position;
  /** accumulated realized PnL of the position */
  realizedPnL: Scalars["BigDecimal"]["output"];
  /** realized PnL rate of the position */
  realizedPnLRate: Scalars["BigDecimal"]["output"];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** the size of the position */
  size: Scalars["BigDecimal"]["output"];
  /** stats of the position history, enum{Closed,PartiallyClosed,Liquidated} */
  status: PositionHistoryStatus;
};

export enum PositionHistoryStatus {
  Closed = "Closed",
  DecreaseMargin = "DecreaseMargin",
  DecreasePosition = "DecreasePosition",
  IncreaseMargin = "IncreaseMargin",
  IncreasePosition = "IncreasePosition",
  Liquidated = "Liquidated",
  Opened = "Opened",
  PartialClosed = "PartialClosed",
}

export type PositionHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<PositionHistory_Filter>>>;
  closedLiquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  closedLiquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  closedSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  closedSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  closedTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  closedTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTxHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  entryTxHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  fundingFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  liquidationFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidatorClosed?: InputMaybe<Scalars["Boolean"]["input"]>;
  liquidatorClosed_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  liquidatorClosed_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  liquidatorClosed_not_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<PositionHistory_Filter>>>;
  position?: InputMaybe<Scalars["String"]["input"]>;
  position_?: InputMaybe<Position_Filter>;
  position_contains?: InputMaybe<Scalars["String"]["input"]>;
  position_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  position_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  position_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  position_gt?: InputMaybe<Scalars["String"]["input"]>;
  position_gte?: InputMaybe<Scalars["String"]["input"]>;
  position_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  position_lt?: InputMaybe<Scalars["String"]["input"]>;
  position_lte?: InputMaybe<Scalars["String"]["input"]>;
  position_not?: InputMaybe<Scalars["String"]["input"]>;
  position_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  position_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  position_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  position_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  position_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  position_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  position_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  position_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  position_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  realizedPnL?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnLRate_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  size?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  size_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  status?: InputMaybe<PositionHistoryStatus>;
  status_in?: InputMaybe<Array<PositionHistoryStatus>>;
  status_not?: InputMaybe<PositionHistoryStatus>;
  status_not_in?: InputMaybe<Array<PositionHistoryStatus>>;
};

export enum PositionHistory_OrderBy {
  Account = "account",
  ClosedLiquidity = "closedLiquidity",
  ClosedSize = "closedSize",
  ClosedTime = "closedTime",
  EntryPrice = "entryPrice",
  EntryTime = "entryTime",
  EntryTxHash = "entryTxHash",
  FundingFee = "fundingFee",
  Id = "id",
  LiquidationFee = "liquidationFee",
  LiquidationPrice = "liquidationPrice",
  LiquidationSize = "liquidationSize",
  LiquidatorClosed = "liquidatorClosed",
  Margin = "margin",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Position = "position",
  PositionAccount = "position__account",
  PositionEntryBlockNumber = "position__entryBlockNumber",
  PositionEntryPrice = "position__entryPrice",
  PositionEntryPriceX96 = "position__entryPriceX96",
  PositionEntryTime = "position__entryTime",
  PositionEntryTxHash = "position__entryTxHash",
  PositionFundingFee = "position__fundingFee",
  PositionFundingRateGrowthX96 = "position__fundingRateGrowthX96",
  PositionId = "position__id",
  PositionLastBlockNumber = "position__lastBlockNumber",
  PositionLastBlockTimestamp = "position__lastBlockTimestamp",
  PositionLastTxHash = "position__lastTxHash",
  PositionLiquidity = "position__liquidity",
  PositionMargin = "position__margin",
  PositionMarginDecreased = "position__marginDecreased",
  PositionMarginIncreased = "position__marginIncreased",
  PositionRealizedPnL = "position__realizedPnL",
  PositionSide = "position__side",
  PositionSize = "position__size",
  RealizedPnL = "realizedPnL",
  RealizedPnLRate = "realizedPnLRate",
  Side = "side",
  Size = "size",
  Status = "status",
}

/** entity that represents the position router configuration */
export type PositionRouterConfig = {
  __typename?: "PositionRouterConfig";
  id: Scalars["ID"]["output"];
  /** minimum fee user must pay for executor to carry out their requests */
  minExecutionFee: Scalars["BigInt"]["output"];
};

export type PositionRouterConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PositionRouterConfig_Filter>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  minExecutionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minExecutionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<PositionRouterConfig_Filter>>>;
};

export enum PositionRouterConfig_OrderBy {
  Id = "id",
  MinExecutionFee = "minExecutionFee",
}

export type Position_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  entryBlockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryBlockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTxHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  entryTxHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  fundingFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingRateGrowthX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingRateGrowthX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  lastBlockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastBlockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastBlockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastBlockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastTransactionHistory?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistory_?: InputMaybe<TransactionHistory_Filter>;
  lastTransactionHistory_contains?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistory_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistory_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistory_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistory_gt?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistory_gte?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistory_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lastTransactionHistory_lt?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistory_lte?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistory_not?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistory_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistory_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistory_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistory_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistory_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lastTransactionHistory_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistory_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistory_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistory_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTxHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  lastTxHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDecreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginIncreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginIncreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  positionHistory?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_?: InputMaybe<PositionHistory_Filter>;
  positionHistory_contains?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_gt?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_gte?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  positionHistory_lt?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_lte?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_not?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  positionHistory_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  positionHistory_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  positionHistory_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  realizedPnL?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  size?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  size_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Position_OrderBy {
  Account = "account",
  EntryBlockNumber = "entryBlockNumber",
  EntryPrice = "entryPrice",
  EntryPriceX96 = "entryPriceX96",
  EntryTime = "entryTime",
  EntryTxHash = "entryTxHash",
  FundingFee = "fundingFee",
  FundingRateGrowthX96 = "fundingRateGrowthX96",
  Id = "id",
  LastBlockNumber = "lastBlockNumber",
  LastBlockTimestamp = "lastBlockTimestamp",
  LastTransactionHistory = "lastTransactionHistory",
  LastTransactionHistoryAccount = "lastTransactionHistory__account",
  LastTransactionHistoryBlockNumber = "lastTransactionHistory__blockNumber",
  LastTransactionHistoryBlockTimestamp = "lastTransactionHistory__blockTimestamp",
  LastTransactionHistoryData = "lastTransactionHistory__data",
  LastTransactionHistoryFundingFee = "lastTransactionHistory__fundingFee",
  LastTransactionHistoryId = "lastTransactionHistory__id",
  LastTransactionHistoryOperation = "lastTransactionHistory__operation",
  LastTransactionHistorySide = "lastTransactionHistory__side",
  LastTransactionHistoryTxHash = "lastTransactionHistory__txHash",
  LastTransactionHistoryType = "lastTransactionHistory__type",
  LastTxHash = "lastTxHash",
  Liquidity = "liquidity",
  Margin = "margin",
  MarginDecreased = "marginDecreased",
  MarginIncreased = "marginIncreased",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  PositionHistory = "positionHistory",
  PositionHistoryAccount = "positionHistory__account",
  PositionHistoryClosedLiquidity = "positionHistory__closedLiquidity",
  PositionHistoryClosedSize = "positionHistory__closedSize",
  PositionHistoryClosedTime = "positionHistory__closedTime",
  PositionHistoryEntryPrice = "positionHistory__entryPrice",
  PositionHistoryEntryTime = "positionHistory__entryTime",
  PositionHistoryEntryTxHash = "positionHistory__entryTxHash",
  PositionHistoryFundingFee = "positionHistory__fundingFee",
  PositionHistoryId = "positionHistory__id",
  PositionHistoryLiquidationFee = "positionHistory__liquidationFee",
  PositionHistoryLiquidationPrice = "positionHistory__liquidationPrice",
  PositionHistoryLiquidationSize = "positionHistory__liquidationSize",
  PositionHistoryLiquidatorClosed = "positionHistory__liquidatorClosed",
  PositionHistoryMargin = "positionHistory__margin",
  PositionHistoryRealizedPnL = "positionHistory__realizedPnL",
  PositionHistoryRealizedPnLRate = "positionHistory__realizedPnLRate",
  PositionHistorySide = "positionHistory__side",
  PositionHistorySize = "positionHistory__size",
  PositionHistoryStatus = "positionHistory__status",
  RealizedPnL = "realizedPnL",
  Side = "side",
  Size = "size",
}

export type PriceInfo = {
  __typename?: "PriceInfo";
  /** market address */
  id: Scalars["Bytes"]["output"];
  /** token maximum price */
  maxPrice: Scalars["BigDecimal"]["output"];
  /** token maximum price, as a Q64.96 */
  maxPriceX96: Scalars["BigInt"]["output"];
  /** token minimum price */
  minPrice: Scalars["BigDecimal"]["output"];
  /** token minimum price, as a Q64.96 */
  minPriceX96: Scalars["BigInt"]["output"];
  /** token price */
  price: Scalars["BigDecimal"]["output"];
  /** pointer to price state */
  priceState: PriceState;
  /** token price, as a Q64.96 */
  priceX96: Scalars["BigInt"]["output"];
};

export type PriceInfo_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PriceInfo_Filter>>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  maxPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  maxPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  minPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  minPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<PriceInfo_Filter>>>;
  price?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  priceState?: InputMaybe<Scalars["String"]["input"]>;
  priceState_?: InputMaybe<PriceState_Filter>;
  priceState_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceState_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_gt?: InputMaybe<Scalars["String"]["input"]>;
  priceState_gte?: InputMaybe<Scalars["String"]["input"]>;
  priceState_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceState_lt?: InputMaybe<Scalars["String"]["input"]>;
  priceState_lte?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceState_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  priceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  price_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  price_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  price_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  price_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  price_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  price_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  price_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum PriceInfo_OrderBy {
  Id = "id",
  MaxPrice = "maxPrice",
  MaxPriceX96 = "maxPriceX96",
  MinPrice = "minPrice",
  MinPriceX96 = "minPriceX96",
  Price = "price",
  PriceState = "priceState",
  PriceStateBasisIndexPriceX96 = "priceState__basisIndexPriceX96",
  PriceStateId = "priceState__id",
  PriceStateIndexPriceUsedX96 = "priceState__indexPriceUsedX96",
  PriceStatePendingVertexIndex = "priceState__pendingVertexIndex",
  PriceStatePendingVertexIndexBitmap = "priceState__pendingVertexIndexBitmap",
  PriceStatePremiumRateX96 = "priceState__premiumRateX96",
  PriceX96 = "priceX96",
}

export type PriceState = {
  __typename?: "PriceState";
  /** the basis index price, as a Q64.96 */
  basisIndexPriceX96: Scalars["BigInt"]["output"];
  /** market address */
  id: Scalars["Bytes"]["output"];
  /** the index price used */
  indexPriceUsedX96: Scalars["BigInt"]["output"];
  /** the net sizes of the liquidation buffer */
  liquidationBufferNetSizes?: Maybe<Array<Scalars["BigDecimal"]["output"]>>;
  /** pointer to market */
  market: Market;
  /** the index used to track the pending update of the price vertex */
  pendingVertexIndex: Scalars["Int"]["output"];
  /** the bitmap used to calculate pending vertex index */
  pendingVertexIndexBitmap: Scalars["Int"]["output"];
  /** the premium rate during the last position adjustment by the trader, as a Q32.96 */
  premiumRateX96: Scalars["BigInt"]["output"];
  /** pointer to price info */
  priceInfo: PriceInfo;
  /** the price vertices used to determine the price curve */
  priceVertices: Array<PriceVertex>;
};

export type PriceStatePriceVerticesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceVertex_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PriceVertex_Filter>;
};

export type PriceState1H = {
  __typename?: "PriceState1H";
  /** the balance rate, denominated in a bip (i.e. 1e-8) */
  balanceRate: Scalars["BigDecimal"]["output"];
  /** format: <market address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the size of the net position held by all LPs in the liquidation buffer */
  liquidationBufferNetSize: Scalars["BigDecimal"]["output"];
  /** total liquidity held by all LPs */
  liquidity: Scalars["BigDecimal"]["output"];
  /** pointer to market */
  market: Market;
  /** the size of the net position held by all LPs */
  netSize: Scalars["BigDecimal"]["output"];
  /** the side of the LP position, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** the start time of the price state */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
};

export type PriceState1H_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PriceState1H_Filter>>>;
  balanceRate?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  balanceRate_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  liquidationBufferNetSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  netSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  netSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<PriceState1H_Filter>>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

export enum PriceState1H_OrderBy {
  BalanceRate = "balanceRate",
  Id = "id",
  LiquidationBufferNetSize = "liquidationBufferNetSize",
  Liquidity = "liquidity",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  NetSize = "netSize",
  Side = "side",
  Ts = "ts",
  TxCount = "txCount",
}

export type PriceState_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PriceState_Filter>>>;
  basisIndexPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  basisIndexPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  basisIndexPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  basisIndexPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  basisIndexPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  basisIndexPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  basisIndexPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  basisIndexPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  indexPriceUsedX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  indexPriceUsedX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidationBufferNetSizes?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationBufferNetSizes_contains?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSizes_contains_nocase?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSizes_not?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSizes_not_contains?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSizes_not_contains_nocase?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<PriceState_Filter>>>;
  pendingVertexIndex?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_gt?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_gte?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  pendingVertexIndexBitmap_lt?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_lte?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_not?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  pendingVertexIndex_gt?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndex_gte?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndex_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  pendingVertexIndex_lt?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndex_lte?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndex_not?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndex_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  premiumRateX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  premiumRateX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  priceInfo?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_?: InputMaybe<PriceInfo_Filter>;
  priceInfo_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_gt?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_gte?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceInfo_lt?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_lte?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_not?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceInfo_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceInfo_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceVertices_?: InputMaybe<PriceVertex_Filter>;
};

export enum PriceState_OrderBy {
  BasisIndexPriceX96 = "basisIndexPriceX96",
  Id = "id",
  IndexPriceUsedX96 = "indexPriceUsedX96",
  LiquidationBufferNetSizes = "liquidationBufferNetSizes",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  PendingVertexIndex = "pendingVertexIndex",
  PendingVertexIndexBitmap = "pendingVertexIndexBitmap",
  PremiumRateX96 = "premiumRateX96",
  PriceInfo = "priceInfo",
  PriceInfoId = "priceInfo__id",
  PriceInfoMaxPrice = "priceInfo__maxPrice",
  PriceInfoMaxPriceX96 = "priceInfo__maxPriceX96",
  PriceInfoMinPrice = "priceInfo__minPrice",
  PriceInfoMinPriceX96 = "priceInfo__minPriceX96",
  PriceInfoPrice = "priceInfo__price",
  PriceInfoPriceX96 = "priceInfo__priceX96",
  PriceVertices = "priceVertices",
}

export type PriceVertex = {
  __typename?: "PriceVertex";
  /** format: <market address>:<index> */
  id: Scalars["ID"]["output"];
  /** the premium rate during the last position adjustment by the LP, as a Q32.96 */
  premiumRateX96: Scalars["BigInt"]["output"];
  /** pointer to price state */
  priceState: PriceState;
  /** size required to achieve the current premium rate */
  size: Scalars["BigDecimal"]["output"];
};

export type PriceVertex_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PriceVertex_Filter>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<PriceVertex_Filter>>>;
  premiumRateX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  premiumRateX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  priceState?: InputMaybe<Scalars["String"]["input"]>;
  priceState_?: InputMaybe<PriceState_Filter>;
  priceState_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceState_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_gt?: InputMaybe<Scalars["String"]["input"]>;
  priceState_gte?: InputMaybe<Scalars["String"]["input"]>;
  priceState_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceState_lt?: InputMaybe<Scalars["String"]["input"]>;
  priceState_lte?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceState_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  size_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum PriceVertex_OrderBy {
  Id = "id",
  PremiumRateX96 = "premiumRateX96",
  PriceState = "priceState",
  PriceStateBasisIndexPriceX96 = "priceState__basisIndexPriceX96",
  PriceStateId = "priceState__id",
  PriceStateIndexPriceUsedX96 = "priceState__indexPriceUsedX96",
  PriceStatePendingVertexIndex = "priceState__pendingVertexIndex",
  PriceStatePendingVertexIndexBitmap = "priceState__pendingVertexIndexBitmap",
  PriceStatePremiumRateX96 = "priceState__premiumRateX96",
  Size = "size",
}

export type Query = {
  __typename?: "Query";
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  candle1D?: Maybe<Candle1D>;
  candle1Ds: Array<Candle1D>;
  candle1H?: Maybe<Candle1H>;
  candle1Hs: Array<Candle1H>;
  candle1M?: Maybe<Candle1M>;
  candle1Ms: Array<Candle1M>;
  candle4H?: Maybe<Candle4H>;
  candle4Hs: Array<Candle4H>;
  candle5M?: Maybe<Candle5M>;
  candle5Ms: Array<Candle5M>;
  candle15M?: Maybe<Candle15M>;
  candle15Ms: Array<Candle15M>;
  candle30M?: Maybe<Candle30M>;
  candle30Ms: Array<Candle30M>;
  decreaseLiquidityPositionRequest?: Maybe<DecreaseLiquidityPositionRequest>;
  decreaseLiquidityPositionRequests: Array<DecreaseLiquidityPositionRequest>;
  decreasePositionRequest?: Maybe<DecreasePositionRequest>;
  decreasePositionRequests: Array<DecreasePositionRequest>;
  globalLiquidationFund?: Maybe<GlobalLiquidationFund>;
  globalLiquidationFunds: Array<GlobalLiquidationFund>;
  globalLiquidityPosition?: Maybe<GlobalLiquidityPosition>;
  globalLiquidityPositions: Array<GlobalLiquidityPosition>;
  globalPosition?: Maybe<GlobalPosition>;
  globalPositions: Array<GlobalPosition>;
  increaseLiquidityPositionRequest?: Maybe<IncreaseLiquidityPositionRequest>;
  increaseLiquidityPositionRequests: Array<IncreaseLiquidityPositionRequest>;
  increasePositionRequest?: Maybe<IncreasePositionRequest>;
  increasePositionRequests: Array<IncreasePositionRequest>;
  liquidationFundPosition?: Maybe<LiquidationFundPosition>;
  liquidationFundPositions: Array<LiquidationFundPosition>;
  liquidityPosition?: Maybe<LiquidityPosition>;
  liquidityPositionHistories: Array<LiquidityPositionHistory>;
  liquidityPositionHistory?: Maybe<LiquidityPositionHistory>;
  liquidityPositions: Array<LiquidityPosition>;
  market?: Maybe<Market>;
  marketBaseConfig?: Maybe<MarketBaseConfig>;
  marketBaseConfigs: Array<MarketBaseConfig>;
  marketFeeRateConfig?: Maybe<MarketFeeRateConfig>;
  marketFeeRateConfigs: Array<MarketFeeRateConfig>;
  marketPriceConfig?: Maybe<MarketPriceConfig>;
  marketPriceConfigs: Array<MarketPriceConfig>;
  markets: Array<Market>;
  order?: Maybe<Order>;
  orderBookConfig?: Maybe<OrderBookConfig>;
  orderBookConfigs: Array<OrderBookConfig>;
  orders: Array<Order>;
  position?: Maybe<Position>;
  positionHistories: Array<PositionHistory>;
  positionHistory?: Maybe<PositionHistory>;
  positionRouterConfig?: Maybe<PositionRouterConfig>;
  positionRouterConfigs: Array<PositionRouterConfig>;
  positions: Array<Position>;
  priceInfo?: Maybe<PriceInfo>;
  priceInfos: Array<PriceInfo>;
  priceState?: Maybe<PriceState>;
  priceState1H?: Maybe<PriceState1H>;
  priceState1Hs: Array<PriceState1H>;
  priceStates: Array<PriceState>;
  priceVertex?: Maybe<PriceVertex>;
  priceVertexes: Array<PriceVertex>;
  transactionHistories: Array<TransactionHistory>;
  transactionHistory?: Maybe<TransactionHistory>;
  vertexConfig?: Maybe<VertexConfig>;
  vertexConfigs: Array<VertexConfig>;
};

export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type QueryCandle1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1D_Filter>;
};

export type QueryCandle1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1H_Filter>;
};

export type QueryCandle1MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle1MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1M_Filter>;
};

export type QueryCandle4HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle4HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle4H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle4H_Filter>;
};

export type QueryCandle5MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle5MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle5M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle5M_Filter>;
};

export type QueryCandle15MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle15MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle15M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle15M_Filter>;
};

export type QueryCandle30MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle30MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle30M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle30M_Filter>;
};

export type QueryDecreaseLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryDecreaseLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<DecreaseLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreaseLiquidityPositionRequest_Filter>;
};

export type QueryDecreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryDecreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<DecreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreasePositionRequest_Filter>;
};

export type QueryGlobalLiquidationFundArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryGlobalLiquidationFundsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalLiquidationFund_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidationFund_Filter>;
};

export type QueryGlobalLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryGlobalLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalLiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidityPosition_Filter>;
};

export type QueryGlobalPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryGlobalPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalPosition_Filter>;
};

export type QueryIncreaseLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryIncreaseLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<IncreaseLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreaseLiquidityPositionRequest_Filter>;
};

export type QueryIncreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryIncreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<IncreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreasePositionRequest_Filter>;
};

export type QueryLiquidationFundPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryLiquidationFundPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LiquidationFundPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidationFundPosition_Filter>;
};

export type QueryLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryLiquidityPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LiquidityPositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPositionHistory_Filter>;
};

export type QueryLiquidityPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPosition_Filter>;
};

export type QueryMarketArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryMarketBaseConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryMarketBaseConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MarketBaseConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketBaseConfig_Filter>;
};

export type QueryMarketFeeRateConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryMarketFeeRateConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MarketFeeRateConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketFeeRateConfig_Filter>;
};

export type QueryMarketPriceConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryMarketPriceConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MarketPriceConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketPriceConfig_Filter>;
};

export type QueryMarketsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Market_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Market_Filter>;
};

export type QueryOrderArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryOrderBookConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryOrderBookConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<OrderBookConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OrderBookConfig_Filter>;
};

export type QueryOrdersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Order_Filter>;
};

export type QueryPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionHistory_Filter>;
};

export type QueryPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPositionRouterConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPositionRouterConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PositionRouterConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionRouterConfig_Filter>;
};

export type QueryPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};

export type QueryPriceInfoArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPriceInfosArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceInfo_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceInfo_Filter>;
};

export type QueryPriceStateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPriceState1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPriceState1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceState1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState1H_Filter>;
};

export type QueryPriceStatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceState_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState_Filter>;
};

export type QueryPriceVertexArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPriceVertexesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceVertex_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceVertex_Filter>;
};

export type QueryTransactionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<TransactionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransactionHistory_Filter>;
};

export type QueryTransactionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryVertexConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryVertexConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<VertexConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VertexConfig_Filter>;
};

export enum Status {
  Cancelled = "Cancelled",
  Created = "Created",
  Executed = "Executed",
}

export type Subscription = {
  __typename?: "Subscription";
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  candle1D?: Maybe<Candle1D>;
  candle1Ds: Array<Candle1D>;
  candle1H?: Maybe<Candle1H>;
  candle1Hs: Array<Candle1H>;
  candle1M?: Maybe<Candle1M>;
  candle1Ms: Array<Candle1M>;
  candle4H?: Maybe<Candle4H>;
  candle4Hs: Array<Candle4H>;
  candle5M?: Maybe<Candle5M>;
  candle5Ms: Array<Candle5M>;
  candle15M?: Maybe<Candle15M>;
  candle15Ms: Array<Candle15M>;
  candle30M?: Maybe<Candle30M>;
  candle30Ms: Array<Candle30M>;
  decreaseLiquidityPositionRequest?: Maybe<DecreaseLiquidityPositionRequest>;
  decreaseLiquidityPositionRequests: Array<DecreaseLiquidityPositionRequest>;
  decreasePositionRequest?: Maybe<DecreasePositionRequest>;
  decreasePositionRequests: Array<DecreasePositionRequest>;
  globalLiquidationFund?: Maybe<GlobalLiquidationFund>;
  globalLiquidationFunds: Array<GlobalLiquidationFund>;
  globalLiquidityPosition?: Maybe<GlobalLiquidityPosition>;
  globalLiquidityPositions: Array<GlobalLiquidityPosition>;
  globalPosition?: Maybe<GlobalPosition>;
  globalPositions: Array<GlobalPosition>;
  increaseLiquidityPositionRequest?: Maybe<IncreaseLiquidityPositionRequest>;
  increaseLiquidityPositionRequests: Array<IncreaseLiquidityPositionRequest>;
  increasePositionRequest?: Maybe<IncreasePositionRequest>;
  increasePositionRequests: Array<IncreasePositionRequest>;
  liquidationFundPosition?: Maybe<LiquidationFundPosition>;
  liquidationFundPositions: Array<LiquidationFundPosition>;
  liquidityPosition?: Maybe<LiquidityPosition>;
  liquidityPositionHistories: Array<LiquidityPositionHistory>;
  liquidityPositionHistory?: Maybe<LiquidityPositionHistory>;
  liquidityPositions: Array<LiquidityPosition>;
  market?: Maybe<Market>;
  marketBaseConfig?: Maybe<MarketBaseConfig>;
  marketBaseConfigs: Array<MarketBaseConfig>;
  marketFeeRateConfig?: Maybe<MarketFeeRateConfig>;
  marketFeeRateConfigs: Array<MarketFeeRateConfig>;
  marketPriceConfig?: Maybe<MarketPriceConfig>;
  marketPriceConfigs: Array<MarketPriceConfig>;
  markets: Array<Market>;
  order?: Maybe<Order>;
  orderBookConfig?: Maybe<OrderBookConfig>;
  orderBookConfigs: Array<OrderBookConfig>;
  orders: Array<Order>;
  position?: Maybe<Position>;
  positionHistories: Array<PositionHistory>;
  positionHistory?: Maybe<PositionHistory>;
  positionRouterConfig?: Maybe<PositionRouterConfig>;
  positionRouterConfigs: Array<PositionRouterConfig>;
  positions: Array<Position>;
  priceInfo?: Maybe<PriceInfo>;
  priceInfos: Array<PriceInfo>;
  priceState?: Maybe<PriceState>;
  priceState1H?: Maybe<PriceState1H>;
  priceState1Hs: Array<PriceState1H>;
  priceStates: Array<PriceState>;
  priceVertex?: Maybe<PriceVertex>;
  priceVertexes: Array<PriceVertex>;
  transactionHistories: Array<TransactionHistory>;
  transactionHistory?: Maybe<TransactionHistory>;
  vertexConfig?: Maybe<VertexConfig>;
  vertexConfigs: Array<VertexConfig>;
};

export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type SubscriptionCandle1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1D_Filter>;
};

export type SubscriptionCandle1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1H_Filter>;
};

export type SubscriptionCandle1MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle1MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1M_Filter>;
};

export type SubscriptionCandle4HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle4HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle4H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle4H_Filter>;
};

export type SubscriptionCandle5MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle5MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle5M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle5M_Filter>;
};

export type SubscriptionCandle15MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle15MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle15M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle15M_Filter>;
};

export type SubscriptionCandle30MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle30MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle30M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle30M_Filter>;
};

export type SubscriptionDecreaseLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionDecreaseLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<DecreaseLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreaseLiquidityPositionRequest_Filter>;
};

export type SubscriptionDecreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionDecreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<DecreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreasePositionRequest_Filter>;
};

export type SubscriptionGlobalLiquidationFundArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionGlobalLiquidationFundsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalLiquidationFund_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidationFund_Filter>;
};

export type SubscriptionGlobalLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionGlobalLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalLiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidityPosition_Filter>;
};

export type SubscriptionGlobalPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionGlobalPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalPosition_Filter>;
};

export type SubscriptionIncreaseLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionIncreaseLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<IncreaseLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreaseLiquidityPositionRequest_Filter>;
};

export type SubscriptionIncreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionIncreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<IncreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreasePositionRequest_Filter>;
};

export type SubscriptionLiquidationFundPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionLiquidationFundPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LiquidationFundPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidationFundPosition_Filter>;
};

export type SubscriptionLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionLiquidityPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LiquidityPositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPositionHistory_Filter>;
};

export type SubscriptionLiquidityPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPosition_Filter>;
};

export type SubscriptionMarketArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionMarketBaseConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionMarketBaseConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MarketBaseConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketBaseConfig_Filter>;
};

export type SubscriptionMarketFeeRateConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionMarketFeeRateConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MarketFeeRateConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketFeeRateConfig_Filter>;
};

export type SubscriptionMarketPriceConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionMarketPriceConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MarketPriceConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketPriceConfig_Filter>;
};

export type SubscriptionMarketsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Market_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Market_Filter>;
};

export type SubscriptionOrderArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionOrderBookConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionOrderBookConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<OrderBookConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OrderBookConfig_Filter>;
};

export type SubscriptionOrdersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Order_Filter>;
};

export type SubscriptionPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionHistory_Filter>;
};

export type SubscriptionPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPositionRouterConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPositionRouterConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PositionRouterConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionRouterConfig_Filter>;
};

export type SubscriptionPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};

export type SubscriptionPriceInfoArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPriceInfosArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceInfo_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceInfo_Filter>;
};

export type SubscriptionPriceStateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPriceState1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPriceState1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceState1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState1H_Filter>;
};

export type SubscriptionPriceStatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceState_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState_Filter>;
};

export type SubscriptionPriceVertexArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPriceVertexesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceVertex_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceVertex_Filter>;
};

export type SubscriptionTransactionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<TransactionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransactionHistory_Filter>;
};

export type SubscriptionTransactionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionVertexConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionVertexConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<VertexConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VertexConfig_Filter>;
};

/** entity that represents a transaction history */
export type TransactionHistory = {
  __typename?: "TransactionHistory";
  /** the owner of the transaction history */
  account: Scalars["Bytes"]["output"];
  /** block which transaction was included in */
  blockNumber: Scalars["BigInt"]["output"];
  /** timestamp of the confirmed transaction */
  blockTimestamp: Scalars["BigInt"]["output"];
  /** JSON-formatted trade data */
  data: Scalars["String"]["output"];
  /** the funding fee */
  fundingFee: Scalars["BigDecimal"]["output"];
  /** format: <transaction hash>:<log index> */
  id: Scalars["ID"]["output"];
  /** pointer to market */
  market: Market;
  /**
   * operation of the transaction history.
   * enum{Open,Close,AdjustMargin,Liquidate,GovUseLiquidationFund,
   * OpenPosition,IncreaseMargin,IncreasePosition,DecreasePosition,ClosePosition,DecreaseMargin,
   * CreateIncreaseOrder,UpdateIncreaseOrder,CancelIncreaseOrder,ExecuteIncreaseOrder,
   * CreateDecreaseOrder,UpdateDecreaseOrder,CancelDecreaseOrder,ExecuteDecreaseOrder,
   * CreateIncreaseLiquidityPosition,CancelIncreaseLiquidityPosition,
   * CreateDecreaseLiquidityPosition,CancelDecreaseLiquidityPosition,
   * CreateIncreasePosition,CancelIncreasePosition,
   * CreateDecreasePosition,CancelDecreasePosition}
   */
  operation: TransactionHistoryOperation;
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** tx hash of the confirmed transaction */
  txHash: Scalars["Bytes"]["output"];
  /** type of the transaction history, enum{Liquidity,Position,Orderbook,PositionRouter} */
  type: TransactionHistoryType;
};

export enum TransactionHistoryOperation {
  CancelDecreaseLiquidityPosition = "CancelDecreaseLiquidityPosition",
  CancelDecreaseOrder = "CancelDecreaseOrder",
  CancelDecreasePosition = "CancelDecreasePosition",
  CancelIncreaseLiquidityPosition = "CancelIncreaseLiquidityPosition",
  CancelIncreaseOrder = "CancelIncreaseOrder",
  CancelIncreasePosition = "CancelIncreasePosition",
  ClosePosition = "ClosePosition",
  CreateDecreaseLiquidityPosition = "CreateDecreaseLiquidityPosition",
  CreateDecreaseOrder = "CreateDecreaseOrder",
  CreateDecreasePosition = "CreateDecreasePosition",
  CreateIncreaseLiquidityPosition = "CreateIncreaseLiquidityPosition",
  CreateIncreaseOrder = "CreateIncreaseOrder",
  CreateIncreasePosition = "CreateIncreasePosition",
  DecreaseMargin = "DecreaseMargin",
  DecreasePosition = "DecreasePosition",
  ExecuteDecreaseOrder = "ExecuteDecreaseOrder",
  ExecuteIncreaseOrder = "ExecuteIncreaseOrder",
  IncreaseMargin = "IncreaseMargin",
  IncreasePosition = "IncreasePosition",
  IncreasedByLiquidation = "IncreasedByLiquidation",
  Liquidate = "Liquidate",
  LiquidityPositionLiquidated = "LiquidityPositionLiquidated",
  OpenPosition = "OpenPosition",
  UpdateDecreaseOrder = "UpdateDecreaseOrder",
  UpdateIncreaseOrder = "UpdateIncreaseOrder",
}

export enum TransactionHistoryType {
  LiquidationFund = "LiquidationFund",
  Liquidity = "Liquidity",
  Orderbook = "Orderbook",
  Position = "Position",
  PositionRouter = "PositionRouter",
}

export type TransactionHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<TransactionHistory_Filter>>>;
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  data?: InputMaybe<Scalars["String"]["input"]>;
  data_contains?: InputMaybe<Scalars["String"]["input"]>;
  data_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  data_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  data_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  data_gt?: InputMaybe<Scalars["String"]["input"]>;
  data_gte?: InputMaybe<Scalars["String"]["input"]>;
  data_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  data_lt?: InputMaybe<Scalars["String"]["input"]>;
  data_lte?: InputMaybe<Scalars["String"]["input"]>;
  data_not?: InputMaybe<Scalars["String"]["input"]>;
  data_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  data_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  data_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  data_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  data_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  data_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  data_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  data_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  data_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  fundingFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  market?: InputMaybe<Scalars["String"]["input"]>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_gt?: InputMaybe<Scalars["String"]["input"]>;
  market_gte?: InputMaybe<Scalars["String"]["input"]>;
  market_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_lt?: InputMaybe<Scalars["String"]["input"]>;
  market_lte?: InputMaybe<Scalars["String"]["input"]>;
  market_not?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  market_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  market_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  market_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  operation?: InputMaybe<TransactionHistoryOperation>;
  operation_in?: InputMaybe<Array<TransactionHistoryOperation>>;
  operation_not?: InputMaybe<TransactionHistoryOperation>;
  operation_not_in?: InputMaybe<Array<TransactionHistoryOperation>>;
  or?: InputMaybe<Array<InputMaybe<TransactionHistory_Filter>>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  txHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  txHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  type?: InputMaybe<TransactionHistoryType>;
  type_in?: InputMaybe<Array<TransactionHistoryType>>;
  type_not?: InputMaybe<TransactionHistoryType>;
  type_not_in?: InputMaybe<Array<TransactionHistoryType>>;
};

export enum TransactionHistory_OrderBy {
  Account = "account",
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  Data = "data",
  FundingFee = "fundingFee",
  Id = "id",
  Market = "market",
  MarketId = "market__id",
  MarketLiquidationFundGovUsed = "market__liquidationFundGovUsed",
  MarketProtocolFee = "market__protocolFee",
  MarketSymbol = "market__symbol",
  MarketTotalProtocolFee = "market__totalProtocolFee",
  MarketVolume = "market__volume",
  MarketVolumeUsd = "market__volumeUSD",
  Operation = "operation",
  Side = "side",
  TxHash = "txHash",
  Type = "type",
}

export type VertexConfig = {
  __typename?: "VertexConfig";
  /** the balance rate of the vertex, denominated in a bip (i.e. 1e-8) */
  balanceRate: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the market price config */
  marketPriceConfig: MarketPriceConfig;
  /** the premium rate of the vertex, denominated in a bip (i.e. 1e-8) */
  premiumRate: Scalars["BigInt"]["output"];
};

export type VertexConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<VertexConfig_Filter>>>;
  balanceRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  balanceRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  marketPriceConfig?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_?: InputMaybe<MarketPriceConfig_Filter>;
  marketPriceConfig_contains?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_gt?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_gte?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  marketPriceConfig_lt?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_lte?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_not?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  marketPriceConfig_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  marketPriceConfig_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  marketPriceConfig_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  marketPriceConfig_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  marketPriceConfig_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<VertexConfig_Filter>>>;
  premiumRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  premiumRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

export enum VertexConfig_OrderBy {
  BalanceRate = "balanceRate",
  Id = "id",
  MarketPriceConfig = "marketPriceConfig",
  MarketPriceConfigId = "marketPriceConfig__id",
  MarketPriceConfigLiquidationVertexIndex = "marketPriceConfig__liquidationVertexIndex",
  MarketPriceConfigMaxPriceImpactLiquidity = "marketPriceConfig__maxPriceImpactLiquidity",
  PremiumRate = "premiumRate",
}

export type _Block_ = {
  __typename?: "_Block_";
  /** The hash of the block */
  hash?: Maybe<Scalars["Bytes"]["output"]>;
  /** The block number */
  number: Scalars["Int"]["output"];
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars["Bytes"]["output"]>;
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars["Int"]["output"]>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: "_Meta_";
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars["String"]["output"];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars["Boolean"]["output"];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = "allow",
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = "deny",
}

export type MarketsQueryVariables = Exact<{
  block24h?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type MarketsQuery = {
  __typename?: "Query";
  markets: Array<{
    __typename?: "Market";
    id: any;
    volume: any;
    volumeUSD: any;
    globalLiquidityPosition: {
      __typename?: "GlobalLiquidityPosition";
      netSize: any;
      liquidationBufferNetSize: any;
      liquidity: any;
    };
    globalPosition: {
      __typename?: "GlobalPosition";
      longSize: any;
      shortSize: any;
    };
    positions: Array<{ __typename?: "Position"; entryPriceX96: any }>;
  }>;
  past: Array<{
    __typename?: "Market";
    id: any;
    volume: any;
    volumeUSD: any;
    globalLiquidityPosition: {
      __typename?: "GlobalLiquidityPosition";
      netSize: any;
      liquidationBufferNetSize: any;
    };
    globalPosition: {
      __typename?: "GlobalPosition";
      longSize: any;
      shortSize: any;
    };
    positions: Array<{ __typename?: "Position"; entryPriceX96: any }>;
  }>;
};

export const MarketsDocument = gql`
  query Markets($block24h: Int) {
    markets {
      id
      globalLiquidityPosition {
        netSize
        liquidationBufferNetSize
        liquidity
      }
      globalPosition {
        longSize
        shortSize
      }
      positions {
        entryPriceX96
      }
      volume
      volumeUSD
    }
    past: markets(block: { number: $block24h }) {
      id
      globalLiquidityPosition {
        netSize
        liquidationBufferNetSize
      }
      globalPosition {
        longSize
        shortSize
      }
      positions {
        entryPriceX96
      }
      volume
      volumeUSD
    }
  }
`;

/**
 * __useMarketsQuery__
 *
 * To run a query within a React component, call `useMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketsQuery({
 *   variables: {
 *      block24h: // value for 'block24h'
 *   },
 * });
 */
export function useMarketsQuery(
  baseOptions?: Apollo.QueryHookOptions<MarketsQuery, MarketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketsQuery, MarketsQueryVariables>(
    MarketsDocument,
    options
  );
}
export function useMarketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MarketsQuery, MarketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MarketsQuery, MarketsQueryVariables>(
    MarketsDocument,
    options
  );
}
export function useMarketsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MarketsQuery,
    MarketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MarketsQuery, MarketsQueryVariables>(
    MarketsDocument,
    options
  );
}
export type MarketsQueryHookResult = ReturnType<typeof useMarketsQuery>;
export type MarketsLazyQueryHookResult = ReturnType<typeof useMarketsLazyQuery>;
export type MarketsSuspenseQueryHookResult = ReturnType<
  typeof useMarketsSuspenseQuery
>;
export type MarketsQueryResult = Apollo.QueryResult<
  MarketsQuery,
  MarketsQueryVariables
>;
