import { useEffect } from "react";
import { useInterval, useUpdateEffect } from "react-use";

import { GLOBAL_INTERVAL_TIMEOUT } from "config/constants";
import { DEFAULT_CHAIN } from "config/constants/connector/chains";
import { WRAPPED_NATIVE_CURRENCY } from "config/constants/tokens";
import moment from "moment";

import { useCurrentChain } from "hooks/useCurrentChain";
import useIsWindowVisible from "hooks/useIsWindowVisible";

import { useAppDispatch, useAppSelector } from "../hooks";
import {
  setAppTimer,
  setBlockHeight,
  setBlockHeightBefore24h,
  setBlockTimestamp,
  setChain,
  setNativePriceUsd,
} from "./slice";
import useBlockNumber, {
  useGetBlockNumbers,
  useGetBlockNumbersForUni,
} from "hooks/useBlockNumber";

export default function GlobalUpdater() {
  const dispatch = useAppDispatch();

  const isWindowVisible = useIsWindowVisible();
  const { currentChain, currentChainId, checkChain } = useCurrentChain();

  const {
    blockLatest,
    blockLatestTimestamp,
    blockBefore24h,
    refechBlockNumbers,
  } = useGetBlockNumbers();

  const {
    blockLatest: blockLatestForUni,
    blockLatestTimestamp: blockLatestTimestampForUni,
    blockBefore24h: blockBefore24hForUni,
    refechBlockNumbers: refechBlockNumbersForUni,
  } = useGetBlockNumbersForUni();

  useUpdateEffect(() => {
    if (currentChain.testnet) {
      if (blockLatest) {
        // console.log('[new block number] blockNumber: ', blockLatest);
        // console.log('blockLatestTimestamp: ', blockLatestTimestamp);
        dispatch(setBlockHeight(blockLatest));
        dispatch(setBlockTimestamp(blockLatestTimestamp));
      }
      return;
    }
    if (blockLatestForUni) {
      // console.log('[new block number] blockLatestForUni: ', blockLatestForUni);
      // console.log('blockLatestTimestampForUni: ', blockLatestTimestampForUni);
      dispatch(setBlockHeight(blockLatestForUni));
      dispatch(setBlockTimestamp(blockLatestTimestampForUni));
    }
  }, [
    currentChain,
    blockLatest,
    blockLatestTimestamp,
    blockLatestForUni,
    blockLatestTimestampForUni,
  ]);

  useUpdateEffect(() => {
    if (currentChain.testnet) {
      if (blockBefore24h) {
        dispatch(setBlockHeightBefore24h(blockBefore24h));
      }
      return;
    }
    if (blockBefore24hForUni) {
      dispatch(setBlockHeightBefore24h(blockBefore24hForUni));
    }
  }, [blockBefore24h, blockBefore24hForUni, currentChain]);

  useEffect(() => {
    dispatch(setAppTimer(moment().unix()));
    if (currentChain.testnet) {
      refechBlockNumbers();
      return;
    }
    refechBlockNumbersForUni();
  }, [currentChain]);

  useInterval(
    () => {
      dispatch(setAppTimer(moment().unix()));
      if (currentChain.testnet) {
        refechBlockNumbers();
        return;
      }
      refechBlockNumbersForUni();
    },
    isWindowVisible ? GLOBAL_INTERVAL_TIMEOUT : null
  );

  // 设置当前默认链
  useEffect(() => {
    if (!checkChain(currentChainId)) {
      dispatch(setChain(DEFAULT_CHAIN));
    }
  }, []);

  return null;
}
