export const MediumIcon = (props: any) => {
  const { color = "white", size = 20, ...restProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clip-path="url(#clip0_31536_18902)">
        <path
          d="M4.68922 4.75684C2.32165 4.75684 0.402344 6.46748 0.402344 8.57748C0.402344 10.6875 2.32179 12.3981 4.68922 12.3981C7.05664 12.3981 8.97609 10.6876 8.97609 8.57748C8.97609 6.46736 7.05688 4.75684 4.68922 4.75684ZM11.5352 4.98064C10.3513 4.98064 9.39184 6.59139 9.39184 8.57754C9.39184 10.5637 10.3515 12.1745 11.5353 12.1745C12.7191 12.1745 13.6788 10.5638 13.6788 8.57754H13.6786C13.6786 6.59082 12.7191 4.98064 11.5352 4.98064H11.5352ZM14.8484 5.35529C14.4322 5.35529 14.0947 6.79804 14.0947 8.57754C14.0947 10.357 14.432 11.7998 14.8484 11.7998C15.2649 11.7998 15.6023 10.3567 15.6023 8.57748C15.6023 6.79792 15.2647 5.35529 14.8485 5.35529H14.8484Z"
          fill="white"
          fill-opacity="0.9"
        />
      </g>
      <defs>
        <clipPath id="clip0_31536_18902">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.00390625 0.577148)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
