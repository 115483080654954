import React, { useRef, useLayoutEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useTheme, styled, useMediaQuery } from "@mui/material";
import bgIcon from "../../assets/index/roadmap/roadmap-bg.png";
import linerIcon from "../../assets/index/roadmap/roadmap-liner.svg";
import { alpha } from "@mui/material";
import cn from "classnames";
import { useAppBreakpoints } from "../../hooks/useAppBreakpoints";

export default function Roadmap() {
  const theme = useTheme();
  const isUnder1200 = useMediaQuery("(max-width: 1200px)");
  const { isMatchMobile, isMatchPad } = useAppBreakpoints();
  return (
    <Box
      className="w-full flex justify-center"
      sx={{
        background: isMatchMobile
          ? "initial"
          : `url('${bgIcon}') no-repeat center center`,
      }}
    >
      <div className="content flex flex-col">
        <div className="">
          <div className="block-title text-center font-[500] text-[40px] sm:text-2xl">
            Roadmap
          </div>
        </div>
        <div className="relative pb-5 mt-1.5 sm:pb-1">
          <Box className="liner absolute top-0 left-0 -z-0 flex justify-center overflow-hidden sm:hidden md:flex">
            <img src={linerIcon} alt="" width="1200" />
          </Box>
          <StyleRadius
            className={cn(
              "absolute top-5.125 -right-1",
              isMatchPad || (isUnder1200 && !isMatchMobile) ? "block" : "hidden"
            )}
          >
            <div className="inner"></div>
          </StyleRadius>
          <Box className="pl-8 pr-4 md:pl-6 md:pr-2 top-4.5 relative sm:pl-3.5 sm:pr-0 sm:flex sm:top-1">
            <StyledLeftLiner
              orientation="vertical"
              className="hidden sm:block"
            />
            <div className="sm:space-y-2 sm:-ml-[9px] sm:mt-1">
              <Box className="grid grid-cols-3 gap-x-5 md:gap-x-2 h-[264px] sm:h-auto sm:grid-cols-1 sm:gap-y-2">
                <div
                  className={cn(
                    "space-y-0.75 text-xl underLg:text-base sm:flex sm:space-y-0 sm:space-x-1"
                  )}
                >
                  <div className="">
                    <StyleItemIcon className="flex items-center justify-center sm:mt-0.325 ">
                      <div className="inner" />
                    </StyleItemIcon>
                  </div>
                  <div className="space-y-0.75">
                    <Typography
                      color="primary"
                      variant="inherit"
                      className="font-medium"
                    >
                      09/2023
                    </Typography>
                    <div className="text-textPrimary90">
                      <div className="flex items-center gap-0.625">
                        <StyledIcon />
                        <span>Testnet</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-0.75 text-xl underLg:text-base sm:flex sm:space-y-0 sm:space-x-1">
                  <StyleItemIcon className="flex items-center justify-center sm:mt-0.325">
                    <div className="inner" />
                  </StyleItemIcon>
                  <div className="space-y-0.75">
                    <Typography
                      color="primary"
                      variant="inherit"
                      className="font-medium"
                    >
                      10/2023
                    </Typography>
                    <div className="text-textPrimary90 space-y-0.75">
                      <div className="flex items-center space-x-0.625">
                        <StyledIcon className="primary" />{" "}
                        <span className="text-textPrimary90">Mainnet</span>
                      </div>
                      <div className="flex items-center space-x-0.625">
                        <StyledIcon className="primary" />{" "}
                        <span className="text-textPrimary90">
                          EQU Fair Launch
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-0.75 text-xl underLg:text-base sm:flex sm:space-y-0 sm:space-x-1">
                  <StyleItemIcon className="flex items-center justify-center sm:mt-0.325">
                    <div className="inner" />
                  </StyleItemIcon>
                  <div className="space-y-0.75">
                    <Typography
                      color="primary"
                      variant="inherit"
                      className="font-medium"
                    >
                      11/2023
                    </Typography>
                    <div className="text-textPrimary90 space-y-0.75">
                      <div className="flex items-center space-x-0.625">
                        <StyledIcon /> <span>Position Mining</span>
                      </div>
                      <div className="flex items-center space-x-0.625">
                        <StyledIcon /> <span>EQU Burn Mechanism</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
              <div className="gap-x-5 md:gap-x-2 flex flex-row-reverse sm:flex-col sm:gap-x-0 sm:gap-y-2">
                <div className="space-y-0.75 text-xl underLg:text-base sm:flex sm:space-y-0 sm:space-x-1 w-1/3 sm:w-auto sm:space-y-0">
                  <StyleItemIcon className="flex items-center justify-center sm:mt-0.325">
                    <div className="inner" />
                  </StyleItemIcon>
                  <div className="space-y-0.75">
                    <Typography
                      color="primary"
                      variant="inherit"
                      className="font-medium"
                    >
                      12/2023
                    </Typography>
                    <div className="text-textPrimary90 space-y-0.75">
                      <div className="flex items-center space-x-0.625">
                        <StyledIcon /> <span>DAO Governance</span>
                      </div>
                      <div className="flex items-center space-x-0.625">
                        <StyledIcon /> <span>Pool2 Mining</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-0.75 text-xl underLg:text-base sm:flex sm:space-y-0 sm:space-x-1 w-1/3 sm:w-auto">
                  <div className="relative flex items-center sm:items-start">
                    {/*<StyledLiner className="absolute top-0.625 -left-3 sm:hidden" />*/}
                    <StyleItemIcon className="flex items-center justify-center sm:mt-0.325 relative">
                      <div className="inner" />
                    </StyleItemIcon>
                  </div>
                  <div className="space-y-0.75">
                    <Typography
                      color="primary"
                      variant="inherit"
                      className="font-medium"
                    >
                      01/2024
                    </Typography>
                    <div className="text-textPrimary90 space-y-0.75">
                      <div className="flex items-center space-x-0.625">
                        <StyledIcon className="primary" />{" "}
                        <span className="text-textPrimary90">Equation v2</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-0.75 text-xl underLg:text-base sm:flex sm:space-y-0 sm:space-x-1 w-1/3 sm:w-auto sm:space-y-0">
                  <div className="relative">
                    <StyleItemIcon className="flex items-center justify-center sm:mt-0.325">
                      <div className="inner" />
                    </StyleItemIcon>
                    <div className="h-[60px] relative left-[7px] sm:block hidden">
                      <Divider
                        sx={{
                          borderStyle: "dashed",
                          borderWidth: "1px",
                          width: 0,
                          borderColor: alpha(theme.palette.primary.main, 1),
                        }}
                        orientation="vertical"
                      />
                      <Box
                        sx={{
                          height: "40px",
                          width: "2px",
                          background: `linear-gradient(to bottom, rgba(0,0 ,0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 1) 200%)`,
                        }}
                        className="absolute bottom-0"
                      ></Box>
                    </div>
                    {/*<StyledLiner className="absolute top-[10px] left-[16px] sm:hidden" />*/}
                  </div>
                  <div className="space-y-0.75">
                    <Typography
                      color="primary"
                      variant="inherit"
                      className="font-medium"
                    >
                      05/2024
                    </Typography>
                    <div className="text-textPrimary90 space-y-0.75">
                      <div className="flex items-center space-x-0.625">
                        <StyledIcon className="primary" />{" "}
                        <span className="text-textPrimary90">Equation v3</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </Box>
  );
}

const StyledIcon = styled(Box)`
  width: 8px;
  height: 8px;
  display: inline-block;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.main};
  &.todo {
    background: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const StyleItemIcon = styled(Box)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.common.white};
  .inner {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.main};
  }
  &.todo {
    background: ${({ theme }) => theme.palette.text.secondary};
    .inner {
      background: ${({ theme }) => alpha(theme.palette.common.white, 0.7)};
    }
  }
  @media screen and (max-width: 767px) {
    width: 16px;
    height: 16px;
    .inner {
      width: 10px;
      height: 10px;
    }
  }
`;

const StyledLiner = styled("div")`
  background: ${({ theme }) => theme.palette.primary.main};
  height: 4px;
  width: 100%;
`;

const StyleRadius = styled(Box)`
  background: ${({ theme }) => theme.palette.background.default};
  width: 130px;
  height: 270px;
  overflow: hidden;
  right: 0;
  .inner {
    border-radius: 50%;
    height: 268.5px;
    width: 269px;
    margin-left: -139px;
    border: 5px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledLeftLiner = styled(Divider)`
  background: linear-gradient(
    -180deg,
    ${({ theme }) => alpha(theme.palette.primary.main, 0)} 0%,
    ${({ theme }) => alpha(theme.palette.primary.main, 0.5)} 10%,
    ${({ theme }) => alpha(theme.palette.primary.main, 1)} 100%
  );
  width: 2px;
  height: 596px;
  border-radius: 2px;
`;
