import { arbitrum, arbitrumGoerli } from "config/constants/connector/chains";

import { Env, Module } from "./constants";
import { USDT_CURRENCY } from "./constants/tokens";

type ModuleMap = Record<Module, string>;

const GRAPH_DEV: Record<number, ModuleMap> = {
  [arbitrumGoerli.id]: {
    [Module.Trade]:
      "https://graph-arbitrum-sepolia.equation.org/subgraphs/name/equation-dev",
    [Module.TradeV2]:
      "https://graph-arbitrum-sepolia.equation.org/subgraphs/name/equation-v2-dev",
    [Module.TradeV3]:
      "https://graph-arbitrum.equation.trade/subgraphs/name/equation-v3-arbitrum\n",
  },
  [arbitrum.id]: {
    [Module.Trade]:
      "https://graph-arbitrum.equation.trade/subgraphs/name/equation-arbitrum",
    [Module.TradeV2]:
      "https://graph-arbitrum-sepolia.equation.org/subgraphs/name/equation-v2-dev",
    [Module.TradeV3]:
      "https://graph-arbitrum.equation.trade/subgraphs/name/equation-v3-arbitrum\n",
  },
};

const GRAPH_PROD: Record<number, ModuleMap> = {
  [arbitrumGoerli.id]: {
    [Module.Trade]:
      "https://graph-arbitrum-sepolia.equation.org/subgraphs/name/equation-dev",
    [Module.TradeV2]:
      "https://graph-arbitrum-sepolia.equation.org/subgraphs/name/equation-v2-dev",
    [Module.TradeV3]:
      "https://graph-arbitrum.equation.trade/subgraphs/name/equation-v3-arbitrum",
  },
  [arbitrum.id]: {
    [Module.Trade]:
      "https://graph-arbitrum.equation.trade/subgraphs/name/equation-arbitrum",
    [Module.TradeV2]:
      "https://graph-arbitrum.equation.trade/subgraphs/name/equation-v2-arbitrum",
    [Module.TradeV3]:
      "https://graph-arbitrum.equation.trade/subgraphs/name/equation-v3-arbitrum",
  },
};

const GRAPH_HOST = {
  [Env.development]: GRAPH_DEV,
  [Env.production]: GRAPH_PROD,
};

export const GRAPH_API_URL: Record<number, ModuleMap> =
  GRAPH_HOST[process.env.REACT_APP_TAG as Env];

const BLOCK_GRAPH_DEV: Record<number, string> = {
  [arbitrumGoerli.id]:
    "https://api.studio.thegraph.com/query/45560/blocks-on-sepolia/v0.0.2",
  [arbitrum.id]:
    "https://graph-arbitrum.equation.trade/subgraphs/name/blocks-arbitrum",
};

const BLOCK_GRAPH_PROD: Record<number, string> = {
  [arbitrumGoerli.id]:
    "https://api.studio.thegraph.com/query/45560/blocks-on-sepolia/v0.0.2",
  [arbitrum.id]:
    "https://graph-arbitrum.equation.trade/subgraphs/name/blocks-arbitrum",
};

const BLOCK_GRAPH = {
  [Env.development]: BLOCK_GRAPH_DEV,
  [Env.production]: BLOCK_GRAPH_PROD,
};

export const BLOCK_GRAPH_URL: Record<number, string> =
  BLOCK_GRAPH[process.env.REACT_APP_TAG as Env];

const DEFAULT_POOL_DEV: Record<number, string> = {
  [arbitrumGoerli.id]: "0xeE2bfdD56Cb8388A717646af629a7Cf9174cfD1B",
  [arbitrum.id]: "0xE8489d514AEF77c5730dDE4eAC46b8F2d9fFD21C",
};

const DEFAULT_POOL_PROD: Record<number, string> = {
  [arbitrumGoerli.id]: "0xeE2bfdD56Cb8388A717646af629a7Cf9174cfD1B",
  [arbitrum.id]: "0xE8489d514AEF77c5730dDE4eAC46b8F2d9fFD21C",
};

const DEFAULT_POOL = {
  [Env.development]: DEFAULT_POOL_DEV,
  [Env.production]: DEFAULT_POOL_PROD,
};

const DEFAULT_POOL_DEV_V2: Record<number, string> = {
  [arbitrumGoerli.id]: "0xCf781B06d2C0C77Ec39C8723509De4884C90bD54",
  [arbitrum.id]: "0xCf781B06d2C0C77Ec39C8723509De4884C90bD54",
};

const DEFAULT_POOL_PROD_V2: Record<number, string> = {
  [arbitrumGoerli.id]: "0xDE43a589Bc5b6136C4B7Ec24311D61713Cd5dF37",
  [arbitrum.id]: "0xDE43a589Bc5b6136C4B7Ec24311D61713Cd5dF37",
};

const DEFAULT_POOL_V2 = {
  [Env.development]: DEFAULT_POOL_DEV_V2,
  [Env.production]: DEFAULT_POOL_PROD_V2,
};

export const DEFAULT_POOL_ADDRESS: Record<number, string> =
  DEFAULT_POOL[process.env.REACT_APP_TAG as Env];

export const DEFAULT_POOL_ADDRESS_V2: Record<number, string> =
  DEFAULT_POOL_V2[process.env.REACT_APP_TAG as Env];

const USDT_DEV: Record<number, string> = {
  [arbitrumGoerli.id]: USDT_CURRENCY[arbitrumGoerli.id].address,
  [arbitrum.id]: USDT_CURRENCY[arbitrum.id].address,
};

const USDT_PROD: Record<number, string> = {
  [arbitrumGoerli.id]: USDT_CURRENCY[arbitrumGoerli.id].address,
  [arbitrum.id]: USDT_CURRENCY[arbitrum.id].address,
};

const USDT = {
  [Env.development]: USDT_DEV,
  [Env.production]: USDT_PROD,
};

export const USDT_ADDRESS: Record<number, string> =
  USDT[process.env.REACT_APP_TAG as Env];

const START_BLOCK_NUMBER_DEV: Record<number, number> = {
  [arbitrumGoerli.id]: 3153655,
  [arbitrum.id]: 142245091,
};

const START_BLOCK_NUMBER_PROD: Record<number, number> = {
  [arbitrumGoerli.id]: 3153655,
  [arbitrum.id]: 142245091,
};

export const MARKETS_BLOCK_NUMBER_MAP = {
  [arbitrumGoerli.id]: 32783733,
  [arbitrum.id]: 42920611,
};

const START_BLOCK_NUMBERS = {
  [Env.development]: START_BLOCK_NUMBER_DEV,
  [Env.production]: START_BLOCK_NUMBER_PROD,
};

export const START_BLOCK_NUMBER: Record<number, number> =
  START_BLOCK_NUMBERS[process.env.REACT_APP_TAG as Env];

const UNI_GRAPH_ADDRESS_DEV: Record<number, string> = {
  [arbitrumGoerli.id]:
    "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one",
  [arbitrum.id]:
    "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one",
};

const UNI_GRAPH_ADDRESS_PROD: Record<number, string> = {
  [arbitrumGoerli.id]:
    "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one",
  [arbitrum.id]:
    "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one",
};

const UNI_GRAPH_ADDRESSES = {
  [Env.development]: UNI_GRAPH_ADDRESS_DEV,
  [Env.production]: UNI_GRAPH_ADDRESS_PROD,
};

export const UNI_GRAPH_ADDRESS: Record<number, string> =
  UNI_GRAPH_ADDRESSES[process.env.REACT_APP_TAG as Env];

export const EVENT_SITE = "https://event.equation.trade";
export const TRADE_SITE = "https://equation.trade";
