import { useAppSelector } from "../state/hooks";
import { globalBaseState } from "../state/global/slice";
import { useApolloClients } from "../context/ApolloClientsContext";
import { useCurrentChain } from "../hooks/useCurrentChain";
import { useAllTokens } from "../hooks/useAllTokens";
import { useEffect, useState } from "react";
import { useMarketsQuery } from "./__generated_tradeV3__/types-and-hooks";
import { MARKETS_BLOCK_NUMBER_MAP } from "../config/env";
import { useMultiTokensV2 } from "../hooks/useRequest";
import { isEmpty } from "lodash";
import { isLessThan, minus, multipliedBy } from "../utils";

function useV3MarketGraph() {
  const { blockHeightBefore24h } = useAppSelector(globalBaseState);
  const { clientTradeV3 } = useApolloClients();
  const { currentChainId } = useCurrentChain();
  const { appTimer } = useAppSelector(globalBaseState);
  const allTokens = useAllTokens();
  const [markets, setMarkets] = useState([]);

  const startBlockNumber = 210148165;

  const { data, refetch } = useMarketsQuery({
    variables: {
      block24h: Number(
        isLessThan(blockHeightBefore24h, startBlockNumber)
          ? startBlockNumber
          : blockHeightBefore24h
      ),
    },
    client: clientTradeV3,
  });

  useEffect(() => {
    refetch();
  }, [appTimer]);

  useEffect(() => {
    if (isEmpty(data)) {
      return;
    }
    const { markets = [], past = [] } = data;

    const refactorMarkets = markets.map((item) => {
      const before24HData = past.find((pool) => pool.id === item.id);
      return {
        ...item,
        volume24H: minus(item?.volumeUSD, before24HData?.volumeUSD || 0),
      };
    });
    setMarkets(refactorMarkets);
  }, [data]);

  return { data: markets };
}

export default useV3MarketGraph;
