import React, { useEffect, useState } from "react";
import Logo from "../../components/logo";
import cn from "classnames";
import Navs from "./navs";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Box, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { StyledButton } from "../../components/styled";
// import { useAccount } from "wagmi";
// import { useCheckLogin, useCurrentChain } from "hooks/useCurrentChain";
// import ConnectWalletButton from "components/Wallet/ConnectWalletButton";
// import SwitchNetworkButton from "components/Wallet/SwitchNetworkButton";
// import { useLocation } from "react-router-dom";
// import Account from "./Account";
// import ErrorOutline from "@mui/icons-material/ErrorOutline";

const StyleDrawer = styled(Drawer)<any>(({ theme, match }) => ({
  width: "100%",
  ".MuiDrawer-paper": {
    borderRadius: 0,
    width: !match ? "375px" : "100%",
    background: "#141519",
    padding: `${theme.spacing(2)} 22px`,
    overflow: "hidden",
  },
}));

const NavsContainer = styled("div")(({ theme }) => ({
  height: 60,
  "@media screen and (max-width: 768px)": {
    padding: `0 16px`,
  },
}));

const Header = (props: any) => {
  // let location = useLocation();
  // let path = location.pathname.replace("/", "");
  // const { isConnected } = useAccount();
  // const { isSupportedChain } = useCurrentChain();
  const [showModal, setShowModal] = useState(false);
  const matchSmall = useMediaQuery("(max-width: 768px)");

  const matchQuery = useMediaQuery("(min-width: 1000px)");
  const matchSmallNav = useMediaQuery("(max-width: 1000px)");
  // const theme = useTheme();

  useEffect(() => {
    if (!matchQuery) {
      return;
    }
    setShowModal(false);
  }, [matchQuery]);

  return (
    <div className="relative h-[80px] flex justify-center items-center z-50 w-full sm:h-[56px]">
      <NavsContainer
        className={cn(
          "flex justify-between items-center content pt-1.5 sm:pt-0.75"
        )}
      >
        <div className="flex items-center">
          <Logo size={matchSmall ? 120 : 153} height={matchSmall ? 24 : 40} />
        </div>
        <div className="flex items-center justify-end">
          <Box className={cn(matchSmallNav ? "hidden" : "block")}>
            <Navs />
          </Box>
          <Box
            className={cn(
              "cursor-pointer ml-1.25 flex items-center space-x-1.5",
              matchSmallNav ? "block space-x-0.5" : "hidden"
            )}
          >
            <MenuIcon
              onClick={() => setShowModal(true)}
              sx={{
                fontSize: 26,
              }}
            />
          </Box>
        </div>
        <StyleDrawer
          open={showModal}
          anchor="right"
          match={matchSmall}
          sx={{
            ".MuiPaper-root": {
              height: matchSmall ? window.innerHeight : "100vh",
            },
          }}
        >
          <div className="bg"></div>
          <div className="bg2"></div>
          <div className="relative z-9999 flex flex-col gap-y-2">
            <div
              className={classNames("flex items-center", {
                "justify-between": matchSmall,
                "justify-end": !matchSmall,
              })}
            >
              <Logo
                className={classNames({
                  hidden: !matchSmall,
                })}
                size={matchSmall ? 100 : 145}
                onClick={() => setShowModal(false)}
              />
              <CloseIcon
                className="cursor-pointer"
                onClick={() => setShowModal(false)}
                sx={{ fontSize: 24 }}
              />
            </div>
            <div
              className={classNames("flex-grow flex flex-col sm:mt-1")}
              style={{ height: window.innerHeight - 85 }}
            >
              <Navs onClick={() => setShowModal(false)} drawer={true} />
            </div>
          </div>
        </StyleDrawer>
      </NavsContainer>
    </div>
  );
};

export default Header;
