import { getServerUrl, BACKEND_HOST_URL } from "config/backend";
import { useCurrentChain } from "hooks/useCurrentChain";
import useSWR from "swr";
import qs from "qs";
import { useMemo } from "react";
import { isEmpty } from "lodash";
import { PRICES_INTERVAL_TIMEOUT } from "config/constants";

const fetcher = (url: string, options: any) =>
  fetch(url, {
    ...options,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((r) => r.json());

export const useFaucetAccountStatus = (address: string) => {
  const { currentChainId } = useCurrentChain();
  const url = getServerUrl("V1", currentChainId, "/v1/faucet/account_status");
  const { data, mutate, ...restProps } = useSWR(
    address ? `${url}?${qs.stringify({ address })}` : null,
    fetcher,
    { refreshInterval: 0 }
  );

  const paramsData = useMemo(() => {
    if (isEmpty(data)) {
      return {};
    }
    return {
      path: (data?.data?.redirect_url as string)?.split("?")[0],
      params: {
        ...qs.parse((data?.data?.redirect_url as string)?.split("?")[1]),
        redirect_uri: "http://localhost:3001/faucet",
      },
    };
  }, [data]);
  return {
    ...data?.data,
    redirectParams: paramsData,
    fetch: mutate,
    ...restProps,
  };
};
export const useFaucetCallback = (params: any) => {
  const { currentChainId } = useCurrentChain();
  const url = getServerUrl("V1", currentChainId, "/v1/faucet/callback");
  const { data, mutate, ...restProps } = useSWR(
    isEmpty(params) ? null : `${url}?${qs.stringify({ ...params })}`,
    (url) => fetcher(url, {})
  );

  return {
    data: data?.data,
    fetch: mutate,
    ...restProps,
  };
};

export const useFaucetClaim = (params: any) => {
  const { currentChainId } = useCurrentChain();
  const url = getServerUrl("V1", currentChainId, "/v1/faucet/claim");
  const { data, mutate, ...restProps } = useSWR(
    isEmpty(params) ? null : url,
    (url) =>
      fetcher(url, {
        method: "POST",
        body: JSON.stringify(params),
      })
  );

  return {
    data: data,
    mutate,
    ...restProps,
  };
};

export const useTokensPrice = (addresses: Array<string> | null) => {
  const { currentChainId } = useCurrentChain();
  const tokensPriceUrl = getServerUrl("V1", currentChainId, "/tokens/24hr");
  const { data, mutate } = useSWR(
    !isEmpty(addresses) ? tokensPriceUrl : null,
    (url) =>
      fetcher(url, {
        method: "POST",
        body: JSON.stringify({ addresses }),
      }),
    { refreshInterval: PRICES_INTERVAL_TIMEOUT }
  );
  return {
    tokensPrice: data?.data,
    fetchTokensPrice: mutate,
  };
};

export const useMultiTokens = (addresses: Array<string> | null) => {
  const { currentChainId } = useCurrentChain();
  const multiTokensUrl = getServerUrl(
    "V1",
    currentChainId,
    "/tokens/by-addresses"
  );
  const { data, mutate } = useSWR(
    !isEmpty(addresses) ? multiTokensUrl : null,
    (url) =>
      fetcher(url, {
        method: "POST",
        body: JSON.stringify({ addresses }),
      }),
    { refreshInterval: PRICES_INTERVAL_TIMEOUT }
  );
  return {
    tokensMultiPrice: data?.data,
    fetchPoolsMultiPrice: mutate,
  };
};

export const useTokensPriceV2 = (addresses: Array<string> | null) => {
  const { currentChainId } = useCurrentChain();
  const tokensPriceUrl = getServerUrl(
    "V2",
    currentChainId,
    "/tokens/by-addresses"
  );
  const fetchedAddress = useMemo(() => {
    if (isEmpty(addresses)) {
      return [];
    }
    return addresses.filter((item) => item);
  }, [addresses]);
  const { data, mutate } = useSWR(
    !isEmpty(fetchedAddress) ? tokensPriceUrl : null,
    (url) =>
      fetcher(url, {
        method: "POST",
        body: JSON.stringify({ addresses: fetchedAddress }),
      }),
    { refreshInterval: PRICES_INTERVAL_TIMEOUT }
  );
  return {
    tokensPrice: data?.data,
    fetchTokensPrice: mutate,
  };
};

export const useMultiTokensV2 = (addresses: Array<string> | null) => {
  const { currentChainId } = useCurrentChain();
  const multiTokensUrl = getServerUrl(
    "V2",
    currentChainId,
    "/prices/by-addresses"
  );
  const fetchedAddress = useMemo(() => {
    if (isEmpty(addresses)) {
      return [];
    }
    return addresses.filter((item) => item);
  }, [addresses]);
  const { data, mutate } = useSWR(
    !isEmpty(fetchedAddress) ? multiTokensUrl : null,
    (url) =>
      fetcher(url, {
        method: "POST",
        body: JSON.stringify({ addresses: fetchedAddress }),
      }),
    { refreshInterval: PRICES_INTERVAL_TIMEOUT }
  );
  return {
    tokensMultiPrice: data?.data,
    fetchPoolsMultiPrice: mutate,
  };
};
