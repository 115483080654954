import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigDecimal: { input: any; output: any };
  BigInt: { input: any; output: any };
  Bytes: { input: any; output: any };
  /** 8 bytes signed integer */
  Int8: { input: any; output: any };
};

/** entity that represents LPs' request to adjust liquidity position */
export type AdjustLiquidityPositionMarginRequest = {
  __typename?: "AdjustLiquidityPositionMarginRequest";
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** margin delta of the adjustment, negative for decreasing */
  marginDelta: Scalars["BigDecimal"]["output"];
  /** in which pool to adjust liquidity position margin */
  pool: Scalars["Bytes"]["output"];
  /** liquidity position id to adjust liquidity */
  positionID: Scalars["BigInt"]["output"];
  /** margin receiver, valid if decrease margin */
  receiver: Scalars["Bytes"]["output"];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Scalars["String"]["output"];
};

export type AdjustLiquidityPositionMarginRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<
    Array<InputMaybe<AdjustLiquidityPositionMarginRequest_Filter>>
  >;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  marginDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<
    Array<InputMaybe<AdjustLiquidityPositionMarginRequest_Filter>>
  >;
  pool?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  positionID?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  positionID_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  receiver?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  receiver_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_gt?: InputMaybe<Scalars["String"]["input"]>;
  status_gte?: InputMaybe<Scalars["String"]["input"]>;
  status_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_lt?: InputMaybe<Scalars["String"]["input"]>;
  status_lte?: InputMaybe<Scalars["String"]["input"]>;
  status_not?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum AdjustLiquidityPositionMarginRequest_OrderBy {
  Account = "account",
  CancelledHash = "cancelledHash",
  CreatedHash = "createdHash",
  ExecutedHash = "executedHash",
  ExecutionFee = "executionFee",
  Id = "id",
  Index = "index",
  MarginDelta = "marginDelta",
  Pool = "pool",
  PositionId = "positionID",
  Receiver = "receiver",
  Status = "status",
}

export type BlockChangedFilter = {
  number_gte: Scalars["Int"]["input"];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars["Bytes"]["input"]>;
  number?: InputMaybe<Scalars["Int"]["input"]>;
  number_gte?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Candle1D = {
  __typename?: "Candle1D";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the token that contains this candle */
  token: Token;
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle1D_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle1D_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle1D_Filter>>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_gt?: InputMaybe<Scalars["String"]["input"]>;
  token_gte?: InputMaybe<Scalars["String"]["input"]>;
  token_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_lt?: InputMaybe<Scalars["String"]["input"]>;
  token_lte?: InputMaybe<Scalars["String"]["input"]>;
  token_not?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle1D_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Open = "open",
  Token = "token",
  TokenDecimals = "token__decimals",
  TokenId = "token__id",
  TokenInterestRate = "token__interestRate",
  TokenLiquidationExecutionFee = "token__liquidationExecutionFee",
  TokenLiquidationFeeRatePerPosition = "token__liquidationFeeRatePerPosition",
  TokenLiquidationVertexIndex = "token__liquidationVertexIndex",
  TokenLiquidityFeeRate = "token__liquidityFeeRate",
  TokenMaxFundingRate = "token__maxFundingRate",
  TokenMaxLeveragePerLiquidityPosition = "token__maxLeveragePerLiquidityPosition",
  TokenMaxLeveragePerPosition = "token__maxLeveragePerPosition",
  TokenMaxPrice = "token__maxPrice",
  TokenMaxPriceImpactLiquidity = "token__maxPriceImpactLiquidity",
  TokenMaxPriceX96 = "token__maxPriceX96",
  TokenMaxRiskRatePerLiquidityPosition = "token__maxRiskRatePerLiquidityPosition",
  TokenMinMarginPerLiquidityPosition = "token__minMarginPerLiquidityPosition",
  TokenMinMarginPerPosition = "token__minMarginPerPosition",
  TokenMinPrice = "token__minPrice",
  TokenMinPriceX96 = "token__minPriceX96",
  TokenName = "token__name",
  TokenPool = "token__pool",
  TokenPrice = "token__price",
  TokenPriceX96 = "token__priceX96",
  TokenProtocolFeeRate = "token__protocolFeeRate",
  TokenReferralDiscountRate = "token__referralDiscountRate",
  TokenReferralParentReturnFeeRate = "token__referralParentReturnFeeRate",
  TokenReferralReturnFeeRate = "token__referralReturnFeeRate",
  TokenSymbol = "token__symbol",
  TokenTradingFeeRate = "token__tradingFeeRate",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle1H = {
  __typename?: "Candle1H";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the token that contains this candle */
  token: Token;
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle1H_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle1H_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle1H_Filter>>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_gt?: InputMaybe<Scalars["String"]["input"]>;
  token_gte?: InputMaybe<Scalars["String"]["input"]>;
  token_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_lt?: InputMaybe<Scalars["String"]["input"]>;
  token_lte?: InputMaybe<Scalars["String"]["input"]>;
  token_not?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle1H_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Open = "open",
  Token = "token",
  TokenDecimals = "token__decimals",
  TokenId = "token__id",
  TokenInterestRate = "token__interestRate",
  TokenLiquidationExecutionFee = "token__liquidationExecutionFee",
  TokenLiquidationFeeRatePerPosition = "token__liquidationFeeRatePerPosition",
  TokenLiquidationVertexIndex = "token__liquidationVertexIndex",
  TokenLiquidityFeeRate = "token__liquidityFeeRate",
  TokenMaxFundingRate = "token__maxFundingRate",
  TokenMaxLeveragePerLiquidityPosition = "token__maxLeveragePerLiquidityPosition",
  TokenMaxLeveragePerPosition = "token__maxLeveragePerPosition",
  TokenMaxPrice = "token__maxPrice",
  TokenMaxPriceImpactLiquidity = "token__maxPriceImpactLiquidity",
  TokenMaxPriceX96 = "token__maxPriceX96",
  TokenMaxRiskRatePerLiquidityPosition = "token__maxRiskRatePerLiquidityPosition",
  TokenMinMarginPerLiquidityPosition = "token__minMarginPerLiquidityPosition",
  TokenMinMarginPerPosition = "token__minMarginPerPosition",
  TokenMinPrice = "token__minPrice",
  TokenMinPriceX96 = "token__minPriceX96",
  TokenName = "token__name",
  TokenPool = "token__pool",
  TokenPrice = "token__price",
  TokenPriceX96 = "token__priceX96",
  TokenProtocolFeeRate = "token__protocolFeeRate",
  TokenReferralDiscountRate = "token__referralDiscountRate",
  TokenReferralParentReturnFeeRate = "token__referralParentReturnFeeRate",
  TokenReferralReturnFeeRate = "token__referralReturnFeeRate",
  TokenSymbol = "token__symbol",
  TokenTradingFeeRate = "token__tradingFeeRate",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle1M = {
  __typename?: "Candle1M";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the token that contains this candle */
  token: Token;
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle1M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle1M_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle1M_Filter>>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_gt?: InputMaybe<Scalars["String"]["input"]>;
  token_gte?: InputMaybe<Scalars["String"]["input"]>;
  token_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_lt?: InputMaybe<Scalars["String"]["input"]>;
  token_lte?: InputMaybe<Scalars["String"]["input"]>;
  token_not?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle1M_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Open = "open",
  Token = "token",
  TokenDecimals = "token__decimals",
  TokenId = "token__id",
  TokenInterestRate = "token__interestRate",
  TokenLiquidationExecutionFee = "token__liquidationExecutionFee",
  TokenLiquidationFeeRatePerPosition = "token__liquidationFeeRatePerPosition",
  TokenLiquidationVertexIndex = "token__liquidationVertexIndex",
  TokenLiquidityFeeRate = "token__liquidityFeeRate",
  TokenMaxFundingRate = "token__maxFundingRate",
  TokenMaxLeveragePerLiquidityPosition = "token__maxLeveragePerLiquidityPosition",
  TokenMaxLeveragePerPosition = "token__maxLeveragePerPosition",
  TokenMaxPrice = "token__maxPrice",
  TokenMaxPriceImpactLiquidity = "token__maxPriceImpactLiquidity",
  TokenMaxPriceX96 = "token__maxPriceX96",
  TokenMaxRiskRatePerLiquidityPosition = "token__maxRiskRatePerLiquidityPosition",
  TokenMinMarginPerLiquidityPosition = "token__minMarginPerLiquidityPosition",
  TokenMinMarginPerPosition = "token__minMarginPerPosition",
  TokenMinPrice = "token__minPrice",
  TokenMinPriceX96 = "token__minPriceX96",
  TokenName = "token__name",
  TokenPool = "token__pool",
  TokenPrice = "token__price",
  TokenPriceX96 = "token__priceX96",
  TokenProtocolFeeRate = "token__protocolFeeRate",
  TokenReferralDiscountRate = "token__referralDiscountRate",
  TokenReferralParentReturnFeeRate = "token__referralParentReturnFeeRate",
  TokenReferralReturnFeeRate = "token__referralReturnFeeRate",
  TokenSymbol = "token__symbol",
  TokenTradingFeeRate = "token__tradingFeeRate",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle4H = {
  __typename?: "Candle4H";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the token that contains this candle */
  token: Token;
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle4H_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle4H_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle4H_Filter>>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_gt?: InputMaybe<Scalars["String"]["input"]>;
  token_gte?: InputMaybe<Scalars["String"]["input"]>;
  token_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_lt?: InputMaybe<Scalars["String"]["input"]>;
  token_lte?: InputMaybe<Scalars["String"]["input"]>;
  token_not?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle4H_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Open = "open",
  Token = "token",
  TokenDecimals = "token__decimals",
  TokenId = "token__id",
  TokenInterestRate = "token__interestRate",
  TokenLiquidationExecutionFee = "token__liquidationExecutionFee",
  TokenLiquidationFeeRatePerPosition = "token__liquidationFeeRatePerPosition",
  TokenLiquidationVertexIndex = "token__liquidationVertexIndex",
  TokenLiquidityFeeRate = "token__liquidityFeeRate",
  TokenMaxFundingRate = "token__maxFundingRate",
  TokenMaxLeveragePerLiquidityPosition = "token__maxLeveragePerLiquidityPosition",
  TokenMaxLeveragePerPosition = "token__maxLeveragePerPosition",
  TokenMaxPrice = "token__maxPrice",
  TokenMaxPriceImpactLiquidity = "token__maxPriceImpactLiquidity",
  TokenMaxPriceX96 = "token__maxPriceX96",
  TokenMaxRiskRatePerLiquidityPosition = "token__maxRiskRatePerLiquidityPosition",
  TokenMinMarginPerLiquidityPosition = "token__minMarginPerLiquidityPosition",
  TokenMinMarginPerPosition = "token__minMarginPerPosition",
  TokenMinPrice = "token__minPrice",
  TokenMinPriceX96 = "token__minPriceX96",
  TokenName = "token__name",
  TokenPool = "token__pool",
  TokenPrice = "token__price",
  TokenPriceX96 = "token__priceX96",
  TokenProtocolFeeRate = "token__protocolFeeRate",
  TokenReferralDiscountRate = "token__referralDiscountRate",
  TokenReferralParentReturnFeeRate = "token__referralParentReturnFeeRate",
  TokenReferralReturnFeeRate = "token__referralReturnFeeRate",
  TokenSymbol = "token__symbol",
  TokenTradingFeeRate = "token__tradingFeeRate",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle5M = {
  __typename?: "Candle5M";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the token that contains this candle */
  token: Token;
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle5M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle5M_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle5M_Filter>>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_gt?: InputMaybe<Scalars["String"]["input"]>;
  token_gte?: InputMaybe<Scalars["String"]["input"]>;
  token_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_lt?: InputMaybe<Scalars["String"]["input"]>;
  token_lte?: InputMaybe<Scalars["String"]["input"]>;
  token_not?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle5M_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Open = "open",
  Token = "token",
  TokenDecimals = "token__decimals",
  TokenId = "token__id",
  TokenInterestRate = "token__interestRate",
  TokenLiquidationExecutionFee = "token__liquidationExecutionFee",
  TokenLiquidationFeeRatePerPosition = "token__liquidationFeeRatePerPosition",
  TokenLiquidationVertexIndex = "token__liquidationVertexIndex",
  TokenLiquidityFeeRate = "token__liquidityFeeRate",
  TokenMaxFundingRate = "token__maxFundingRate",
  TokenMaxLeveragePerLiquidityPosition = "token__maxLeveragePerLiquidityPosition",
  TokenMaxLeveragePerPosition = "token__maxLeveragePerPosition",
  TokenMaxPrice = "token__maxPrice",
  TokenMaxPriceImpactLiquidity = "token__maxPriceImpactLiquidity",
  TokenMaxPriceX96 = "token__maxPriceX96",
  TokenMaxRiskRatePerLiquidityPosition = "token__maxRiskRatePerLiquidityPosition",
  TokenMinMarginPerLiquidityPosition = "token__minMarginPerLiquidityPosition",
  TokenMinMarginPerPosition = "token__minMarginPerPosition",
  TokenMinPrice = "token__minPrice",
  TokenMinPriceX96 = "token__minPriceX96",
  TokenName = "token__name",
  TokenPool = "token__pool",
  TokenPrice = "token__price",
  TokenPriceX96 = "token__priceX96",
  TokenProtocolFeeRate = "token__protocolFeeRate",
  TokenReferralDiscountRate = "token__referralDiscountRate",
  TokenReferralParentReturnFeeRate = "token__referralParentReturnFeeRate",
  TokenReferralReturnFeeRate = "token__referralReturnFeeRate",
  TokenSymbol = "token__symbol",
  TokenTradingFeeRate = "token__tradingFeeRate",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle15M = {
  __typename?: "Candle15M";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the token that contains this candle */
  token: Token;
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle15M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle15M_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle15M_Filter>>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_gt?: InputMaybe<Scalars["String"]["input"]>;
  token_gte?: InputMaybe<Scalars["String"]["input"]>;
  token_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_lt?: InputMaybe<Scalars["String"]["input"]>;
  token_lte?: InputMaybe<Scalars["String"]["input"]>;
  token_not?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle15M_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Open = "open",
  Token = "token",
  TokenDecimals = "token__decimals",
  TokenId = "token__id",
  TokenInterestRate = "token__interestRate",
  TokenLiquidationExecutionFee = "token__liquidationExecutionFee",
  TokenLiquidationFeeRatePerPosition = "token__liquidationFeeRatePerPosition",
  TokenLiquidationVertexIndex = "token__liquidationVertexIndex",
  TokenLiquidityFeeRate = "token__liquidityFeeRate",
  TokenMaxFundingRate = "token__maxFundingRate",
  TokenMaxLeveragePerLiquidityPosition = "token__maxLeveragePerLiquidityPosition",
  TokenMaxLeveragePerPosition = "token__maxLeveragePerPosition",
  TokenMaxPrice = "token__maxPrice",
  TokenMaxPriceImpactLiquidity = "token__maxPriceImpactLiquidity",
  TokenMaxPriceX96 = "token__maxPriceX96",
  TokenMaxRiskRatePerLiquidityPosition = "token__maxRiskRatePerLiquidityPosition",
  TokenMinMarginPerLiquidityPosition = "token__minMarginPerLiquidityPosition",
  TokenMinMarginPerPosition = "token__minMarginPerPosition",
  TokenMinPrice = "token__minPrice",
  TokenMinPriceX96 = "token__minPriceX96",
  TokenName = "token__name",
  TokenPool = "token__pool",
  TokenPrice = "token__price",
  TokenPriceX96 = "token__priceX96",
  TokenProtocolFeeRate = "token__protocolFeeRate",
  TokenReferralDiscountRate = "token__referralDiscountRate",
  TokenReferralParentReturnFeeRate = "token__referralParentReturnFeeRate",
  TokenReferralReturnFeeRate = "token__referralReturnFeeRate",
  TokenSymbol = "token__symbol",
  TokenTradingFeeRate = "token__tradingFeeRate",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

export type Candle30M = {
  __typename?: "Candle30M";
  /** the latest price of the candle */
  close: Scalars["BigDecimal"]["output"];
  /** the highest price of the candle */
  high: Scalars["BigDecimal"]["output"];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the lowest price of the candle */
  low: Scalars["BigDecimal"]["output"];
  /** the first price of the candle */
  open: Scalars["BigDecimal"]["output"];
  /** the token that contains this candle */
  token: Token;
  /** the start time of the candle */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
  /** the trading volume of token */
  volume: Scalars["BigDecimal"]["output"];
  /** usd amount of the volume */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

export type Candle30M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle30M_Filter>>>;
  close?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  close_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  close_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  high_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  high_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  low?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  low_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  low_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  open_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  open_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Candle30M_Filter>>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_gt?: InputMaybe<Scalars["String"]["input"]>;
  token_gte?: InputMaybe<Scalars["String"]["input"]>;
  token_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_lt?: InputMaybe<Scalars["String"]["input"]>;
  token_lte?: InputMaybe<Scalars["String"]["input"]>;
  token_not?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Candle30M_OrderBy {
  Close = "close",
  High = "high",
  Id = "id",
  Low = "low",
  Open = "open",
  Token = "token",
  TokenDecimals = "token__decimals",
  TokenId = "token__id",
  TokenInterestRate = "token__interestRate",
  TokenLiquidationExecutionFee = "token__liquidationExecutionFee",
  TokenLiquidationFeeRatePerPosition = "token__liquidationFeeRatePerPosition",
  TokenLiquidationVertexIndex = "token__liquidationVertexIndex",
  TokenLiquidityFeeRate = "token__liquidityFeeRate",
  TokenMaxFundingRate = "token__maxFundingRate",
  TokenMaxLeveragePerLiquidityPosition = "token__maxLeveragePerLiquidityPosition",
  TokenMaxLeveragePerPosition = "token__maxLeveragePerPosition",
  TokenMaxPrice = "token__maxPrice",
  TokenMaxPriceImpactLiquidity = "token__maxPriceImpactLiquidity",
  TokenMaxPriceX96 = "token__maxPriceX96",
  TokenMaxRiskRatePerLiquidityPosition = "token__maxRiskRatePerLiquidityPosition",
  TokenMinMarginPerLiquidityPosition = "token__minMarginPerLiquidityPosition",
  TokenMinMarginPerPosition = "token__minMarginPerPosition",
  TokenMinPrice = "token__minPrice",
  TokenMinPriceX96 = "token__minPriceX96",
  TokenName = "token__name",
  TokenPool = "token__pool",
  TokenPrice = "token__price",
  TokenPriceX96 = "token__priceX96",
  TokenProtocolFeeRate = "token__protocolFeeRate",
  TokenReferralDiscountRate = "token__referralDiscountRate",
  TokenReferralParentReturnFeeRate = "token__referralParentReturnFeeRate",
  TokenReferralReturnFeeRate = "token__referralReturnFeeRate",
  TokenSymbol = "token__symbol",
  TokenTradingFeeRate = "token__tradingFeeRate",
  Ts = "ts",
  TxCount = "txCount",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

/** entity that represents LPs' request to close liquidity position */
export type CloseLiquidityPositionRequest = {
  __typename?: "CloseLiquidityPositionRequest";
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** in which pool to close liquidity position */
  pool: Scalars["Bytes"]["output"];
  /** liquidity position id to close */
  positionID: Scalars["BigInt"]["output"];
  /** margin receiver */
  receiver: Scalars["Bytes"]["output"];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Scalars["String"]["output"];
};

export type CloseLiquidityPositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<CloseLiquidityPositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<CloseLiquidityPositionRequest_Filter>>>;
  pool?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  positionID?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  positionID_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  receiver?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  receiver_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_gt?: InputMaybe<Scalars["String"]["input"]>;
  status_gte?: InputMaybe<Scalars["String"]["input"]>;
  status_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_lt?: InputMaybe<Scalars["String"]["input"]>;
  status_lte?: InputMaybe<Scalars["String"]["input"]>;
  status_not?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum CloseLiquidityPositionRequest_OrderBy {
  Account = "account",
  CancelledHash = "cancelledHash",
  CreatedHash = "createdHash",
  ExecutedHash = "executedHash",
  ExecutionFee = "executionFee",
  Id = "id",
  Index = "index",
  Pool = "pool",
  PositionId = "positionID",
  Receiver = "receiver",
  Status = "status",
}

/** entity that represents Traders' request to close/decrease a position */
export type DecreasePositionRequest = {
  __typename?: "DecreasePositionRequest";
  /** worst trade price trader can accept */
  acceptableTradePrice: Scalars["BigDecimal"]["output"];
  /** worst trade price trader can accept, as a Q64.96 */
  acceptableTradePriceX96: Scalars["BigInt"]["output"];
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** delta of the margin to decrease */
  marginDelta: Scalars["BigDecimal"]["output"];
  /** in which pool to decrease position */
  pool: Scalars["Bytes"]["output"];
  /** margin receiver */
  receiver: Scalars["Bytes"]["output"];
  /** side of the request, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** size of the base token to decrease */
  sizeDelta: Scalars["BigDecimal"]["output"];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Scalars["String"]["output"];
};

export type DecreasePositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableTradePrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  acceptableTradePriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  acceptableTradePrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  acceptableTradePrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<DecreasePositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  marginDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<DecreasePositionRequest_Filter>>>;
  pool?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  receiver?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  receiver_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  sizeDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  sizeDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_gt?: InputMaybe<Scalars["String"]["input"]>;
  status_gte?: InputMaybe<Scalars["String"]["input"]>;
  status_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_lt?: InputMaybe<Scalars["String"]["input"]>;
  status_lte?: InputMaybe<Scalars["String"]["input"]>;
  status_not?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum DecreasePositionRequest_OrderBy {
  AcceptableTradePrice = "acceptableTradePrice",
  AcceptableTradePriceX96 = "acceptableTradePriceX96",
  Account = "account",
  CancelledHash = "cancelledHash",
  CreatedHash = "createdHash",
  ExecutedHash = "executedHash",
  ExecutionFee = "executionFee",
  Id = "id",
  Index = "index",
  MarginDelta = "marginDelta",
  Pool = "pool",
  Receiver = "receiver",
  Side = "side",
  SizeDelta = "sizeDelta",
  Status = "status",
}

/** entity that represents users' request to decrease risk buffer fund position */
export type DecreaseRiskBufferFundPositionRequest = {
  __typename?: "DecreaseRiskBufferFundPositionRequest";
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** liquidity to decrease */
  liquidity: Scalars["BigDecimal"]["output"];
  /** in which pool to decrease risk buffer fund position */
  pool: Scalars["Bytes"]["output"];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Scalars["String"]["output"];
};

export type DecreaseRiskBufferFundPositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<
    Array<InputMaybe<DecreaseRiskBufferFundPositionRequest_Filter>>
  >;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<
    Array<InputMaybe<DecreaseRiskBufferFundPositionRequest_Filter>>
  >;
  pool?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_gt?: InputMaybe<Scalars["String"]["input"]>;
  status_gte?: InputMaybe<Scalars["String"]["input"]>;
  status_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_lt?: InputMaybe<Scalars["String"]["input"]>;
  status_lte?: InputMaybe<Scalars["String"]["input"]>;
  status_not?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum DecreaseRiskBufferFundPositionRequest_OrderBy {
  Account = "account",
  CancelledHash = "cancelledHash",
  CreatedHash = "createdHash",
  ExecutedHash = "executedHash",
  ExecutionFee = "executionFee",
  Id = "id",
  Index = "index",
  Liquidity = "liquidity",
  Pool = "pool",
  Status = "status",
}

/** entity that represents the funding rate history */
export type FundingRateHistory = {
  __typename?: "FundingRateHistory";
  /** block which transaction was included in */
  blockNumber: Scalars["BigInt"]["output"];
  /** timestamp of the confirmed transaction */
  blockTimestamp: Scalars["BigInt"]["output"];
  /**
   * The change in funding rate, a positive value means longs pay shorts,
   * when a negative value means shorts pay longs
   */
  fundingRate: Scalars["BigDecimal"]["output"];
  /** format: <pool address>:<last adjust time> */
  id: Scalars["ID"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** the timestamp of the last funding rate adjustment */
  ts: Scalars["BigInt"]["output"];
  /** tx hash of the confirmed transaction */
  txHash: Scalars["Bytes"]["output"];
};

export type FundingRateHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FundingRateHistory_Filter>>>;
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingRate?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingRate_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<FundingRateHistory_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  txHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
};

export enum FundingRateHistory_OrderBy {
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  FundingRate = "fundingRate",
  Id = "id",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  Ts = "ts",
  TxHash = "txHash",
}

/** entity that represents a global funding rate sample */
export type GlobalFundingRateSample = {
  __typename?: "GlobalFundingRateSample";
  /** the cumulative premium rate of the samples taken since the last funding rate adjustment */
  cumulativePremiumRate: Scalars["BigDecimal"]["output"];
  /** the cumulative premium rate of the samples taken since the last funding rate adjustment, as a Q80.96 */
  cumulativePremiumRateX96: Scalars["BigInt"]["output"];
  /** pool address */
  id: Scalars["Bytes"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** the number of samples taken since the last funding rate adjustment */
  sampleCount: Scalars["Int"]["output"];
};

export type GlobalFundingRateSample_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalFundingRateSample_Filter>>>;
  cumulativePremiumRate?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  cumulativePremiumRateX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  cumulativePremiumRateX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  cumulativePremiumRateX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  cumulativePremiumRateX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  cumulativePremiumRateX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  cumulativePremiumRateX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  cumulativePremiumRateX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  cumulativePremiumRateX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  cumulativePremiumRate_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  cumulativePremiumRate_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  cumulativePremiumRate_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  cumulativePremiumRate_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  cumulativePremiumRate_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  cumulativePremiumRate_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  cumulativePremiumRate_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<GlobalFundingRateSample_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  sampleCount?: InputMaybe<Scalars["Int"]["input"]>;
  sampleCount_gt?: InputMaybe<Scalars["Int"]["input"]>;
  sampleCount_gte?: InputMaybe<Scalars["Int"]["input"]>;
  sampleCount_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  sampleCount_lt?: InputMaybe<Scalars["Int"]["input"]>;
  sampleCount_lte?: InputMaybe<Scalars["Int"]["input"]>;
  sampleCount_not?: InputMaybe<Scalars["Int"]["input"]>;
  sampleCount_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export enum GlobalFundingRateSample_OrderBy {
  CumulativePremiumRate = "cumulativePremiumRate",
  CumulativePremiumRateX96 = "cumulativePremiumRateX96",
  Id = "id",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  SampleCount = "sampleCount",
}

/** entity that represents a global liquidity position */
export type GlobalLiquidityPosition = {
  __typename?: "GlobalLiquidityPosition";
  /** the entry price of the net position held by all LPs */
  entryPrice: Scalars["BigDecimal"]["output"];
  /** the entry price of the net position held by all LPs, as a Q64.96 */
  entryPriceX96: Scalars["BigInt"]["output"];
  /** pool address */
  id: Scalars["Bytes"]["output"];
  /** the size of the net position held by all LPs in the liquidation buffer */
  liquidationBufferNetSize: Scalars["BigDecimal"]["output"];
  /** total liquidity held by all LPs */
  liquidity: Scalars["BigDecimal"]["output"];
  /** total margin held by all LPs */
  margin: Scalars["BigDecimal"]["output"];
  /** the size of the net position held by all LPs */
  netSize: Scalars["BigDecimal"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** realized profit of the pool */
  realizedProfit: Scalars["BigDecimal"]["output"];
  /** the accumulated realized profit growth per liquidity unit */
  realizedProfitGrowth: Scalars["BigDecimal"]["output"];
  /** the accumulated realized profit growth per liquidity unit, as a Q192.64 */
  realizedProfitGrowthX64: Scalars["BigInt"]["output"];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** all trading fee of the pool */
  tradingFee: Scalars["BigDecimal"]["output"];
};

export type GlobalLiquidityPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalLiquidityPosition_Filter>>>;
  entryPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  liquidationBufferNetSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  netSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  netSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<GlobalLiquidityPosition_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  realizedProfit?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfitGrowth?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfitGrowthX64?: InputMaybe<Scalars["BigInt"]["input"]>;
  realizedProfitGrowthX64_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  realizedProfitGrowthX64_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  realizedProfitGrowthX64_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  realizedProfitGrowthX64_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  realizedProfitGrowthX64_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  realizedProfitGrowthX64_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  realizedProfitGrowthX64_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  realizedProfitGrowth_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfitGrowth_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfitGrowth_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedProfitGrowth_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfitGrowth_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfitGrowth_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfitGrowth_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  realizedProfit_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfit_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfit_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedProfit_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfit_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfit_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfit_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  tradingFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  tradingFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum GlobalLiquidityPosition_OrderBy {
  EntryPrice = "entryPrice",
  EntryPriceX96 = "entryPriceX96",
  Id = "id",
  LiquidationBufferNetSize = "liquidationBufferNetSize",
  Liquidity = "liquidity",
  Margin = "margin",
  NetSize = "netSize",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  RealizedProfit = "realizedProfit",
  RealizedProfitGrowth = "realizedProfitGrowth",
  RealizedProfitGrowthX64 = "realizedProfitGrowthX64",
  Side = "side",
  TradingFee = "tradingFee",
}

export type GlobalPosition = {
  __typename?: "GlobalPosition";
  /**
   * The change in funding rate, a positive value means longs pay shorts,
   * when a negative value means shorts pay longs
   */
  fundingRate: Scalars["BigDecimal"]["output"];
  /**
   * The change in funding rate, a positive value means longs pay shorts,
   * when a negative value means shorts pay longs, as a Q160.96
   */
  fundingRateX96: Scalars["BigInt"]["output"];
  /** pool address */
  id: Scalars["Bytes"]["output"];
  /** the timestamp of the last funding rate adjustment */
  lastAdjustFundingRateTime: Scalars["BigInt"]["output"];
  /** the sum of all position liquidity */
  liquidity: Scalars["BigDecimal"]["output"];
  /** the funding rate growth per unit of long position sizes */
  longFundingRateGrowth: Scalars["BigDecimal"]["output"];
  /** the funding rate growth per unit of long position sizes, as a Q96.96 */
  longFundingRateGrowthX96: Scalars["BigInt"]["output"];
  /** the sum of long position sizes */
  longSize: Scalars["BigDecimal"]["output"];
  /** the sum of all position margins */
  margin: Scalars["BigDecimal"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** the funding rate growth per unit of short position sizes */
  shortFundingRateGrowth: Scalars["BigDecimal"]["output"];
  /** the funding rate growth per unit of short position sizes, as a Q96.96 */
  shortFundingRateGrowthX96: Scalars["BigInt"]["output"];
  /** the sum of short position sizes */
  shortSize: Scalars["BigDecimal"]["output"];
};

export type GlobalPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalPosition_Filter>>>;
  fundingRate?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRateX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingRateX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingRate_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingRate_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingRate_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  lastAdjustFundingRateTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastAdjustFundingRateTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastAdjustFundingRateTime_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  longFundingRateGrowth?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowthX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  longFundingRateGrowthX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  longFundingRateGrowth_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowth_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowth_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  longFundingRateGrowth_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowth_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowth_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowth_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  longSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  longSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<GlobalPosition_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  shortFundingRateGrowth?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowthX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  shortFundingRateGrowthX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  shortFundingRateGrowth_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowth_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowth_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  shortFundingRateGrowth_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowth_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowth_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowth_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  shortSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  shortSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum GlobalPosition_OrderBy {
  FundingRate = "fundingRate",
  FundingRateX96 = "fundingRateX96",
  Id = "id",
  LastAdjustFundingRateTime = "lastAdjustFundingRateTime",
  Liquidity = "liquidity",
  LongFundingRateGrowth = "longFundingRateGrowth",
  LongFundingRateGrowthX96 = "longFundingRateGrowthX96",
  LongSize = "longSize",
  Margin = "margin",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  ShortFundingRateGrowth = "shortFundingRateGrowth",
  ShortFundingRateGrowthX96 = "shortFundingRateGrowthX96",
  ShortSize = "shortSize",
}

/** entity that represents a global risk buffer fund */
export type GlobalRiskBufferFund = {
  __typename?: "GlobalRiskBufferFund";
  /** pool address */
  id: Scalars["Bytes"]["output"];
  /** the total liquidity of the risk buffer fund */
  liquidity: Scalars["BigDecimal"]["output"];
  /** pointer to pool */
  pool: Pool;
  /**
   * the risk buffer fund, which accumulated by unrealized losses and price impact fees
   * paid by LPs when positions are closed or liquidated. It also accumulates the remaining margin of LPs
   * after liquidation. Additionally, the net profit or loss from closing LP's net position is also accumulated
   * in the risk buffer fund
   */
  riskBufferFund: Scalars["BigDecimal"]["output"];
};

export type GlobalRiskBufferFund_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalRiskBufferFund_Filter>>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<GlobalRiskBufferFund_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  riskBufferFund?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFund_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFund_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFund_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  riskBufferFund_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFund_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFund_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFund_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum GlobalRiskBufferFund_OrderBy {
  Id = "id",
  Liquidity = "liquidity",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  RiskBufferFund = "riskBufferFund",
}

/** entity that represents a global unrealized loss metrics */
export type GlobalUnrealizedLossMetrics = {
  __typename?: "GlobalUnrealizedLossMetrics";
  /** pool address */
  id: Scalars["Bytes"]["output"];
  /**
   * the time when the LP's net position no longer has unrealized losses or the risk buffer fund has enough balance
   * to cover the unrealized losses of all LPs
   */
  lastZeroLossTime: Scalars["BigInt"]["output"];
  /** the total liquidity of all LPs whose entry time is after `lastZeroLossTime` */
  liquidity: Scalars["BigDecimal"]["output"];
  /** the product of liquidity and unrealized loss for each LP whose entry time is after `lastZeroLossTime` */
  liquidityTimesUnrealizedLoss: Scalars["BigInt"]["output"];
  /** pointer to pool */
  pool: Pool;
};

export type GlobalUnrealizedLossMetrics_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalUnrealizedLossMetrics_Filter>>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  lastZeroLossTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastZeroLossTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastZeroLossTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastZeroLossTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastZeroLossTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastZeroLossTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastZeroLossTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastZeroLossTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidityTimesUnrealizedLoss?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityTimesUnrealizedLoss_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityTimesUnrealizedLoss_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityTimesUnrealizedLoss_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  liquidityTimesUnrealizedLoss_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityTimesUnrealizedLoss_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityTimesUnrealizedLoss_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityTimesUnrealizedLoss_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<GlobalUnrealizedLossMetrics_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum GlobalUnrealizedLossMetrics_OrderBy {
  Id = "id",
  LastZeroLossTime = "lastZeroLossTime",
  Liquidity = "liquidity",
  LiquidityTimesUnrealizedLoss = "liquidityTimesUnrealizedLoss",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
}

/** entity that represents Traders' request to open/increase a position */
export type IncreasePositionRequest = {
  __typename?: "IncreasePositionRequest";
  /** worst trade price trader can accept */
  acceptableTradePrice: Scalars["BigDecimal"]["output"];
  /** worst trade price trader can accept, as a Q64.96 */
  acceptableTradePriceX96: Scalars["BigInt"]["output"];
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** must be non-zero for opening position */
  marginDelta: Scalars["BigDecimal"]["output"];
  /** in which pool to increase position */
  pool: Scalars["Bytes"]["output"];
  /** side of the request, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** size of the base token to increase */
  sizeDelta: Scalars["BigDecimal"]["output"];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Scalars["String"]["output"];
};

export type IncreasePositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableTradePrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  acceptableTradePriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  acceptableTradePrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  acceptableTradePrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<IncreasePositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  marginDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<IncreasePositionRequest_Filter>>>;
  pool?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  sizeDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  sizeDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_gt?: InputMaybe<Scalars["String"]["input"]>;
  status_gte?: InputMaybe<Scalars["String"]["input"]>;
  status_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_lt?: InputMaybe<Scalars["String"]["input"]>;
  status_lte?: InputMaybe<Scalars["String"]["input"]>;
  status_not?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum IncreasePositionRequest_OrderBy {
  AcceptableTradePrice = "acceptableTradePrice",
  AcceptableTradePriceX96 = "acceptableTradePriceX96",
  Account = "account",
  CancelledHash = "cancelledHash",
  CreatedHash = "createdHash",
  ExecutedHash = "executedHash",
  ExecutionFee = "executionFee",
  Id = "id",
  Index = "index",
  MarginDelta = "marginDelta",
  Pool = "pool",
  Side = "side",
  SizeDelta = "sizeDelta",
  Status = "status",
}

/** entity that represents users' request to increase risk buffer fund position */
export type IncreaseRiskBufferFundPositionRequest = {
  __typename?: "IncreaseRiskBufferFundPositionRequest";
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** liquidity to increase */
  liquidity: Scalars["BigDecimal"]["output"];
  /** in which pool to increase risk buffer fund position */
  pool: Scalars["Bytes"]["output"];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Scalars["String"]["output"];
};

export type IncreaseRiskBufferFundPositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<
    Array<InputMaybe<IncreaseRiskBufferFundPositionRequest_Filter>>
  >;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<
    Array<InputMaybe<IncreaseRiskBufferFundPositionRequest_Filter>>
  >;
  pool?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_gt?: InputMaybe<Scalars["String"]["input"]>;
  status_gte?: InputMaybe<Scalars["String"]["input"]>;
  status_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_lt?: InputMaybe<Scalars["String"]["input"]>;
  status_lte?: InputMaybe<Scalars["String"]["input"]>;
  status_not?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum IncreaseRiskBufferFundPositionRequest_OrderBy {
  Account = "account",
  CancelledHash = "cancelledHash",
  CreatedHash = "createdHash",
  ExecutedHash = "executedHash",
  ExecutionFee = "executionFee",
  Id = "id",
  Index = "index",
  Liquidity = "liquidity",
  Pool = "pool",
  Status = "status",
}

/** entity that represents a liquidity position */
export type LiquidityPosition = {
  __typename?: "LiquidityPosition";
  /** the owner of the position */
  account: Scalars["Bytes"]["output"];
  /** the block number when the position is closed */
  closeBlockNumber: Scalars["BigInt"]["output"];
  /** the time when the position is closed */
  closeTime: Scalars["BigInt"]["output"];
  /** the tx hash when the position is closed */
  closeTxHash: Scalars["Bytes"]["output"];
  /** the block number when the position is opened */
  entryBlockNumber: Scalars["BigInt"]["output"];
  entryRealizedProfitGrowth: Scalars["BigDecimal"]["output"];
  /** the snapshot of GlobalLiquidityPosition.realizedProfitGrowthX64 at the time of opening the position, as a Q192.64 */
  entryRealizedProfitGrowthX64: Scalars["BigInt"]["output"];
  /** the time when the position is opened */
  entryTime: Scalars["BigInt"]["output"];
  /** the tx hash when the position is opened */
  entryTxHash: Scalars["Bytes"]["output"];
  /** the snapshot of unrealized loss of LP at the time of opening the position */
  entryUnrealizedLoss: Scalars["BigDecimal"]["output"];
  /** the address that receives the liquidation execution fee */
  feeReceiver: Scalars["Bytes"]["output"];
  /** format: <pool address>:<position id> */
  id: Scalars["ID"]["output"];
  /** the initial margin of the position */
  initialMargin: Scalars["BigDecimal"]["output"];
  /** the liquidation execution fee paid by the position */
  liquidationExecutionFee: Scalars["BigDecimal"]["output"];
  /** the liquidity (value) of the position */
  liquidity: Scalars["BigDecimal"]["output"];
  /** the margin of the position */
  margin: Scalars["BigDecimal"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** the position id */
  positionID: Scalars["BigInt"]["output"];
  /** the profit balance of the position */
  profitBalance: Scalars["BigDecimal"]["output"];
  /** the realized PnL of the position */
  realizedPnL: Scalars["BigDecimal"]["output"];
  /** the realized profit of the position */
  realizedProfit: Scalars["BigDecimal"]["output"];
  /** status of the position, enum{Open,Closed,Liquidated} */
  status: Scalars["String"]["output"];
};

export type LiquidityPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<LiquidityPosition_Filter>>>;
  closeBlockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  closeBlockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeBlockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  closeTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  closeTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  closeTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  closeTxHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  closeTxHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  closeTxHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  entryBlockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryBlockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryRealizedProfitGrowth?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryRealizedProfitGrowthX64?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryRealizedProfitGrowthX64_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryRealizedProfitGrowthX64_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryRealizedProfitGrowthX64_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  entryRealizedProfitGrowthX64_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryRealizedProfitGrowthX64_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryRealizedProfitGrowthX64_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryRealizedProfitGrowthX64_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  entryRealizedProfitGrowth_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryRealizedProfitGrowth_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryRealizedProfitGrowth_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  entryRealizedProfitGrowth_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryRealizedProfitGrowth_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryRealizedProfitGrowth_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryRealizedProfitGrowth_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  entryTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTxHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  entryTxHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  entryUnrealizedLoss?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryUnrealizedLoss_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryUnrealizedLoss_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryUnrealizedLoss_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryUnrealizedLoss_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryUnrealizedLoss_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryUnrealizedLoss_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryUnrealizedLoss_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  feeReceiver?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  feeReceiver_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  feeReceiver_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  initialMargin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  initialMargin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  initialMargin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  initialMargin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  initialMargin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  initialMargin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  initialMargin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  initialMargin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationExecutionFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationExecutionFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationExecutionFee_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<LiquidityPosition_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  positionID?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  positionID_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  positionID_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  profitBalance?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  profitBalance_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  profitBalance_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  profitBalance_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  profitBalance_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  profitBalance_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  profitBalance_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  profitBalance_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedProfit?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfit_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfit_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfit_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedProfit_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfit_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfit_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedProfit_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_gt?: InputMaybe<Scalars["String"]["input"]>;
  status_gte?: InputMaybe<Scalars["String"]["input"]>;
  status_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_lt?: InputMaybe<Scalars["String"]["input"]>;
  status_lte?: InputMaybe<Scalars["String"]["input"]>;
  status_not?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum LiquidityPosition_OrderBy {
  Account = "account",
  CloseBlockNumber = "closeBlockNumber",
  CloseTime = "closeTime",
  CloseTxHash = "closeTxHash",
  EntryBlockNumber = "entryBlockNumber",
  EntryRealizedProfitGrowth = "entryRealizedProfitGrowth",
  EntryRealizedProfitGrowthX64 = "entryRealizedProfitGrowthX64",
  EntryTime = "entryTime",
  EntryTxHash = "entryTxHash",
  EntryUnrealizedLoss = "entryUnrealizedLoss",
  FeeReceiver = "feeReceiver",
  Id = "id",
  InitialMargin = "initialMargin",
  LiquidationExecutionFee = "liquidationExecutionFee",
  Liquidity = "liquidity",
  Margin = "margin",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  PositionId = "positionID",
  ProfitBalance = "profitBalance",
  RealizedPnL = "realizedPnL",
  RealizedProfit = "realizedProfit",
  Status = "status",
}

/** entity that represents LPs' request to open liquidity position */
export type OpenLiquidityPositionRequest = {
  __typename?: "OpenLiquidityPositionRequest";
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** liquidity to increase */
  liquidity: Scalars["BigDecimal"]["output"];
  /** margin used to open the position */
  margin: Scalars["BigDecimal"]["output"];
  /** in which pool to open liquidity position */
  pool: Scalars["Bytes"]["output"];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Scalars["String"]["output"];
};

export type OpenLiquidityPositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<OpenLiquidityPositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<OpenLiquidityPositionRequest_Filter>>>;
  pool?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_gt?: InputMaybe<Scalars["String"]["input"]>;
  status_gte?: InputMaybe<Scalars["String"]["input"]>;
  status_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_lt?: InputMaybe<Scalars["String"]["input"]>;
  status_lte?: InputMaybe<Scalars["String"]["input"]>;
  status_not?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum OpenLiquidityPositionRequest_OrderBy {
  Account = "account",
  CancelledHash = "cancelledHash",
  CreatedHash = "createdHash",
  ExecutedHash = "executedHash",
  ExecutionFee = "executionFee",
  Id = "id",
  Index = "index",
  Liquidity = "liquidity",
  Margin = "margin",
  Pool = "pool",
  Status = "status",
}

/** entity that represents a limit order */
export type Order = {
  __typename?: "Order";
  /** worst trade price user can accept */
  acceptableTradePrice: Scalars["BigDecimal"]["output"];
  /** worst trade price user can accept, as a Q64.96 */
  acceptableTradePriceX96: Scalars["BigInt"]["output"];
  /** account that create the request */
  account: Scalars["Bytes"]["output"];
  /** valid if the order cancelled, hash of the cancellation tx */
  cancelledHash: Scalars["Bytes"]["output"];
  /** timestamp that the order was cancelled */
  cancelledTimestamp: Scalars["BigInt"]["output"];
  /** tx hash of the request creation */
  createdHash: Scalars["Bytes"]["output"];
  /** timestamp that the order was created */
  createdTimestamp: Scalars["BigInt"]["output"];
  /** valid if the order executed, hash of the execution tx */
  executedHash: Scalars["Bytes"]["output"];
  /** timestamp that the order was executed */
  executedTimestamp: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  /** the request index */
  index: Scalars["BigInt"]["output"];
  /** timestamp that the order was operated(e.g. creation, cancellation, update) */
  lastOperationTimestamp: Scalars["BigInt"]["output"];
  /** margin paid by the user, transferred to pool when to execute */
  marginDelta: Scalars["BigDecimal"]["output"];
  /** in which pool to execute the order */
  pool: Pool;
  /** valid for decrease orders */
  receiver: Scalars["Bytes"]["output"];
  /** side of the request, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** size delta of base token */
  sizeDelta: Scalars["BigDecimal"]["output"];
  /** status of the order, enum{Created, Cancelled, Executed} */
  status: Scalars["String"]["output"];
  /** whether to trigger when market price is higher than trigger price */
  triggerAbove: Scalars["Boolean"]["output"];
  /** trigger price of the limit order */
  triggerMarketPrice: Scalars["BigDecimal"]["output"];
  /** trigger price of the limit order, as a Q64.96 */
  triggerMarketPriceX96: Scalars["BigInt"]["output"];
  /** type of the order, enum{Increase, Decrease} */
  type: Scalars["String"]["output"];
  /** tx hash that the order was last updated at */
  updatedHash: Scalars["Bytes"]["output"];
  /** timestamp that the order was last updated */
  updatedTimestamp: Scalars["BigInt"]["output"];
};

/** entity that represents the order book configuration */
export type OrderBookConfig = {
  __typename?: "OrderBookConfig";
  id: Scalars["ID"]["output"];
  /** minimum fee user must pay for executor to carry out their orders */
  minExecutionFee: Scalars["BigInt"]["output"];
};

export type OrderBookConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OrderBookConfig_Filter>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  minExecutionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minExecutionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<OrderBookConfig_Filter>>>;
};

export enum OrderBookConfig_OrderBy {
  Id = "id",
  MinExecutionFee = "minExecutionFee",
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = "asc",
  Desc = "desc",
}

export type Order_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableTradePrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  acceptableTradePriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  acceptableTradePriceX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  acceptableTradePrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  acceptableTradePrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  acceptableTradePrice_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<Order_Filter>>>;
  cancelledHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  cancelledTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  cancelledTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  cancelledTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  createdHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  createdHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  createdTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  createdTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  createdTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  executedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  executedTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  executedTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  executedTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  index?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  index_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  index_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastOperationTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastOperationTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastOperationTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  marginDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Order_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  receiver?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  receiver_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  receiver_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  sizeDelta?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  sizeDelta_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  sizeDelta_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_gt?: InputMaybe<Scalars["String"]["input"]>;
  status_gte?: InputMaybe<Scalars["String"]["input"]>;
  status_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_lt?: InputMaybe<Scalars["String"]["input"]>;
  status_lte?: InputMaybe<Scalars["String"]["input"]>;
  status_not?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  triggerAbove?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerAbove_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  triggerAbove_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerAbove_not_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  triggerMarketPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  triggerMarketPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  triggerMarketPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  triggerMarketPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  triggerMarketPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  triggerMarketPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_contains?: InputMaybe<Scalars["String"]["input"]>;
  type_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  type_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  type_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  type_gt?: InputMaybe<Scalars["String"]["input"]>;
  type_gte?: InputMaybe<Scalars["String"]["input"]>;
  type_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  type_lt?: InputMaybe<Scalars["String"]["input"]>;
  type_lte?: InputMaybe<Scalars["String"]["input"]>;
  type_not?: InputMaybe<Scalars["String"]["input"]>;
  type_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  type_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  type_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  type_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  type_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  type_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  type_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  type_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  type_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  updatedHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  updatedHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  updatedHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  updatedTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  updatedTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

export enum Order_OrderBy {
  AcceptableTradePrice = "acceptableTradePrice",
  AcceptableTradePriceX96 = "acceptableTradePriceX96",
  Account = "account",
  CancelledHash = "cancelledHash",
  CancelledTimestamp = "cancelledTimestamp",
  CreatedHash = "createdHash",
  CreatedTimestamp = "createdTimestamp",
  ExecutedHash = "executedHash",
  ExecutedTimestamp = "executedTimestamp",
  Id = "id",
  Index = "index",
  LastOperationTimestamp = "lastOperationTimestamp",
  MarginDelta = "marginDelta",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  Receiver = "receiver",
  Side = "side",
  SizeDelta = "sizeDelta",
  Status = "status",
  TriggerAbove = "triggerAbove",
  TriggerMarketPrice = "triggerMarketPrice",
  TriggerMarketPriceX96 = "triggerMarketPriceX96",
  Type = "type",
  UpdatedHash = "updatedHash",
  UpdatedTimestamp = "updatedTimestamp",
}

/** entity that represents a pool */
export type Pool = {
  __typename?: "Pool";
  fundingRateHistories: Array<FundingRateHistory>;
  /** pointer to global funding rate sample */
  globalFundingRateSample: GlobalFundingRateSample;
  /** pointer to global liquidity position */
  globalLiquidityPosition: GlobalLiquidityPosition;
  /** pointer to global position */
  globalPosition: GlobalPosition;
  /** pointer to global risk buffer fund */
  globalRiskBufferFund: GlobalRiskBufferFund;
  /** pointer to global unrealized loss metrics */
  globalUnrealizedLossMetrics: GlobalUnrealizedLossMetrics;
  /** pool address */
  id: Scalars["Bytes"]["output"];
  liquidityPositions: Array<LiquidityPosition>;
  orders: Array<Order>;
  positionHistories: Array<PositionHistory>;
  positions: Array<Position>;
  /** pointer to previous global funding rate */
  previousGlobalFundingRate: PreviousGlobalFundingRate;
  /** pointer to price state */
  priceState: PriceState;
  priceState1Hs: Array<PriceState1H>;
  /** protocol fee balance of the pool */
  protocolFee: Scalars["BigDecimal"]["output"];
  /** referral fee balance of the pool */
  referralFee: Scalars["BigDecimal"]["output"];
  /** accumulated usage of RBF */
  riskBufferFundGovUsed: Scalars["BigDecimal"]["output"];
  riskBufferFundPositions: Array<RiskBufferFundPosition>;
  /** base token */
  token: Token;
  /** total protocol fee of the pool */
  totalProtocolFee: Scalars["BigDecimal"]["output"];
  /** total referral fee of the pool */
  totalReferralFee: Scalars["BigDecimal"]["output"];
  transactionHistories: Array<TransactionHistory>;
  /** quote token */
  usd: Token;
  /** all time volume */
  volume: Scalars["BigDecimal"]["output"];
  /** all time volume in derived USD */
  volumeUSD: Scalars["BigDecimal"]["output"];
};

/** entity that represents a pool */
export type PoolFundingRateHistoriesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<FundingRateHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FundingRateHistory_Filter>;
};

/** entity that represents a pool */
export type PoolLiquidityPositionsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LiquidityPosition_Filter>;
};

/** entity that represents a pool */
export type PoolOrdersArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Order_Filter>;
};

/** entity that represents a pool */
export type PoolPositionHistoriesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PositionHistory_Filter>;
};

/** entity that represents a pool */
export type PoolPositionsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Position_Filter>;
};

/** entity that represents a pool */
export type PoolPriceState1HsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceState1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PriceState1H_Filter>;
};

/** entity that represents a pool */
export type PoolRiskBufferFundPositionsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<RiskBufferFundPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RiskBufferFundPosition_Filter>;
};

/** entity that represents a pool */
export type PoolTransactionHistoriesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<TransactionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TransactionHistory_Filter>;
};

export type Pool_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Pool_Filter>>>;
  fundingRateHistories_?: InputMaybe<FundingRateHistory_Filter>;
  globalFundingRateSample?: InputMaybe<Scalars["String"]["input"]>;
  globalFundingRateSample_?: InputMaybe<GlobalFundingRateSample_Filter>;
  globalFundingRateSample_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalFundingRateSample_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalFundingRateSample_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  globalFundingRateSample_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalFundingRateSample_gt?: InputMaybe<Scalars["String"]["input"]>;
  globalFundingRateSample_gte?: InputMaybe<Scalars["String"]["input"]>;
  globalFundingRateSample_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  globalFundingRateSample_lt?: InputMaybe<Scalars["String"]["input"]>;
  globalFundingRateSample_lte?: InputMaybe<Scalars["String"]["input"]>;
  globalFundingRateSample_not?: InputMaybe<Scalars["String"]["input"]>;
  globalFundingRateSample_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalFundingRateSample_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalFundingRateSample_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalFundingRateSample_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalFundingRateSample_not_in?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  globalFundingRateSample_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalFundingRateSample_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalFundingRateSample_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  globalFundingRateSample_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_?: InputMaybe<GlobalLiquidityPosition_Filter>;
  globalLiquidityPosition_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_gt?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_gte?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  globalLiquidityPosition_lt?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_lte?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_not?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_not_in?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  globalLiquidityPosition_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalLiquidityPosition_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  globalLiquidityPosition_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalPosition?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_?: InputMaybe<GlobalPosition_Filter>;
  globalPosition_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_gt?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_gte?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  globalPosition_lt?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_lte?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  globalPosition_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalPosition_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  globalPosition_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_?: InputMaybe<GlobalRiskBufferFund_Filter>;
  globalRiskBufferFund_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalRiskBufferFund_gt?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_gte?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  globalRiskBufferFund_lt?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_lte?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_not?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalRiskBufferFund_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalRiskBufferFund_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  globalRiskBufferFund_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalRiskBufferFund_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  globalRiskBufferFund_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalUnrealizedLossMetrics?: InputMaybe<Scalars["String"]["input"]>;
  globalUnrealizedLossMetrics_?: InputMaybe<GlobalUnrealizedLossMetrics_Filter>;
  globalUnrealizedLossMetrics_contains?: InputMaybe<Scalars["String"]["input"]>;
  globalUnrealizedLossMetrics_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalUnrealizedLossMetrics_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalUnrealizedLossMetrics_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalUnrealizedLossMetrics_gt?: InputMaybe<Scalars["String"]["input"]>;
  globalUnrealizedLossMetrics_gte?: InputMaybe<Scalars["String"]["input"]>;
  globalUnrealizedLossMetrics_in?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  globalUnrealizedLossMetrics_lt?: InputMaybe<Scalars["String"]["input"]>;
  globalUnrealizedLossMetrics_lte?: InputMaybe<Scalars["String"]["input"]>;
  globalUnrealizedLossMetrics_not?: InputMaybe<Scalars["String"]["input"]>;
  globalUnrealizedLossMetrics_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalUnrealizedLossMetrics_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalUnrealizedLossMetrics_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalUnrealizedLossMetrics_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalUnrealizedLossMetrics_not_in?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  globalUnrealizedLossMetrics_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalUnrealizedLossMetrics_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalUnrealizedLossMetrics_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  globalUnrealizedLossMetrics_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  liquidityPositions_?: InputMaybe<LiquidityPosition_Filter>;
  or?: InputMaybe<Array<InputMaybe<Pool_Filter>>>;
  orders_?: InputMaybe<Order_Filter>;
  positionHistories_?: InputMaybe<PositionHistory_Filter>;
  positions_?: InputMaybe<Position_Filter>;
  previousGlobalFundingRate?: InputMaybe<Scalars["String"]["input"]>;
  previousGlobalFundingRate_?: InputMaybe<PreviousGlobalFundingRate_Filter>;
  previousGlobalFundingRate_contains?: InputMaybe<Scalars["String"]["input"]>;
  previousGlobalFundingRate_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  previousGlobalFundingRate_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  previousGlobalFundingRate_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  previousGlobalFundingRate_gt?: InputMaybe<Scalars["String"]["input"]>;
  previousGlobalFundingRate_gte?: InputMaybe<Scalars["String"]["input"]>;
  previousGlobalFundingRate_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  previousGlobalFundingRate_lt?: InputMaybe<Scalars["String"]["input"]>;
  previousGlobalFundingRate_lte?: InputMaybe<Scalars["String"]["input"]>;
  previousGlobalFundingRate_not?: InputMaybe<Scalars["String"]["input"]>;
  previousGlobalFundingRate_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  previousGlobalFundingRate_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  previousGlobalFundingRate_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  previousGlobalFundingRate_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  previousGlobalFundingRate_not_in?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  previousGlobalFundingRate_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  previousGlobalFundingRate_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  previousGlobalFundingRate_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  previousGlobalFundingRate_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  priceState?: InputMaybe<Scalars["String"]["input"]>;
  priceState1Hs_?: InputMaybe<PriceState1H_Filter>;
  priceState_?: InputMaybe<PriceState_Filter>;
  priceState_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceState_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_gt?: InputMaybe<Scalars["String"]["input"]>;
  priceState_gte?: InputMaybe<Scalars["String"]["input"]>;
  priceState_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceState_lt?: InputMaybe<Scalars["String"]["input"]>;
  priceState_lte?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceState_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  protocolFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  protocolFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  protocolFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  referralFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  referralFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  referralFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  referralFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  referralFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  referralFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  referralFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  referralFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  riskBufferFundGovUsed?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFundGovUsed_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFundGovUsed_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFundGovUsed_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  riskBufferFundGovUsed_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFundGovUsed_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFundGovUsed_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  riskBufferFundGovUsed_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  riskBufferFundPositions_?: InputMaybe<RiskBufferFundPosition_Filter>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_gt?: InputMaybe<Scalars["String"]["input"]>;
  token_gte?: InputMaybe<Scalars["String"]["input"]>;
  token_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_lt?: InputMaybe<Scalars["String"]["input"]>;
  token_lte?: InputMaybe<Scalars["String"]["input"]>;
  token_not?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  totalProtocolFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  totalProtocolFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalProtocolFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  totalReferralFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalReferralFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalReferralFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalReferralFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  totalReferralFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalReferralFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalReferralFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  totalReferralFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  transactionHistories_?: InputMaybe<TransactionHistory_Filter>;
  usd?: InputMaybe<Scalars["String"]["input"]>;
  usd_?: InputMaybe<Token_Filter>;
  usd_contains?: InputMaybe<Scalars["String"]["input"]>;
  usd_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  usd_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  usd_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  usd_gt?: InputMaybe<Scalars["String"]["input"]>;
  usd_gte?: InputMaybe<Scalars["String"]["input"]>;
  usd_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  usd_lt?: InputMaybe<Scalars["String"]["input"]>;
  usd_lte?: InputMaybe<Scalars["String"]["input"]>;
  usd_not?: InputMaybe<Scalars["String"]["input"]>;
  usd_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  usd_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  usd_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  usd_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  usd_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  usd_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  usd_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  usd_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  usd_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  volume?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volumeUSD_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  volume_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  volume_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Pool_OrderBy {
  FundingRateHistories = "fundingRateHistories",
  GlobalFundingRateSample = "globalFundingRateSample",
  GlobalFundingRateSampleCumulativePremiumRate = "globalFundingRateSample__cumulativePremiumRate",
  GlobalFundingRateSampleCumulativePremiumRateX96 = "globalFundingRateSample__cumulativePremiumRateX96",
  GlobalFundingRateSampleId = "globalFundingRateSample__id",
  GlobalFundingRateSampleSampleCount = "globalFundingRateSample__sampleCount",
  GlobalLiquidityPosition = "globalLiquidityPosition",
  GlobalLiquidityPositionEntryPrice = "globalLiquidityPosition__entryPrice",
  GlobalLiquidityPositionEntryPriceX96 = "globalLiquidityPosition__entryPriceX96",
  GlobalLiquidityPositionId = "globalLiquidityPosition__id",
  GlobalLiquidityPositionLiquidationBufferNetSize = "globalLiquidityPosition__liquidationBufferNetSize",
  GlobalLiquidityPositionLiquidity = "globalLiquidityPosition__liquidity",
  GlobalLiquidityPositionMargin = "globalLiquidityPosition__margin",
  GlobalLiquidityPositionNetSize = "globalLiquidityPosition__netSize",
  GlobalLiquidityPositionRealizedProfit = "globalLiquidityPosition__realizedProfit",
  GlobalLiquidityPositionRealizedProfitGrowth = "globalLiquidityPosition__realizedProfitGrowth",
  GlobalLiquidityPositionRealizedProfitGrowthX64 = "globalLiquidityPosition__realizedProfitGrowthX64",
  GlobalLiquidityPositionSide = "globalLiquidityPosition__side",
  GlobalLiquidityPositionTradingFee = "globalLiquidityPosition__tradingFee",
  GlobalPosition = "globalPosition",
  GlobalPositionFundingRate = "globalPosition__fundingRate",
  GlobalPositionFundingRateX96 = "globalPosition__fundingRateX96",
  GlobalPositionId = "globalPosition__id",
  GlobalPositionLastAdjustFundingRateTime = "globalPosition__lastAdjustFundingRateTime",
  GlobalPositionLiquidity = "globalPosition__liquidity",
  GlobalPositionLongFundingRateGrowth = "globalPosition__longFundingRateGrowth",
  GlobalPositionLongFundingRateGrowthX96 = "globalPosition__longFundingRateGrowthX96",
  GlobalPositionLongSize = "globalPosition__longSize",
  GlobalPositionMargin = "globalPosition__margin",
  GlobalPositionShortFundingRateGrowth = "globalPosition__shortFundingRateGrowth",
  GlobalPositionShortFundingRateGrowthX96 = "globalPosition__shortFundingRateGrowthX96",
  GlobalPositionShortSize = "globalPosition__shortSize",
  GlobalRiskBufferFund = "globalRiskBufferFund",
  GlobalRiskBufferFundId = "globalRiskBufferFund__id",
  GlobalRiskBufferFundLiquidity = "globalRiskBufferFund__liquidity",
  GlobalRiskBufferFundRiskBufferFund = "globalRiskBufferFund__riskBufferFund",
  GlobalUnrealizedLossMetrics = "globalUnrealizedLossMetrics",
  GlobalUnrealizedLossMetricsId = "globalUnrealizedLossMetrics__id",
  GlobalUnrealizedLossMetricsLastZeroLossTime = "globalUnrealizedLossMetrics__lastZeroLossTime",
  GlobalUnrealizedLossMetricsLiquidity = "globalUnrealizedLossMetrics__liquidity",
  GlobalUnrealizedLossMetricsLiquidityTimesUnrealizedLoss = "globalUnrealizedLossMetrics__liquidityTimesUnrealizedLoss",
  Id = "id",
  LiquidityPositions = "liquidityPositions",
  Orders = "orders",
  PositionHistories = "positionHistories",
  Positions = "positions",
  PreviousGlobalFundingRate = "previousGlobalFundingRate",
  PreviousGlobalFundingRateId = "previousGlobalFundingRate__id",
  PreviousGlobalFundingRateLongFundingRateGrowth = "previousGlobalFundingRate__longFundingRateGrowth",
  PreviousGlobalFundingRateLongFundingRateGrowthX96 = "previousGlobalFundingRate__longFundingRateGrowthX96",
  PreviousGlobalFundingRateShortFundingRateGrowth = "previousGlobalFundingRate__shortFundingRateGrowth",
  PreviousGlobalFundingRateShortFundingRateGrowthX96 = "previousGlobalFundingRate__shortFundingRateGrowthX96",
  PriceState = "priceState",
  PriceState1Hs = "priceState1Hs",
  PriceStateId = "priceState__id",
  PriceStateIndexPriceUsedX96 = "priceState__indexPriceUsedX96",
  PriceStatePendingVertexIndex = "priceState__pendingVertexIndex",
  PriceStatePendingVertexIndexBitmap = "priceState__pendingVertexIndexBitmap",
  PriceStatePremiumRateX96 = "priceState__premiumRateX96",
  ProtocolFee = "protocolFee",
  ReferralFee = "referralFee",
  RiskBufferFundGovUsed = "riskBufferFundGovUsed",
  RiskBufferFundPositions = "riskBufferFundPositions",
  Token = "token",
  TokenDecimals = "token__decimals",
  TokenId = "token__id",
  TokenInterestRate = "token__interestRate",
  TokenLiquidationExecutionFee = "token__liquidationExecutionFee",
  TokenLiquidationFeeRatePerPosition = "token__liquidationFeeRatePerPosition",
  TokenLiquidationVertexIndex = "token__liquidationVertexIndex",
  TokenLiquidityFeeRate = "token__liquidityFeeRate",
  TokenMaxFundingRate = "token__maxFundingRate",
  TokenMaxLeveragePerLiquidityPosition = "token__maxLeveragePerLiquidityPosition",
  TokenMaxLeveragePerPosition = "token__maxLeveragePerPosition",
  TokenMaxPrice = "token__maxPrice",
  TokenMaxPriceImpactLiquidity = "token__maxPriceImpactLiquidity",
  TokenMaxPriceX96 = "token__maxPriceX96",
  TokenMaxRiskRatePerLiquidityPosition = "token__maxRiskRatePerLiquidityPosition",
  TokenMinMarginPerLiquidityPosition = "token__minMarginPerLiquidityPosition",
  TokenMinMarginPerPosition = "token__minMarginPerPosition",
  TokenMinPrice = "token__minPrice",
  TokenMinPriceX96 = "token__minPriceX96",
  TokenName = "token__name",
  TokenPool = "token__pool",
  TokenPrice = "token__price",
  TokenPriceX96 = "token__priceX96",
  TokenProtocolFeeRate = "token__protocolFeeRate",
  TokenReferralDiscountRate = "token__referralDiscountRate",
  TokenReferralParentReturnFeeRate = "token__referralParentReturnFeeRate",
  TokenReferralReturnFeeRate = "token__referralReturnFeeRate",
  TokenSymbol = "token__symbol",
  TokenTradingFeeRate = "token__tradingFeeRate",
  TotalProtocolFee = "totalProtocolFee",
  TotalReferralFee = "totalReferralFee",
  TransactionHistories = "transactionHistories",
  Usd = "usd",
  UsdDecimals = "usd__decimals",
  UsdId = "usd__id",
  UsdInterestRate = "usd__interestRate",
  UsdLiquidationExecutionFee = "usd__liquidationExecutionFee",
  UsdLiquidationFeeRatePerPosition = "usd__liquidationFeeRatePerPosition",
  UsdLiquidationVertexIndex = "usd__liquidationVertexIndex",
  UsdLiquidityFeeRate = "usd__liquidityFeeRate",
  UsdMaxFundingRate = "usd__maxFundingRate",
  UsdMaxLeveragePerLiquidityPosition = "usd__maxLeveragePerLiquidityPosition",
  UsdMaxLeveragePerPosition = "usd__maxLeveragePerPosition",
  UsdMaxPrice = "usd__maxPrice",
  UsdMaxPriceImpactLiquidity = "usd__maxPriceImpactLiquidity",
  UsdMaxPriceX96 = "usd__maxPriceX96",
  UsdMaxRiskRatePerLiquidityPosition = "usd__maxRiskRatePerLiquidityPosition",
  UsdMinMarginPerLiquidityPosition = "usd__minMarginPerLiquidityPosition",
  UsdMinMarginPerPosition = "usd__minMarginPerPosition",
  UsdMinPrice = "usd__minPrice",
  UsdMinPriceX96 = "usd__minPriceX96",
  UsdName = "usd__name",
  UsdPool = "usd__pool",
  UsdPrice = "usd__price",
  UsdPriceX96 = "usd__priceX96",
  UsdProtocolFeeRate = "usd__protocolFeeRate",
  UsdReferralDiscountRate = "usd__referralDiscountRate",
  UsdReferralParentReturnFeeRate = "usd__referralParentReturnFeeRate",
  UsdReferralReturnFeeRate = "usd__referralReturnFeeRate",
  UsdSymbol = "usd__symbol",
  UsdTradingFeeRate = "usd__tradingFeeRate",
  Volume = "volume",
  VolumeUsd = "volumeUSD",
}

/** entity that represents a position */
export type Position = {
  __typename?: "Position";
  /** the owner of the position */
  account: Scalars["Bytes"]["output"];
  /** the entry block of the position */
  entryBlockNumber: Scalars["BigInt"]["output"];
  /** the entry price of the position */
  entryPrice: Scalars["BigDecimal"]["output"];
  /** the entry price of the position, as a Q64.96 */
  entryPriceX96: Scalars["BigInt"]["output"];
  /** the entry time of the position */
  entryTime: Scalars["BigInt"]["output"];
  /** the entry tx hash of the position */
  entryTxHash: Scalars["Bytes"]["output"];
  /** accumulated funding fee of the position */
  fundingFee: Scalars["BigDecimal"]["output"];
  /** the snapshot of the funding rate growth at the time the position was opened/closed, as a Q96.96 */
  fundingRateGrowthX96: Scalars["BigInt"]["output"];
  /** format: <address>:<pool address>:<side> */
  id: Scalars["ID"]["output"];
  /** the block of the last update for the position */
  lastBlockNumber: Scalars["BigInt"]["output"];
  /** the timestamp of the last update for the position */
  lastBlockTimestamp: Scalars["BigInt"]["output"];
  /** the ID of the last transaction history */
  lastTransactionHistoryID: Scalars["String"]["output"];
  /** the tx hash of the last update for the position */
  lastTxHash: Scalars["Bytes"]["output"];
  /** the leverage of the position */
  leverage: Scalars["BigDecimal"]["output"];
  /** the liquidity of the position */
  liquidity: Scalars["BigDecimal"]["output"];
  /** the margin of the position */
  margin: Scalars["BigDecimal"]["output"];
  /** total decreased margin */
  marginDecreased: Scalars["BigDecimal"]["output"];
  /** total increased margin */
  marginIncreased: Scalars["BigDecimal"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** position history ID */
  positionHistoryID: Scalars["String"]["output"];
  /** accumulated realized PnL of the position */
  realizedPnL: Scalars["BigDecimal"]["output"];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** the size of the position */
  size: Scalars["BigDecimal"]["output"];
  /** accumulated trading fee of the position */
  tradingFee: Scalars["BigDecimal"]["output"];
  /** accumulated closing trading fee of the position */
  tradingFeeByClosing: Scalars["BigDecimal"]["output"];
};

/** entity that represents a position history */
export type PositionHistory = {
  __typename?: "PositionHistory";
  /** the owner of the position */
  account: Scalars["Bytes"]["output"];
  /** the accumulated liquidity from closing of the position */
  closedLiquidity: Scalars["BigDecimal"]["output"];
  /** the accumulated size from closing of the position */
  closedSize: Scalars["BigDecimal"]["output"];
  /** the timestamp of the last closing of the position */
  closedTime: Scalars["BigInt"]["output"];
  /** the entry price of the position, as a Q64.96 */
  entryPrice: Scalars["BigDecimal"]["output"];
  /** the entry time of the position */
  entryTime: Scalars["BigInt"]["output"];
  /** the entry tx hash of the position */
  entryTxHash: Scalars["Bytes"]["output"];
  /** accumulated funding fee of the position */
  fundingFee: Scalars["BigDecimal"]["output"];
  /** format: <transaction hash>:<log index> */
  id: Scalars["ID"]["output"];
  /** the liquidation fee of the position */
  liquidationFee: Scalars["BigDecimal"]["output"];
  /** the liquidation price of the position */
  liquidationPrice: Scalars["BigDecimal"]["output"];
  /** the liquidation size of the position */
  liquidationSize: Scalars["BigDecimal"]["output"];
  /** position is closed by the liquidator */
  liquidatorClosed: Scalars["Boolean"]["output"];
  /** the margin of the position */
  margin: Scalars["BigDecimal"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** accumulated realized PnL of the position */
  realizedPnL: Scalars["BigDecimal"]["output"];
  /** realized PnL rate of the position */
  realizedPnLRate: Scalars["BigDecimal"]["output"];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** the size of the position */
  size: Scalars["BigDecimal"]["output"];
  /** stats of the position history, enum{Closed,PartiallyClosed,Liquidated} */
  status: Scalars["String"]["output"];
};

export type PositionHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<PositionHistory_Filter>>>;
  closedLiquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  closedLiquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedLiquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  closedSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  closedSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  closedSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  closedTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  closedTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  closedTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTxHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  entryTxHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  fundingFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  liquidationFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidatorClosed?: InputMaybe<Scalars["Boolean"]["input"]>;
  liquidatorClosed_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  liquidatorClosed_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  liquidatorClosed_not_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<PositionHistory_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  realizedPnL?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnLRate_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnLRate_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  size?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  size_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_gt?: InputMaybe<Scalars["String"]["input"]>;
  status_gte?: InputMaybe<Scalars["String"]["input"]>;
  status_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_lt?: InputMaybe<Scalars["String"]["input"]>;
  status_lte?: InputMaybe<Scalars["String"]["input"]>;
  status_not?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  status_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  status_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum PositionHistory_OrderBy {
  Account = "account",
  ClosedLiquidity = "closedLiquidity",
  ClosedSize = "closedSize",
  ClosedTime = "closedTime",
  EntryPrice = "entryPrice",
  EntryTime = "entryTime",
  EntryTxHash = "entryTxHash",
  FundingFee = "fundingFee",
  Id = "id",
  LiquidationFee = "liquidationFee",
  LiquidationPrice = "liquidationPrice",
  LiquidationSize = "liquidationSize",
  LiquidatorClosed = "liquidatorClosed",
  Margin = "margin",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  RealizedPnL = "realizedPnL",
  RealizedPnLRate = "realizedPnLRate",
  Side = "side",
  Size = "size",
  Status = "status",
}

/** entity that represents the position router configuration */
export type PositionRouterConfig = {
  __typename?: "PositionRouterConfig";
  id: Scalars["ID"]["output"];
  /** minimum fee user must pay for executor to carry out their requests */
  minExecutionFee: Scalars["BigInt"]["output"];
};

export type PositionRouterConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PositionRouterConfig_Filter>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  minExecutionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minExecutionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  minExecutionFee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<PositionRouterConfig_Filter>>>;
};

export enum PositionRouterConfig_OrderBy {
  Id = "id",
  MinExecutionFee = "minExecutionFee",
}

export type Position_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  entryBlockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryBlockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryBlockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryTxHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  entryTxHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  entryTxHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  fundingFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingRateGrowthX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  fundingRateGrowthX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  fundingRateGrowthX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  lastBlockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastBlockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastBlockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastBlockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  lastBlockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  lastTransactionHistoryID?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistoryID_contains?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistoryID_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistoryID_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistoryID_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistoryID_gt?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistoryID_gte?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistoryID_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lastTransactionHistoryID_lt?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistoryID_lte?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistoryID_not?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistoryID_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistoryID_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistoryID_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistoryID_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistoryID_not_in?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  lastTransactionHistoryID_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistoryID_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTransactionHistoryID_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  lastTransactionHistoryID_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  lastTxHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  lastTxHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  lastTxHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  leverage?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  leverage_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  leverage_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  leverage_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  leverage_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  leverage_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  leverage_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  leverage_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginDecreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginDecreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginIncreased?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  marginIncreased_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  marginIncreased_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  margin_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  margin_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_contains?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_gt?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_gte?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  positionHistoryID_lt?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_lte?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_not?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_not_contains_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  positionHistoryID_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_not_ends_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  positionHistoryID_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  positionHistoryID_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_not_starts_with_nocase?: InputMaybe<
    Scalars["String"]["input"]
  >;
  positionHistoryID_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  positionHistoryID_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  realizedPnL?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  realizedPnL_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  size?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  size_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  tradingFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFeeByClosing?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFeeByClosing_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFeeByClosing_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFeeByClosing_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  tradingFeeByClosing_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFeeByClosing_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFeeByClosing_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFeeByClosing_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  tradingFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  tradingFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  tradingFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum Position_OrderBy {
  Account = "account",
  EntryBlockNumber = "entryBlockNumber",
  EntryPrice = "entryPrice",
  EntryPriceX96 = "entryPriceX96",
  EntryTime = "entryTime",
  EntryTxHash = "entryTxHash",
  FundingFee = "fundingFee",
  FundingRateGrowthX96 = "fundingRateGrowthX96",
  Id = "id",
  LastBlockNumber = "lastBlockNumber",
  LastBlockTimestamp = "lastBlockTimestamp",
  LastTransactionHistoryId = "lastTransactionHistoryID",
  LastTxHash = "lastTxHash",
  Leverage = "leverage",
  Liquidity = "liquidity",
  Margin = "margin",
  MarginDecreased = "marginDecreased",
  MarginIncreased = "marginIncreased",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  PositionHistoryId = "positionHistoryID",
  RealizedPnL = "realizedPnL",
  Side = "side",
  Size = "size",
  TradingFee = "tradingFee",
  TradingFeeByClosing = "tradingFeeByClosing",
}

export type PreviousGlobalFundingRate = {
  __typename?: "PreviousGlobalFundingRate";
  /** pool address */
  id: Scalars["Bytes"]["output"];
  /** the funding rate growth per unit of long position sizes */
  longFundingRateGrowth: Scalars["BigDecimal"]["output"];
  /** the funding rate growth per unit of long position sizes, as a Q96.96 */
  longFundingRateGrowthX96: Scalars["BigInt"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** the funding rate growth per unit of short position sizes */
  shortFundingRateGrowth: Scalars["BigDecimal"]["output"];
  /** the funding rate growth per unit of short position sizes, as a Q96.96 */
  shortFundingRateGrowthX96: Scalars["BigInt"]["output"];
};

export type PreviousGlobalFundingRate_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PreviousGlobalFundingRate_Filter>>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  longFundingRateGrowth?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowthX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  longFundingRateGrowthX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  longFundingRateGrowthX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  longFundingRateGrowth_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowth_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowth_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  longFundingRateGrowth_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowth_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowth_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  longFundingRateGrowth_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  or?: InputMaybe<Array<InputMaybe<PreviousGlobalFundingRate_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  shortFundingRateGrowth?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowthX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  shortFundingRateGrowthX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  shortFundingRateGrowthX96_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  shortFundingRateGrowth_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowth_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowth_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  shortFundingRateGrowth_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowth_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowth_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  shortFundingRateGrowth_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
};

export enum PreviousGlobalFundingRate_OrderBy {
  Id = "id",
  LongFundingRateGrowth = "longFundingRateGrowth",
  LongFundingRateGrowthX96 = "longFundingRateGrowthX96",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  ShortFundingRateGrowth = "shortFundingRateGrowth",
  ShortFundingRateGrowthX96 = "shortFundingRateGrowthX96",
}

/** entity that represents a price state */
export type PriceState = {
  __typename?: "PriceState";
  /** pool address */
  id: Scalars["Bytes"]["output"];
  indexPriceUsedX96: Scalars["BigInt"]["output"];
  /** the net sizes of the liquidation buffer */
  liquidationBufferNetSizes: Array<Scalars["BigDecimal"]["output"]>;
  /** the index used to track the pending update of the price vertex */
  pendingVertexIndex: Scalars["Int"]["output"];
  /** the bitmap used to calculate pending vertex index */
  pendingVertexIndexBitmap: Scalars["Int"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** the premium rate during the last position adjustment by the trader, as a Q32.96 */
  premiumRateX96: Scalars["BigInt"]["output"];
  /** the price vertices used to determine the pricing function */
  priceVertices: Array<PriceVertex>;
};

/** entity that represents a price state */
export type PriceStatePriceVerticesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceVertex_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PriceVertex_Filter>;
};

export type PriceState1H = {
  __typename?: "PriceState1H";
  balanceRate: Scalars["BigDecimal"]["output"];
  /** the entry price of the net position held by all LPs */
  entryPrice: Scalars["BigDecimal"]["output"];
  /** the entry price of the net position held by all LPs, as a Q64.96 */
  entryPriceX96: Scalars["BigInt"]["output"];
  /** format: <pool address>:<truncate timestamp> */
  id: Scalars["ID"]["output"];
  /** the size of the net position held by all LPs in the liquidation buffer */
  liquidationBufferNetSize: Scalars["BigDecimal"]["output"];
  /** total liquidity held by all LPs */
  liquidity: Scalars["BigDecimal"]["output"];
  /** the size of the net position held by all LPs */
  netSize: Scalars["BigDecimal"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** the side of the LP position, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** the start time of the price state */
  ts: Scalars["BigInt"]["output"];
  /** the number of transactions */
  txCount: Scalars["BigInt"]["output"];
};

export type PriceState1H_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PriceState1H_Filter>>>;
  balanceRate?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  balanceRate_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  balanceRate_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  entryPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  entryPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  entryPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  entryPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  liquidationBufferNetSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidationBufferNetSize_not_in?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  netSize?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  netSize_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  netSize_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<PriceState1H_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  ts?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  ts_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  ts_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

export enum PriceState1H_OrderBy {
  BalanceRate = "balanceRate",
  EntryPrice = "entryPrice",
  EntryPriceX96 = "entryPriceX96",
  Id = "id",
  LiquidationBufferNetSize = "liquidationBufferNetSize",
  Liquidity = "liquidity",
  NetSize = "netSize",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  Side = "side",
  Ts = "ts",
  TxCount = "txCount",
}

export type PriceState_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PriceState_Filter>>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  indexPriceUsedX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  indexPriceUsedX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  indexPriceUsedX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidationBufferNetSizes?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidationBufferNetSizes_contains?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSizes_contains_nocase?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSizes_not?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSizes_not_contains?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  liquidationBufferNetSizes_not_contains_nocase?: InputMaybe<
    Array<Scalars["BigDecimal"]["input"]>
  >;
  or?: InputMaybe<Array<InputMaybe<PriceState_Filter>>>;
  pendingVertexIndex?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_gt?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_gte?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  pendingVertexIndexBitmap_lt?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_lte?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_not?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndexBitmap_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  pendingVertexIndex_gt?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndex_gte?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndex_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  pendingVertexIndex_lt?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndex_lte?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndex_not?: InputMaybe<Scalars["Int"]["input"]>;
  pendingVertexIndex_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  premiumRateX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  premiumRateX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  priceVertices_?: InputMaybe<PriceVertex_Filter>;
};

export enum PriceState_OrderBy {
  Id = "id",
  IndexPriceUsedX96 = "indexPriceUsedX96",
  LiquidationBufferNetSizes = "liquidationBufferNetSizes",
  PendingVertexIndex = "pendingVertexIndex",
  PendingVertexIndexBitmap = "pendingVertexIndexBitmap",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  PremiumRateX96 = "premiumRateX96",
  PriceVertices = "priceVertices",
}

/** entity that represents determine the pricing function */
export type PriceVertex = {
  __typename?: "PriceVertex";
  /** format: <pool address>:<index> */
  id: Scalars["ID"]["output"];
  /** the premium rate during the last position adjustment by the LP, as a Q32.96 */
  premiumRateX96: Scalars["BigInt"]["output"];
  /** pointer to priceState */
  priceState: PriceState;
  /** size required to achieve the current premium rate */
  size: Scalars["BigDecimal"]["output"];
};

export type PriceVertex_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PriceVertex_Filter>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<PriceVertex_Filter>>>;
  premiumRateX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  premiumRateX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRateX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  priceState?: InputMaybe<Scalars["String"]["input"]>;
  priceState_?: InputMaybe<PriceState_Filter>;
  priceState_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceState_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_gt?: InputMaybe<Scalars["String"]["input"]>;
  priceState_gte?: InputMaybe<Scalars["String"]["input"]>;
  priceState_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceState_lt?: InputMaybe<Scalars["String"]["input"]>;
  priceState_lte?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceState_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  priceState_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceState_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  size_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  size_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
};

export enum PriceVertex_OrderBy {
  Id = "id",
  PremiumRateX96 = "premiumRateX96",
  PriceState = "priceState",
  PriceStateId = "priceState__id",
  PriceStateIndexPriceUsedX96 = "priceState__indexPriceUsedX96",
  PriceStatePendingVertexIndex = "priceState__pendingVertexIndex",
  PriceStatePendingVertexIndexBitmap = "priceState__pendingVertexIndexBitmap",
  PriceStatePremiumRateX96 = "priceState__premiumRateX96",
  Size = "size",
}

export type Query = {
  __typename?: "Query";
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  adjustLiquidityPositionMarginRequest?: Maybe<AdjustLiquidityPositionMarginRequest>;
  adjustLiquidityPositionMarginRequests: Array<AdjustLiquidityPositionMarginRequest>;
  candle1D?: Maybe<Candle1D>;
  candle1Ds: Array<Candle1D>;
  candle1H?: Maybe<Candle1H>;
  candle1Hs: Array<Candle1H>;
  candle1M?: Maybe<Candle1M>;
  candle1Ms: Array<Candle1M>;
  candle4H?: Maybe<Candle4H>;
  candle4Hs: Array<Candle4H>;
  candle5M?: Maybe<Candle5M>;
  candle5Ms: Array<Candle5M>;
  candle15M?: Maybe<Candle15M>;
  candle15Ms: Array<Candle15M>;
  candle30M?: Maybe<Candle30M>;
  candle30Ms: Array<Candle30M>;
  closeLiquidityPositionRequest?: Maybe<CloseLiquidityPositionRequest>;
  closeLiquidityPositionRequests: Array<CloseLiquidityPositionRequest>;
  decreasePositionRequest?: Maybe<DecreasePositionRequest>;
  decreasePositionRequests: Array<DecreasePositionRequest>;
  decreaseRiskBufferFundPositionRequest?: Maybe<DecreaseRiskBufferFundPositionRequest>;
  decreaseRiskBufferFundPositionRequests: Array<DecreaseRiskBufferFundPositionRequest>;
  fundingRateHistories: Array<FundingRateHistory>;
  fundingRateHistory?: Maybe<FundingRateHistory>;
  globalFundingRateSample?: Maybe<GlobalFundingRateSample>;
  globalFundingRateSamples: Array<GlobalFundingRateSample>;
  globalLiquidityPosition?: Maybe<GlobalLiquidityPosition>;
  globalLiquidityPositions: Array<GlobalLiquidityPosition>;
  globalPosition?: Maybe<GlobalPosition>;
  globalPositions: Array<GlobalPosition>;
  globalRiskBufferFund?: Maybe<GlobalRiskBufferFund>;
  globalRiskBufferFunds: Array<GlobalRiskBufferFund>;
  globalUnrealizedLossMetrics: Array<GlobalUnrealizedLossMetrics>;
  increasePositionRequest?: Maybe<IncreasePositionRequest>;
  increasePositionRequests: Array<IncreasePositionRequest>;
  increaseRiskBufferFundPositionRequest?: Maybe<IncreaseRiskBufferFundPositionRequest>;
  increaseRiskBufferFundPositionRequests: Array<IncreaseRiskBufferFundPositionRequest>;
  liquidityPosition?: Maybe<LiquidityPosition>;
  liquidityPositions: Array<LiquidityPosition>;
  openLiquidityPositionRequest?: Maybe<OpenLiquidityPositionRequest>;
  openLiquidityPositionRequests: Array<OpenLiquidityPositionRequest>;
  order?: Maybe<Order>;
  orderBookConfig?: Maybe<OrderBookConfig>;
  orderBookConfigs: Array<OrderBookConfig>;
  orders: Array<Order>;
  pool?: Maybe<Pool>;
  pools: Array<Pool>;
  position?: Maybe<Position>;
  positionHistories: Array<PositionHistory>;
  positionHistory?: Maybe<PositionHistory>;
  positionRouterConfig?: Maybe<PositionRouterConfig>;
  positionRouterConfigs: Array<PositionRouterConfig>;
  positions: Array<Position>;
  previousGlobalFundingRate?: Maybe<PreviousGlobalFundingRate>;
  previousGlobalFundingRates: Array<PreviousGlobalFundingRate>;
  priceState?: Maybe<PriceState>;
  priceState1H?: Maybe<PriceState1H>;
  priceState1Hs: Array<PriceState1H>;
  priceStates: Array<PriceState>;
  priceVertex?: Maybe<PriceVertex>;
  priceVertexes: Array<PriceVertex>;
  riskBufferFundPosition?: Maybe<RiskBufferFundPosition>;
  riskBufferFundPositions: Array<RiskBufferFundPosition>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  transactionHistories: Array<TransactionHistory>;
  transactionHistory?: Maybe<TransactionHistory>;
  vertexConfig?: Maybe<VertexConfig>;
  vertexConfigs: Array<VertexConfig>;
};

export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type QueryAdjustLiquidityPositionMarginRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryAdjustLiquidityPositionMarginRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AdjustLiquidityPositionMarginRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AdjustLiquidityPositionMarginRequest_Filter>;
};

export type QueryCandle1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1D_Filter>;
};

export type QueryCandle1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1H_Filter>;
};

export type QueryCandle1MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle1MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1M_Filter>;
};

export type QueryCandle4HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle4HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle4H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle4H_Filter>;
};

export type QueryCandle5MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle5MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle5M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle5M_Filter>;
};

export type QueryCandle15MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle15MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle15M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle15M_Filter>;
};

export type QueryCandle30MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCandle30MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle30M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle30M_Filter>;
};

export type QueryCloseLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryCloseLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CloseLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CloseLiquidityPositionRequest_Filter>;
};

export type QueryDecreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryDecreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<DecreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreasePositionRequest_Filter>;
};

export type QueryDecreaseRiskBufferFundPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryDecreaseRiskBufferFundPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<DecreaseRiskBufferFundPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreaseRiskBufferFundPositionRequest_Filter>;
};

export type QueryFundingRateHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<FundingRateHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FundingRateHistory_Filter>;
};

export type QueryFundingRateHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryGlobalFundingRateSampleArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryGlobalFundingRateSamplesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalFundingRateSample_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalFundingRateSample_Filter>;
};

export type QueryGlobalLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryGlobalLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalLiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidityPosition_Filter>;
};

export type QueryGlobalPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryGlobalPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalPosition_Filter>;
};

export type QueryGlobalRiskBufferFundArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryGlobalRiskBufferFundsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalRiskBufferFund_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalRiskBufferFund_Filter>;
};

export type QueryGlobalUnrealizedLossMetricsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalUnrealizedLossMetrics_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalUnrealizedLossMetrics_Filter>;
};

export type QueryIncreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryIncreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<IncreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreasePositionRequest_Filter>;
};

export type QueryIncreaseRiskBufferFundPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryIncreaseRiskBufferFundPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<IncreaseRiskBufferFundPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreaseRiskBufferFundPositionRequest_Filter>;
};

export type QueryLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPosition_Filter>;
};

export type QueryOpenLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryOpenLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<OpenLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OpenLiquidityPositionRequest_Filter>;
};

export type QueryOrderArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryOrderBookConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryOrderBookConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<OrderBookConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OrderBookConfig_Filter>;
};

export type QueryOrdersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Order_Filter>;
};

export type QueryPoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pool_Filter>;
};

export type QueryPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionHistory_Filter>;
};

export type QueryPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPositionRouterConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPositionRouterConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PositionRouterConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionRouterConfig_Filter>;
};

export type QueryPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};

export type QueryPreviousGlobalFundingRateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPreviousGlobalFundingRatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PreviousGlobalFundingRate_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PreviousGlobalFundingRate_Filter>;
};

export type QueryPriceStateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPriceState1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPriceState1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceState1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState1H_Filter>;
};

export type QueryPriceStatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceState_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState_Filter>;
};

export type QueryPriceVertexArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPriceVertexesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceVertex_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceVertex_Filter>;
};

export type QueryRiskBufferFundPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryRiskBufferFundPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<RiskBufferFundPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RiskBufferFundPosition_Filter>;
};

export type QueryTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};

export type QueryTransactionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<TransactionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransactionHistory_Filter>;
};

export type QueryTransactionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryVertexConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryVertexConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<VertexConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VertexConfig_Filter>;
};

/** entity that represents a RBF position */
export type RiskBufferFundPosition = {
  __typename?: "RiskBufferFundPosition";
  /** the owner of the RBF position */
  account: Scalars["Bytes"]["output"];
  /** format: <pool address>:<address> */
  id: Scalars["ID"]["output"];
  /** the liquidity of the RBF */
  liquidity: Scalars["BigDecimal"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** the time when the liquidity can be withdrawn */
  unlockTime: Scalars["BigInt"]["output"];
};

export type RiskBufferFundPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<RiskBufferFundPosition_Filter>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  liquidity?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  liquidity_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  liquidity_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<RiskBufferFundPosition_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  unlockTime?: InputMaybe<Scalars["BigInt"]["input"]>;
  unlockTime_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  unlockTime_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  unlockTime_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  unlockTime_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  unlockTime_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  unlockTime_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  unlockTime_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

export enum RiskBufferFundPosition_OrderBy {
  Account = "account",
  Id = "id",
  Liquidity = "liquidity",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  UnlockTime = "unlockTime",
}

export type Subscription = {
  __typename?: "Subscription";
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  adjustLiquidityPositionMarginRequest?: Maybe<AdjustLiquidityPositionMarginRequest>;
  adjustLiquidityPositionMarginRequests: Array<AdjustLiquidityPositionMarginRequest>;
  candle1D?: Maybe<Candle1D>;
  candle1Ds: Array<Candle1D>;
  candle1H?: Maybe<Candle1H>;
  candle1Hs: Array<Candle1H>;
  candle1M?: Maybe<Candle1M>;
  candle1Ms: Array<Candle1M>;
  candle4H?: Maybe<Candle4H>;
  candle4Hs: Array<Candle4H>;
  candle5M?: Maybe<Candle5M>;
  candle5Ms: Array<Candle5M>;
  candle15M?: Maybe<Candle15M>;
  candle15Ms: Array<Candle15M>;
  candle30M?: Maybe<Candle30M>;
  candle30Ms: Array<Candle30M>;
  closeLiquidityPositionRequest?: Maybe<CloseLiquidityPositionRequest>;
  closeLiquidityPositionRequests: Array<CloseLiquidityPositionRequest>;
  decreasePositionRequest?: Maybe<DecreasePositionRequest>;
  decreasePositionRequests: Array<DecreasePositionRequest>;
  decreaseRiskBufferFundPositionRequest?: Maybe<DecreaseRiskBufferFundPositionRequest>;
  decreaseRiskBufferFundPositionRequests: Array<DecreaseRiskBufferFundPositionRequest>;
  fundingRateHistories: Array<FundingRateHistory>;
  fundingRateHistory?: Maybe<FundingRateHistory>;
  globalFundingRateSample?: Maybe<GlobalFundingRateSample>;
  globalFundingRateSamples: Array<GlobalFundingRateSample>;
  globalLiquidityPosition?: Maybe<GlobalLiquidityPosition>;
  globalLiquidityPositions: Array<GlobalLiquidityPosition>;
  globalPosition?: Maybe<GlobalPosition>;
  globalPositions: Array<GlobalPosition>;
  globalRiskBufferFund?: Maybe<GlobalRiskBufferFund>;
  globalRiskBufferFunds: Array<GlobalRiskBufferFund>;
  globalUnrealizedLossMetrics: Array<GlobalUnrealizedLossMetrics>;
  increasePositionRequest?: Maybe<IncreasePositionRequest>;
  increasePositionRequests: Array<IncreasePositionRequest>;
  increaseRiskBufferFundPositionRequest?: Maybe<IncreaseRiskBufferFundPositionRequest>;
  increaseRiskBufferFundPositionRequests: Array<IncreaseRiskBufferFundPositionRequest>;
  liquidityPosition?: Maybe<LiquidityPosition>;
  liquidityPositions: Array<LiquidityPosition>;
  openLiquidityPositionRequest?: Maybe<OpenLiquidityPositionRequest>;
  openLiquidityPositionRequests: Array<OpenLiquidityPositionRequest>;
  order?: Maybe<Order>;
  orderBookConfig?: Maybe<OrderBookConfig>;
  orderBookConfigs: Array<OrderBookConfig>;
  orders: Array<Order>;
  pool?: Maybe<Pool>;
  pools: Array<Pool>;
  position?: Maybe<Position>;
  positionHistories: Array<PositionHistory>;
  positionHistory?: Maybe<PositionHistory>;
  positionRouterConfig?: Maybe<PositionRouterConfig>;
  positionRouterConfigs: Array<PositionRouterConfig>;
  positions: Array<Position>;
  previousGlobalFundingRate?: Maybe<PreviousGlobalFundingRate>;
  previousGlobalFundingRates: Array<PreviousGlobalFundingRate>;
  priceState?: Maybe<PriceState>;
  priceState1H?: Maybe<PriceState1H>;
  priceState1Hs: Array<PriceState1H>;
  priceStates: Array<PriceState>;
  priceVertex?: Maybe<PriceVertex>;
  priceVertexes: Array<PriceVertex>;
  riskBufferFundPosition?: Maybe<RiskBufferFundPosition>;
  riskBufferFundPositions: Array<RiskBufferFundPosition>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  transactionHistories: Array<TransactionHistory>;
  transactionHistory?: Maybe<TransactionHistory>;
  vertexConfig?: Maybe<VertexConfig>;
  vertexConfigs: Array<VertexConfig>;
};

export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type SubscriptionAdjustLiquidityPositionMarginRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionAdjustLiquidityPositionMarginRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AdjustLiquidityPositionMarginRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AdjustLiquidityPositionMarginRequest_Filter>;
};

export type SubscriptionCandle1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1D_Filter>;
};

export type SubscriptionCandle1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1H_Filter>;
};

export type SubscriptionCandle1MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle1MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1M_Filter>;
};

export type SubscriptionCandle4HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle4HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle4H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle4H_Filter>;
};

export type SubscriptionCandle5MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle5MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle5M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle5M_Filter>;
};

export type SubscriptionCandle15MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle15MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle15M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle15M_Filter>;
};

export type SubscriptionCandle30MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCandle30MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle30M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle30M_Filter>;
};

export type SubscriptionCloseLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionCloseLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CloseLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CloseLiquidityPositionRequest_Filter>;
};

export type SubscriptionDecreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionDecreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<DecreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreasePositionRequest_Filter>;
};

export type SubscriptionDecreaseRiskBufferFundPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionDecreaseRiskBufferFundPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<DecreaseRiskBufferFundPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreaseRiskBufferFundPositionRequest_Filter>;
};

export type SubscriptionFundingRateHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<FundingRateHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FundingRateHistory_Filter>;
};

export type SubscriptionFundingRateHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionGlobalFundingRateSampleArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionGlobalFundingRateSamplesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalFundingRateSample_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalFundingRateSample_Filter>;
};

export type SubscriptionGlobalLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionGlobalLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalLiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidityPosition_Filter>;
};

export type SubscriptionGlobalPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionGlobalPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalPosition_Filter>;
};

export type SubscriptionGlobalRiskBufferFundArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionGlobalRiskBufferFundsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalRiskBufferFund_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalRiskBufferFund_Filter>;
};

export type SubscriptionGlobalUnrealizedLossMetricsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<GlobalUnrealizedLossMetrics_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalUnrealizedLossMetrics_Filter>;
};

export type SubscriptionIncreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionIncreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<IncreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreasePositionRequest_Filter>;
};

export type SubscriptionIncreaseRiskBufferFundPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionIncreaseRiskBufferFundPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<IncreaseRiskBufferFundPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreaseRiskBufferFundPositionRequest_Filter>;
};

export type SubscriptionLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPosition_Filter>;
};

export type SubscriptionOpenLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionOpenLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<OpenLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OpenLiquidityPositionRequest_Filter>;
};

export type SubscriptionOrderArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionOrderBookConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionOrderBookConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<OrderBookConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OrderBookConfig_Filter>;
};

export type SubscriptionOrdersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Order_Filter>;
};

export type SubscriptionPoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pool_Filter>;
};

export type SubscriptionPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionHistory_Filter>;
};

export type SubscriptionPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPositionRouterConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPositionRouterConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PositionRouterConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionRouterConfig_Filter>;
};

export type SubscriptionPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};

export type SubscriptionPreviousGlobalFundingRateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPreviousGlobalFundingRatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PreviousGlobalFundingRate_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PreviousGlobalFundingRate_Filter>;
};

export type SubscriptionPriceStateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPriceState1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPriceState1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceState1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState1H_Filter>;
};

export type SubscriptionPriceStatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceState_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState_Filter>;
};

export type SubscriptionPriceVertexArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPriceVertexesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PriceVertex_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceVertex_Filter>;
};

export type SubscriptionRiskBufferFundPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionRiskBufferFundPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<RiskBufferFundPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RiskBufferFundPosition_Filter>;
};

export type SubscriptionTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};

export type SubscriptionTransactionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<TransactionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransactionHistory_Filter>;
};

export type SubscriptionTransactionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionVertexConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"]["input"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionVertexConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<VertexConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VertexConfig_Filter>;
};

/** entity that represents a token */
export type Token = {
  __typename?: "Token";
  candles1D: Array<Candle1D>;
  candles1H: Array<Candle1H>;
  candles1M: Array<Candle1M>;
  candles4H: Array<Candle4H>;
  candles5M: Array<Candle5M>;
  candles15M: Array<Candle15M>;
  candles30M: Array<Candle30M>;
  /** token decimals */
  decimals: Scalars["Int"]["output"];
  /** token address */
  id: Scalars["Bytes"]["output"];
  /** the interest rate used to calculate the funding rate, denominated in ten thousandths of a bip (i.e. 1e-8) */
  interestRate: Scalars["BigInt"]["output"];
  /** the liquidation execution fee for LP and trader positions */
  liquidationExecutionFee: Scalars["BigInt"]["output"];
  /** the liquidation fee rate for trader positions, denominated in ten thousandths of a bip (i.e. 1e-8) */
  liquidationFeeRatePerPosition: Scalars["BigInt"]["output"];
  /** the index used to store the net position of the liquidation */
  liquidationVertexIndex: Scalars["Int"]["output"];
  /** the liquidity fee rate as a percentage of trading fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  liquidityFeeRate: Scalars["BigInt"]["output"];
  /** the maximum funding rate, denominated in ten thousandths of a bip (i.e. 1e-8) */
  maxFundingRate: Scalars["BigInt"]["output"];
  /** the maximum leverage for LP positions */
  maxLeveragePerLiquidityPosition: Scalars["BigInt"]["output"];
  /** the maximum leverage for trader positions */
  maxLeveragePerPosition: Scalars["BigInt"]["output"];
  /** token maximum price */
  maxPrice: Scalars["BigDecimal"]["output"];
  /** the maximum LP liquidity value used to calculate premium rate when trader increase or decrease positions */
  maxPriceImpactLiquidity: Scalars["BigInt"]["output"];
  /** token maximum price, as a Q64.96 */
  maxPriceX96: Scalars["BigInt"]["output"];
  /** the maximum risk rate for LP positions, denominated in ten thousandths of a bip (i.e. 1e-8) */
  maxRiskRatePerLiquidityPosition: Scalars["BigInt"]["output"];
  /** the minimum entry margin required for LP positions */
  minMarginPerLiquidityPosition: Scalars["BigInt"]["output"];
  /** the minimum entry margin required for trader positions */
  minMarginPerPosition: Scalars["BigInt"]["output"];
  /** token minimum price */
  minPrice: Scalars["BigDecimal"]["output"];
  /** token minimum price, as a Q64.96 */
  minPriceX96: Scalars["BigInt"]["output"];
  /** token name */
  name: Scalars["String"]["output"];
  /** pool address */
  pool: Scalars["Bytes"]["output"];
  /** token price */
  price: Scalars["BigDecimal"]["output"];
  /** token price, as a Q64.96 */
  priceX96: Scalars["BigInt"]["output"];
  /** the protocol fee rate as a percentage of trading fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  protocolFeeRate: Scalars["BigInt"]["output"];
  /** the discount rate for referrals,denominated in ten thousandths of a bip (i.e. 1e-8) */
  referralDiscountRate: Scalars["BigInt"]["output"];
  /** the referral parent return fee rate as a percentage of trading fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  referralParentReturnFeeRate: Scalars["BigInt"]["output"];
  /** the referral return fee rate as a percentage of trading fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  referralReturnFeeRate: Scalars["BigInt"]["output"];
  /** token symbol */
  symbol: Scalars["String"]["output"];
  /** the trading fee rate for user increase or decrease positions, denominated in ten thousandths of a bip (i.e. 1e-8) */
  tradingFeeRate: Scalars["BigInt"]["output"];
  vertices: Array<VertexConfig>;
};

/** entity that represents a token */
export type TokenCandles1DArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle1D_Filter>;
};

/** entity that represents a token */
export type TokenCandles1HArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle1H_Filter>;
};

/** entity that represents a token */
export type TokenCandles1MArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle1M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle1M_Filter>;
};

/** entity that represents a token */
export type TokenCandles4HArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle4H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle4H_Filter>;
};

/** entity that represents a token */
export type TokenCandles5MArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle5M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle5M_Filter>;
};

/** entity that represents a token */
export type TokenCandles15MArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle15M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle15M_Filter>;
};

/** entity that represents a token */
export type TokenCandles30MArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Candle30M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<Candle30M_Filter>;
};

/** entity that represents a token */
export type TokenVerticesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<VertexConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<VertexConfig_Filter>;
};

export type Token_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  candles1D_?: InputMaybe<Candle1D_Filter>;
  candles1H_?: InputMaybe<Candle1H_Filter>;
  candles1M_?: InputMaybe<Candle1M_Filter>;
  candles4H_?: InputMaybe<Candle4H_Filter>;
  candles5M_?: InputMaybe<Candle5M_Filter>;
  candles15M_?: InputMaybe<Candle15M_Filter>;
  candles30M_?: InputMaybe<Candle30M_Filter>;
  decimals?: InputMaybe<Scalars["Int"]["input"]>;
  decimals_gt?: InputMaybe<Scalars["Int"]["input"]>;
  decimals_gte?: InputMaybe<Scalars["Int"]["input"]>;
  decimals_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  decimals_lt?: InputMaybe<Scalars["Int"]["input"]>;
  decimals_lte?: InputMaybe<Scalars["Int"]["input"]>;
  decimals_not?: InputMaybe<Scalars["Int"]["input"]>;
  decimals_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  id?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  interestRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  interestRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  interestRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidationExecutionFee?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidationExecutionFee_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationExecutionFee_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  liquidationFeeRatePerPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  liquidationFeeRatePerPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidationFeeRatePerPosition_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  liquidationVertexIndex?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_gt?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_gte?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  liquidationVertexIndex_lt?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_lte?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_not?: InputMaybe<Scalars["Int"]["input"]>;
  liquidationVertexIndex_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  liquidityFeeRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFeeRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFeeRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFeeRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  liquidityFeeRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFeeRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFeeRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  liquidityFeeRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxFundingRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxFundingRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxFundingRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxFundingRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxFundingRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxFundingRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxFundingRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxFundingRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxLeveragePerLiquidityPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  maxLeveragePerLiquidityPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerLiquidityPosition_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  maxLeveragePerPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxLeveragePerPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxLeveragePerPosition_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPriceImpactLiquidity?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxPriceImpactLiquidity_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceImpactLiquidity_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  maxPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  maxPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  maxPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maxPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  maxRiskRatePerLiquidityPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxRiskRatePerLiquidityPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxRiskRatePerLiquidityPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxRiskRatePerLiquidityPosition_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  maxRiskRatePerLiquidityPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxRiskRatePerLiquidityPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxRiskRatePerLiquidityPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  maxRiskRatePerLiquidityPosition_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  minMarginPerLiquidityPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  minMarginPerLiquidityPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerLiquidityPosition_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  minMarginPerPosition?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minMarginPerPosition_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  minMarginPerPosition_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minPrice?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  minPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  minPrice_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPrice_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPrice_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  minPrice_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPrice_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPrice_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  minPrice_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  name_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  name_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  name_gt?: InputMaybe<Scalars["String"]["input"]>;
  name_gte?: InputMaybe<Scalars["String"]["input"]>;
  name_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_lt?: InputMaybe<Scalars["String"]["input"]>;
  name_lte?: InputMaybe<Scalars["String"]["input"]>;
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  name_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  name_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  name_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  name_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  name_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  pool?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  price?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  priceX96?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  priceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  priceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  price_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  price_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  price_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  price_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  price_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  price_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  price_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  protocolFeeRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFeeRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFeeRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFeeRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  protocolFeeRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFeeRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFeeRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  protocolFeeRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  referralDiscountRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralDiscountRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralDiscountRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralDiscountRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  referralDiscountRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralDiscountRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralDiscountRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralDiscountRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  referralParentReturnFeeRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralParentReturnFeeRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralParentReturnFeeRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralParentReturnFeeRate_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  referralParentReturnFeeRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralParentReturnFeeRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralParentReturnFeeRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralParentReturnFeeRate_not_in?: InputMaybe<
    Array<Scalars["BigInt"]["input"]>
  >;
  referralReturnFeeRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralReturnFeeRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralReturnFeeRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralReturnFeeRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  referralReturnFeeRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralReturnFeeRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralReturnFeeRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  referralReturnFeeRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  symbol?: InputMaybe<Scalars["String"]["input"]>;
  symbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  symbol_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  symbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  symbol_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  symbol_gt?: InputMaybe<Scalars["String"]["input"]>;
  symbol_gte?: InputMaybe<Scalars["String"]["input"]>;
  symbol_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  symbol_lt?: InputMaybe<Scalars["String"]["input"]>;
  symbol_lte?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  symbol_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  symbol_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  symbol_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  tradingFeeRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  tradingFeeRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  tradingFeeRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  tradingFeeRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  tradingFeeRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  tradingFeeRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  tradingFeeRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  tradingFeeRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  vertices_?: InputMaybe<VertexConfig_Filter>;
};

export enum Token_OrderBy {
  Candles1D = "candles1D",
  Candles1H = "candles1H",
  Candles1M = "candles1M",
  Candles4H = "candles4H",
  Candles5M = "candles5M",
  Candles15M = "candles15M",
  Candles30M = "candles30M",
  Decimals = "decimals",
  Id = "id",
  InterestRate = "interestRate",
  LiquidationExecutionFee = "liquidationExecutionFee",
  LiquidationFeeRatePerPosition = "liquidationFeeRatePerPosition",
  LiquidationVertexIndex = "liquidationVertexIndex",
  LiquidityFeeRate = "liquidityFeeRate",
  MaxFundingRate = "maxFundingRate",
  MaxLeveragePerLiquidityPosition = "maxLeveragePerLiquidityPosition",
  MaxLeveragePerPosition = "maxLeveragePerPosition",
  MaxPrice = "maxPrice",
  MaxPriceImpactLiquidity = "maxPriceImpactLiquidity",
  MaxPriceX96 = "maxPriceX96",
  MaxRiskRatePerLiquidityPosition = "maxRiskRatePerLiquidityPosition",
  MinMarginPerLiquidityPosition = "minMarginPerLiquidityPosition",
  MinMarginPerPosition = "minMarginPerPosition",
  MinPrice = "minPrice",
  MinPriceX96 = "minPriceX96",
  Name = "name",
  Pool = "pool",
  Price = "price",
  PriceX96 = "priceX96",
  ProtocolFeeRate = "protocolFeeRate",
  ReferralDiscountRate = "referralDiscountRate",
  ReferralParentReturnFeeRate = "referralParentReturnFeeRate",
  ReferralReturnFeeRate = "referralReturnFeeRate",
  Symbol = "symbol",
  TradingFeeRate = "tradingFeeRate",
  Vertices = "vertices",
}

/** entity that represents a transaction history */
export type TransactionHistory = {
  __typename?: "TransactionHistory";
  /** the owner of the transaction history */
  account: Scalars["Bytes"]["output"];
  /** block which transaction was included in */
  blockNumber: Scalars["BigInt"]["output"];
  /** timestamp of the confirmed transaction */
  blockTimestamp: Scalars["BigInt"]["output"];
  /** JSON-formatted trade data */
  data: Scalars["String"]["output"];
  /** the funding fee */
  fundingFee: Scalars["BigDecimal"]["output"];
  /** format: <transaction hash>:<log index> */
  id: Scalars["ID"]["output"];
  /**
   * operation of the transaction history.
   * enum{Open,Close,AdjustMargin,Liquidate,GovUseRiskBufferFund,
   * OpenPosition,IncreaseMargin,IncreasePosition,DecreasePosition,ClosePosition,DecreaseMargin,
   * CreateIncreaseOrder,UpdateIncreaseOrder,CancelIncreaseOrder,ExecuteIncreaseOrder,
   * CreateDecreaseOrder,UpdateDecreaseOrder,CancelDecreaseOrder,ExecuteDecreaseOrder,
   * CreateOpenLiquidityPosition,CancelOpenLiquidityPosition,CreateCloseLiquidityPosition,
   * CancelCloseLiquidityPosition,CreateAdjustLiquidityPositionMargin,CancelAdjustLiquidityPositionMargin,
   * CreateIncreasePosition,CancelIncreasePosition,CreateDecreasePosition,CancelDecreasePosition,
   * CreateIncreaseRiskBufferFundPosition,CancelIncreaseRiskBufferFundPosition,
   * CreateDecreaseRiskBufferFundPosition,CancelDecreaseRiskBufferFundPosition}
   */
  operation: Scalars["String"]["output"];
  /** pointer to pool */
  pool: Pool;
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars["Int"]["output"];
  /** tx hash of the confirmed transaction */
  txHash: Scalars["Bytes"]["output"];
  /** type of the transaction history, enum{Liquidity,RiskBufferFund,Position,Orderbook,PositionRouter} */
  type: Scalars["String"]["output"];
};

export type TransactionHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  account_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  account_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  and?: InputMaybe<Array<InputMaybe<TransactionHistory_Filter>>>;
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  data?: InputMaybe<Scalars["String"]["input"]>;
  data_contains?: InputMaybe<Scalars["String"]["input"]>;
  data_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  data_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  data_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  data_gt?: InputMaybe<Scalars["String"]["input"]>;
  data_gte?: InputMaybe<Scalars["String"]["input"]>;
  data_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  data_lt?: InputMaybe<Scalars["String"]["input"]>;
  data_lte?: InputMaybe<Scalars["String"]["input"]>;
  data_not?: InputMaybe<Scalars["String"]["input"]>;
  data_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  data_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  data_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  data_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  data_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  data_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  data_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  data_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  data_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  fundingFee?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_gte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  fundingFee_lt?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_lte?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  fundingFee_not_in?: InputMaybe<Array<Scalars["BigDecimal"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  operation?: InputMaybe<Scalars["String"]["input"]>;
  operation_contains?: InputMaybe<Scalars["String"]["input"]>;
  operation_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  operation_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  operation_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  operation_gt?: InputMaybe<Scalars["String"]["input"]>;
  operation_gte?: InputMaybe<Scalars["String"]["input"]>;
  operation_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  operation_lt?: InputMaybe<Scalars["String"]["input"]>;
  operation_lte?: InputMaybe<Scalars["String"]["input"]>;
  operation_not?: InputMaybe<Scalars["String"]["input"]>;
  operation_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  operation_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  operation_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  operation_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  operation_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  operation_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  operation_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  operation_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  operation_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<TransactionHistory_Filter>>>;
  pool?: InputMaybe<Scalars["String"]["input"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_gt?: InputMaybe<Scalars["String"]["input"]>;
  pool_gte?: InputMaybe<Scalars["String"]["input"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_lt?: InputMaybe<Scalars["String"]["input"]>;
  pool_lte?: InputMaybe<Scalars["String"]["input"]>;
  pool_not?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  side?: InputMaybe<Scalars["Int"]["input"]>;
  side_gt?: InputMaybe<Scalars["Int"]["input"]>;
  side_gte?: InputMaybe<Scalars["Int"]["input"]>;
  side_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  side_lt?: InputMaybe<Scalars["Int"]["input"]>;
  side_lte?: InputMaybe<Scalars["Int"]["input"]>;
  side_not?: InputMaybe<Scalars["Int"]["input"]>;
  side_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  txHash?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  txHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_not?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>;
  txHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_contains?: InputMaybe<Scalars["String"]["input"]>;
  type_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  type_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  type_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  type_gt?: InputMaybe<Scalars["String"]["input"]>;
  type_gte?: InputMaybe<Scalars["String"]["input"]>;
  type_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  type_lt?: InputMaybe<Scalars["String"]["input"]>;
  type_lte?: InputMaybe<Scalars["String"]["input"]>;
  type_not?: InputMaybe<Scalars["String"]["input"]>;
  type_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  type_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  type_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  type_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  type_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  type_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  type_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  type_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  type_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum TransactionHistory_OrderBy {
  Account = "account",
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  Data = "data",
  FundingFee = "fundingFee",
  Id = "id",
  Operation = "operation",
  Pool = "pool",
  PoolId = "pool__id",
  PoolProtocolFee = "pool__protocolFee",
  PoolReferralFee = "pool__referralFee",
  PoolRiskBufferFundGovUsed = "pool__riskBufferFundGovUsed",
  PoolTotalProtocolFee = "pool__totalProtocolFee",
  PoolTotalReferralFee = "pool__totalReferralFee",
  PoolVolume = "pool__volume",
  PoolVolumeUsd = "pool__volumeUSD",
  Side = "side",
  TxHash = "txHash",
  Type = "type",
}

/** entity that represents the vertex configuration */
export type VertexConfig = {
  __typename?: "VertexConfig";
  /** the balance rate of the vertex, denominated in a bip (i.e. 1e-8) */
  balanceRate: Scalars["BigInt"]["output"];
  /** format: <token address>:<vertex index> */
  id: Scalars["ID"]["output"];
  /** the premium rate of the vertex, denominated in a bip (i.e. 1e-8) */
  premiumRate: Scalars["BigInt"]["output"];
  /** pointer to token */
  token: Token;
};

export type VertexConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<VertexConfig_Filter>>>;
  balanceRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  balanceRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  balanceRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_gt?: InputMaybe<Scalars["ID"]["input"]>;
  id_gte?: InputMaybe<Scalars["ID"]["input"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_lt?: InputMaybe<Scalars["ID"]["input"]>;
  id_lte?: InputMaybe<Scalars["ID"]["input"]>;
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  or?: InputMaybe<Array<InputMaybe<VertexConfig_Filter>>>;
  premiumRate?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_gt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_gte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  premiumRate_lt?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_lte?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_not?: InputMaybe<Scalars["BigInt"]["input"]>;
  premiumRate_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_gt?: InputMaybe<Scalars["String"]["input"]>;
  token_gte?: InputMaybe<Scalars["String"]["input"]>;
  token_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_lt?: InputMaybe<Scalars["String"]["input"]>;
  token_lte?: InputMaybe<Scalars["String"]["input"]>;
  token_not?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum VertexConfig_OrderBy {
  BalanceRate = "balanceRate",
  Id = "id",
  PremiumRate = "premiumRate",
  Token = "token",
  TokenDecimals = "token__decimals",
  TokenId = "token__id",
  TokenInterestRate = "token__interestRate",
  TokenLiquidationExecutionFee = "token__liquidationExecutionFee",
  TokenLiquidationFeeRatePerPosition = "token__liquidationFeeRatePerPosition",
  TokenLiquidationVertexIndex = "token__liquidationVertexIndex",
  TokenLiquidityFeeRate = "token__liquidityFeeRate",
  TokenMaxFundingRate = "token__maxFundingRate",
  TokenMaxLeveragePerLiquidityPosition = "token__maxLeveragePerLiquidityPosition",
  TokenMaxLeveragePerPosition = "token__maxLeveragePerPosition",
  TokenMaxPrice = "token__maxPrice",
  TokenMaxPriceImpactLiquidity = "token__maxPriceImpactLiquidity",
  TokenMaxPriceX96 = "token__maxPriceX96",
  TokenMaxRiskRatePerLiquidityPosition = "token__maxRiskRatePerLiquidityPosition",
  TokenMinMarginPerLiquidityPosition = "token__minMarginPerLiquidityPosition",
  TokenMinMarginPerPosition = "token__minMarginPerPosition",
  TokenMinPrice = "token__minPrice",
  TokenMinPriceX96 = "token__minPriceX96",
  TokenName = "token__name",
  TokenPool = "token__pool",
  TokenPrice = "token__price",
  TokenPriceX96 = "token__priceX96",
  TokenProtocolFeeRate = "token__protocolFeeRate",
  TokenReferralDiscountRate = "token__referralDiscountRate",
  TokenReferralParentReturnFeeRate = "token__referralParentReturnFeeRate",
  TokenReferralReturnFeeRate = "token__referralReturnFeeRate",
  TokenSymbol = "token__symbol",
  TokenTradingFeeRate = "token__tradingFeeRate",
}

export type _Block_ = {
  __typename?: "_Block_";
  /** The hash of the block */
  hash?: Maybe<Scalars["Bytes"]["output"]>;
  /** The block number */
  number: Scalars["Int"]["output"];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars["Int"]["output"]>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: "_Meta_";
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars["String"]["output"];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars["Boolean"]["output"];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = "allow",
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = "deny",
}

export type TokenFieldFragment = {
  __typename?: "Token";
  id: any;
  decimals: number;
  maxPrice: any;
  maxPriceX96: any;
  minMarginPerLiquidityPosition: any;
  maxLeveragePerLiquidityPosition: any;
  maxRiskRatePerLiquidityPosition: any;
  minMarginPerPosition: any;
  maxLeveragePerPosition: any;
  liquidationFeeRatePerPosition: any;
  minPrice: any;
  minPriceX96: any;
  name: string;
  price: any;
  priceX96: any;
  protocolFeeRate: any;
  tradingFeeRate: any;
  liquidationExecutionFee: any;
  symbol: string;
  interestRate: any;
  maxFundingRate: any;
  maxPriceImpactLiquidity: any;
  liquidationVertexIndex: number;
  liquidityFeeRate: any;
  referralDiscountRate: any;
  referralParentReturnFeeRate: any;
  referralReturnFeeRate: any;
  vertices: Array<{
    __typename?: "VertexConfig";
    id: string;
    premiumRate: any;
    balanceRate: any;
  }>;
};

export type GlobalLiquidityPositionFieldFragment = {
  __typename?: "GlobalLiquidityPosition";
  entryPriceX96: any;
  liquidity: any;
  netSize: any;
  side: number;
  realizedProfitGrowthX64: any;
  entryPrice: any;
  margin: any;
  realizedProfit: any;
  realizedProfitGrowth: any;
  tradingFee: any;
  liquidationBufferNetSize: any;
};

export type GlobalPositionFieldFragment = {
  __typename?: "GlobalPosition";
  fundingRate: any;
  lastAdjustFundingRateTime: any;
  longSize: any;
  shortSize: any;
  longFundingRateGrowthX96: any;
  shortFundingRateGrowthX96: any;
};

export type PriceStateFieldFragment = {
  __typename?: "PriceState";
  liquidationBufferNetSizes: Array<any>;
  pendingVertexIndex: number;
  premiumRateX96: any;
  priceVertices: Array<{
    __typename?: "PriceVertex";
    id: string;
    premiumRateX96: any;
    size: any;
  }>;
};

export type GlobalFundingRateSampleFieldFragment = {
  __typename?: "GlobalFundingRateSample";
  sampleCount: number;
  cumulativePremiumRate: any;
  cumulativePremiumRateX96: any;
};

export type PoolsQueryVariables = Exact<{
  blockBefore: Scalars["Int"]["input"];
}>;

export type PoolsQuery = {
  __typename?: "Query";
  pools: Array<{
    __typename?: "Pool";
    id: any;
    volume: any;
    volumeUSD: any;
    globalLiquidityPosition: {
      __typename?: "GlobalLiquidityPosition";
      entryPriceX96: any;
      liquidity: any;
      netSize: any;
      side: number;
      realizedProfitGrowthX64: any;
      entryPrice: any;
      margin: any;
      realizedProfit: any;
      realizedProfitGrowth: any;
      tradingFee: any;
      liquidationBufferNetSize: any;
    };
    globalPosition: {
      __typename?: "GlobalPosition";
      fundingRate: any;
      lastAdjustFundingRateTime: any;
      longSize: any;
      shortSize: any;
      longFundingRateGrowthX96: any;
      shortFundingRateGrowthX96: any;
    };
    token: {
      __typename?: "Token";
      id: any;
      decimals: number;
      maxPrice: any;
      maxPriceX96: any;
      minMarginPerLiquidityPosition: any;
      maxLeveragePerLiquidityPosition: any;
      maxRiskRatePerLiquidityPosition: any;
      minMarginPerPosition: any;
      maxLeveragePerPosition: any;
      liquidationFeeRatePerPosition: any;
      minPrice: any;
      minPriceX96: any;
      name: string;
      price: any;
      priceX96: any;
      protocolFeeRate: any;
      tradingFeeRate: any;
      liquidationExecutionFee: any;
      symbol: string;
      interestRate: any;
      maxFundingRate: any;
      maxPriceImpactLiquidity: any;
      liquidationVertexIndex: number;
      liquidityFeeRate: any;
      referralDiscountRate: any;
      referralParentReturnFeeRate: any;
      referralReturnFeeRate: any;
      vertices: Array<{
        __typename?: "VertexConfig";
        id: string;
        premiumRate: any;
        balanceRate: any;
      }>;
    };
    globalFundingRateSample: {
      __typename?: "GlobalFundingRateSample";
      sampleCount: number;
      cumulativePremiumRate: any;
      cumulativePremiumRateX96: any;
    };
    priceState: {
      __typename?: "PriceState";
      liquidationBufferNetSizes: Array<any>;
      pendingVertexIndex: number;
      premiumRateX96: any;
      priceVertices: Array<{
        __typename?: "PriceVertex";
        id: string;
        premiumRateX96: any;
        size: any;
      }>;
    };
    globalRiskBufferFund: {
      __typename?: "GlobalRiskBufferFund";
      liquidity: any;
      riskBufferFund: any;
    };
  }>;
  poolsBefore1D: Array<{
    __typename?: "Pool";
    id: any;
    volume: any;
    volumeUSD: any;
    globalLiquidityPosition: {
      __typename?: "GlobalLiquidityPosition";
      entryPriceX96: any;
      liquidity: any;
      netSize: any;
      side: number;
      realizedProfitGrowthX64: any;
      entryPrice: any;
      margin: any;
      realizedProfit: any;
      realizedProfitGrowth: any;
      tradingFee: any;
      liquidationBufferNetSize: any;
    };
    globalPosition: {
      __typename?: "GlobalPosition";
      fundingRate: any;
      lastAdjustFundingRateTime: any;
      longSize: any;
      shortSize: any;
      longFundingRateGrowthX96: any;
      shortFundingRateGrowthX96: any;
    };
    token: {
      __typename?: "Token";
      id: any;
      decimals: number;
      maxPrice: any;
      maxPriceX96: any;
      minMarginPerLiquidityPosition: any;
      maxLeveragePerLiquidityPosition: any;
      maxRiskRatePerLiquidityPosition: any;
      minMarginPerPosition: any;
      maxLeveragePerPosition: any;
      liquidationFeeRatePerPosition: any;
      minPrice: any;
      minPriceX96: any;
      name: string;
      price: any;
      priceX96: any;
      protocolFeeRate: any;
      tradingFeeRate: any;
      liquidationExecutionFee: any;
      symbol: string;
      interestRate: any;
      maxFundingRate: any;
      maxPriceImpactLiquidity: any;
      liquidationVertexIndex: number;
      liquidityFeeRate: any;
      referralDiscountRate: any;
      referralParentReturnFeeRate: any;
      referralReturnFeeRate: any;
      vertices: Array<{
        __typename?: "VertexConfig";
        id: string;
        premiumRate: any;
        balanceRate: any;
      }>;
    };
    globalFundingRateSample: {
      __typename?: "GlobalFundingRateSample";
      sampleCount: number;
      cumulativePremiumRate: any;
      cumulativePremiumRateX96: any;
    };
    priceState: {
      __typename?: "PriceState";
      liquidationBufferNetSizes: Array<any>;
      pendingVertexIndex: number;
      premiumRateX96: any;
      priceVertices: Array<{
        __typename?: "PriceVertex";
        id: string;
        premiumRateX96: any;
        size: any;
      }>;
    };
  }>;
};

export type PoolsGlobalQueryVariables = Exact<{ [key: string]: never }>;

export type PoolsGlobalQuery = {
  __typename?: "Query";
  pools: Array<{
    __typename?: "Pool";
    id: any;
    globalLiquidityPosition: {
      __typename?: "GlobalLiquidityPosition";
      entryPriceX96: any;
      liquidity: any;
      netSize: any;
      side: number;
      realizedProfitGrowthX64: any;
      entryPrice: any;
      margin: any;
      realizedProfit: any;
      realizedProfitGrowth: any;
      tradingFee: any;
      liquidationBufferNetSize: any;
    };
    globalRiskBufferFund: {
      __typename?: "GlobalRiskBufferFund";
      liquidity: any;
      riskBufferFund: any;
    };
    token: {
      __typename?: "Token";
      id: any;
      decimals: number;
      maxPrice: any;
      maxPriceX96: any;
      minMarginPerLiquidityPosition: any;
      maxLeveragePerLiquidityPosition: any;
      maxRiskRatePerLiquidityPosition: any;
      minMarginPerPosition: any;
      maxLeveragePerPosition: any;
      liquidationFeeRatePerPosition: any;
      minPrice: any;
      minPriceX96: any;
      name: string;
      price: any;
      priceX96: any;
      protocolFeeRate: any;
      tradingFeeRate: any;
      liquidationExecutionFee: any;
      symbol: string;
      interestRate: any;
      maxFundingRate: any;
      maxPriceImpactLiquidity: any;
      liquidationVertexIndex: number;
      liquidityFeeRate: any;
      referralDiscountRate: any;
      referralParentReturnFeeRate: any;
      referralReturnFeeRate: any;
      vertices: Array<{
        __typename?: "VertexConfig";
        id: string;
        premiumRate: any;
        balanceRate: any;
      }>;
    };
  }>;
};

export type RiskBufferFundPositionQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RiskBufferFundPositionQuery = {
  __typename?: "Query";
  riskBufferFundPosition?: {
    __typename?: "RiskBufferFundPosition";
    liquidity: any;
    account: any;
    unlockTime: any;
  };
};

export const TokenFieldFragmentDoc = gql`
  fragment tokenField on Token {
    id
    decimals
    maxPrice
    maxPriceX96
    minMarginPerLiquidityPosition
    maxLeveragePerLiquidityPosition
    maxRiskRatePerLiquidityPosition
    minMarginPerPosition
    maxLeveragePerPosition
    liquidationFeeRatePerPosition
    minPrice
    minPriceX96
    name
    price
    priceX96
    protocolFeeRate
    tradingFeeRate
    liquidationExecutionFee
    symbol
    interestRate
    maxFundingRate
    maxPriceImpactLiquidity
    liquidationVertexIndex
    liquidityFeeRate
    maxRiskRatePerLiquidityPosition
    minMarginPerLiquidityPosition
    minMarginPerPosition
    minPrice
    minPriceX96
    name
    price
    priceX96
    protocolFeeRate
    referralDiscountRate
    referralParentReturnFeeRate
    referralReturnFeeRate
    vertices {
      id
      premiumRate
      balanceRate
    }
  }
`;
export const GlobalLiquidityPositionFieldFragmentDoc = gql`
  fragment globalLiquidityPositionField on GlobalLiquidityPosition {
    entryPriceX96
    liquidity
    netSize
    side
    realizedProfitGrowthX64
    entryPrice
    margin
    realizedProfit
    realizedProfitGrowth
    tradingFee
    liquidationBufferNetSize
  }
`;
export const GlobalPositionFieldFragmentDoc = gql`
  fragment globalPositionField on GlobalPosition {
    fundingRate
    lastAdjustFundingRateTime
    longSize
    shortSize
    longFundingRateGrowthX96
    shortFundingRateGrowthX96
  }
`;
export const PriceStateFieldFragmentDoc = gql`
  fragment priceStateField on PriceState {
    liquidationBufferNetSizes
    pendingVertexIndex
    premiumRateX96
    priceVertices {
      id
      premiumRateX96
      size
    }
  }
`;
export const GlobalFundingRateSampleFieldFragmentDoc = gql`
  fragment globalFundingRateSampleField on GlobalFundingRateSample {
    sampleCount
    cumulativePremiumRate
    cumulativePremiumRateX96
  }
`;
export const PoolsDocument = gql`
  query Pools($blockBefore: Int!) {
    pools {
      id
      globalLiquidityPosition {
        ...globalLiquidityPositionField
      }
      globalPosition {
        ...globalPositionField
      }
      token {
        ...tokenField
      }
      volume
      volumeUSD
      globalFundingRateSample {
        ...globalFundingRateSampleField
      }
      priceState {
        ...priceStateField
      }
      globalRiskBufferFund {
        liquidity
        riskBufferFund
      }
    }
    poolsBefore1D: pools(block: { number: $blockBefore }) {
      id
      globalLiquidityPosition {
        ...globalLiquidityPositionField
      }
      globalPosition {
        ...globalPositionField
      }
      token {
        ...tokenField
      }
      volume
      volumeUSD
      globalFundingRateSample {
        ...globalFundingRateSampleField
      }
      priceState {
        ...priceStateField
      }
    }
  }
  ${GlobalLiquidityPositionFieldFragmentDoc}
  ${GlobalPositionFieldFragmentDoc}
  ${TokenFieldFragmentDoc}
  ${GlobalFundingRateSampleFieldFragmentDoc}
  ${PriceStateFieldFragmentDoc}
`;

/**
 * __usePoolsQuery__
 *
 * To run a query within a React component, call `usePoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoolsQuery({
 *   variables: {
 *      blockBefore: // value for 'blockBefore'
 *   },
 * });
 */
export function usePoolsQuery(
  baseOptions: Apollo.QueryHookOptions<PoolsQuery, PoolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PoolsQuery, PoolsQueryVariables>(
    PoolsDocument,
    options
  );
}
export function usePoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PoolsQuery, PoolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PoolsQuery, PoolsQueryVariables>(
    PoolsDocument,
    options
  );
}
export function usePoolsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PoolsQuery, PoolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PoolsQuery, PoolsQueryVariables>(
    PoolsDocument,
    options
  );
}
export type PoolsQueryHookResult = ReturnType<typeof usePoolsQuery>;
export type PoolsLazyQueryHookResult = ReturnType<typeof usePoolsLazyQuery>;
export type PoolsSuspenseQueryHookResult = ReturnType<
  typeof usePoolsSuspenseQuery
>;
export type PoolsQueryResult = Apollo.QueryResult<
  PoolsQuery,
  PoolsQueryVariables
>;
export const PoolsGlobalDocument = gql`
  query PoolsGlobal {
    pools {
      id
      globalLiquidityPosition {
        ...globalLiquidityPositionField
      }
      globalRiskBufferFund {
        liquidity
        riskBufferFund
      }
      token {
        ...tokenField
      }
    }
  }
  ${GlobalLiquidityPositionFieldFragmentDoc}
  ${TokenFieldFragmentDoc}
`;

/**
 * __usePoolsGlobalQuery__
 *
 * To run a query within a React component, call `usePoolsGlobalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoolsGlobalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoolsGlobalQuery({
 *   variables: {
 *   },
 * });
 */
export function usePoolsGlobalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PoolsGlobalQuery,
    PoolsGlobalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PoolsGlobalQuery, PoolsGlobalQueryVariables>(
    PoolsGlobalDocument,
    options
  );
}
export function usePoolsGlobalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PoolsGlobalQuery,
    PoolsGlobalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PoolsGlobalQuery, PoolsGlobalQueryVariables>(
    PoolsGlobalDocument,
    options
  );
}
export function usePoolsGlobalSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PoolsGlobalQuery,
    PoolsGlobalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PoolsGlobalQuery, PoolsGlobalQueryVariables>(
    PoolsGlobalDocument,
    options
  );
}
export type PoolsGlobalQueryHookResult = ReturnType<typeof usePoolsGlobalQuery>;
export type PoolsGlobalLazyQueryHookResult = ReturnType<
  typeof usePoolsGlobalLazyQuery
>;
export type PoolsGlobalSuspenseQueryHookResult = ReturnType<
  typeof usePoolsGlobalSuspenseQuery
>;
export type PoolsGlobalQueryResult = Apollo.QueryResult<
  PoolsGlobalQuery,
  PoolsGlobalQueryVariables
>;
export const RiskBufferFundPositionDocument = gql`
  query RiskBufferFundPosition($id: ID!) {
    riskBufferFundPosition(id: $id) {
      liquidity
      account
      unlockTime
    }
  }
`;

/**
 * __useRiskBufferFundPositionQuery__
 *
 * To run a query within a React component, call `useRiskBufferFundPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiskBufferFundPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskBufferFundPositionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRiskBufferFundPositionQuery(
  baseOptions: Apollo.QueryHookOptions<
    RiskBufferFundPositionQuery,
    RiskBufferFundPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RiskBufferFundPositionQuery,
    RiskBufferFundPositionQueryVariables
  >(RiskBufferFundPositionDocument, options);
}
export function useRiskBufferFundPositionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RiskBufferFundPositionQuery,
    RiskBufferFundPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RiskBufferFundPositionQuery,
    RiskBufferFundPositionQueryVariables
  >(RiskBufferFundPositionDocument, options);
}
export function useRiskBufferFundPositionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RiskBufferFundPositionQuery,
    RiskBufferFundPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RiskBufferFundPositionQuery,
    RiskBufferFundPositionQueryVariables
  >(RiskBufferFundPositionDocument, options);
}
export type RiskBufferFundPositionQueryHookResult = ReturnType<
  typeof useRiskBufferFundPositionQuery
>;
export type RiskBufferFundPositionLazyQueryHookResult = ReturnType<
  typeof useRiskBufferFundPositionLazyQuery
>;
export type RiskBufferFundPositionSuspenseQueryHookResult = ReturnType<
  typeof useRiskBufferFundPositionSuspenseQuery
>;
export type RiskBufferFundPositionQueryResult = Apollo.QueryResult<
  RiskBufferFundPositionQuery,
  RiskBufferFundPositionQueryVariables
>;
