import React from "react";
// import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const StyledLoading = styled("div")(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // color: alpha(theme.palette.primary.main, 0.6)
  background: theme.palette.background.default,
}));

const Loading = (props: any) => {
  return (
    <StyledLoading>
      <svg
        version="1.1"
        id="loader-1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="80px"
        height="80px"
        viewBox="0 0 80 80"
      >
        <defs>
          <linearGradient id="circle">
            <stop offset="0%" stopColor="#A5FECB" />
            <stop offset="50%" stopColor="#20BDFF" />
            <stop offset="100%" stopColor="#5433FF" />
          </linearGradient>
          <linearGradient id="center" x1="100%" y1="0" x2="0" y2="0">
            <stop offset="0%" stopColor="rgba(15, 232, 235, 0)" />
            <stop offset="100%" stopColor="rgba(199, 238, 0, 1)" />
          </linearGradient>
        </defs>
        <g id="logo" transform="translate(23,21)">
        <path d="M31.524 9.2763L16.5542 16.7749C16.3707 16.8669 16.1544 16.8657 15.9719 16.7719L1.13828 9.14445C0.667907 8.90258 0.674092 8.22801 1.14882 7.99481L16.1186 0.641212C16.2997 0.552276 16.512 0.553398 16.6921 0.644244L31.5256 8.12662C31.9978 8.36483 31.9969 9.03942 31.524 9.2763Z" fill="#C7EE00"/>
          <path d="M0.345089 28.7308L14.3132 36.0099C14.7415 36.2331 15.2539 35.9223 15.2539 35.4393V19.4307C15.2539 19.1899 15.1194 18.9693 14.9054 18.8589L0.937313 11.6553C0.509109 11.4344 -0.000962257 11.7453 -0.000962257 12.2271V28.1603C-0.000962257 28.4001 0.132419 28.62 0.345089 28.7308Z" fill="#C7EE00"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M10.6488 29.119C10.8703 29.6756 10.2778 30.2062 9.7489 29.9249L8.93764 29.4935C8.80325 29.422 8.69855 29.3052 8.64216 29.1638L7.26724 25.7168L5.98544 27.366C5.79531 27.6106 5.45783 27.685 5.18248 27.5429L4.30167 27.0886C3.94327 26.9037 3.84137 26.4384 4.08966 26.1207L6.29998 23.2918L4.23944 18.1258C4.01877 17.5726 4.60356 17.0423 5.13261 17.316L5.95 17.7388C6.08761 17.8099 6.19492 17.9284 6.2522 18.0723L7.64461 21.5708L8.94296 19.9091C9.13341 19.6654 9.47037 19.5917 9.7452 19.7336L10.6075 20.179C10.965 20.3637 11.0672 20.8276 10.8202 21.1454L8.60808 23.9916L10.6488 29.119Z" fill="#111111"/>
          <path d="M32.4655 28.7308L18.4973 36.0099C18.069 36.2331 17.5566 35.9223 17.5566 35.4393V19.4307C17.5566 19.1899 17.6911 18.9693 17.9051 18.8589L31.8732 11.6553C32.3014 11.4344 32.8115 11.7453 32.8115 12.2271V28.1603C32.8115 28.4001 32.6781 28.62 32.4655 28.7308Z" fill="#C7EE00"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M17.8275 4.21828C18.0121 4.12958 18.2279 4.13403 18.4086 4.23027L19.4071 4.76192C19.8725 5.0097 19.8587 5.68131 19.3835 5.9097L10.1862 10.3301C10.0017 10.4188 9.78591 10.4143 9.60517 10.3181L8.60664 9.78644C8.14127 9.53866 8.15512 8.86705 8.6303 8.63867L17.8275 4.21828ZM22.433 6.52102C22.6176 6.43231 22.8333 6.43676 23.0141 6.533L24.0126 7.06465C24.478 7.31243 24.4641 7.98405 23.989 8.21243L14.7917 12.6328C14.6071 12.7215 14.3914 12.7171 14.2106 12.6208L13.2121 12.0892C12.7467 11.8414 12.7606 11.1698 13.2358 10.9414L22.433 6.52102Z" fill="#111111"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M26.7188 18.4635C26.7188 17.9896 26.2238 17.6783 25.7967 17.8836L24.8163 18.3548C24.5934 18.462 24.4516 18.6874 24.4516 18.9347V23.1731L20.6323 25.0088C20.4093 25.1159 20.2676 25.3413 20.2676 25.5886L20.2676 26.6764C20.2676 27.1503 20.7625 27.4616 21.1897 27.2563L24.4516 25.6885V29.3079C24.4516 29.7818 24.9465 30.0931 25.3737 29.8878L26.3541 29.4166C26.577 29.3095 26.7188 29.0841 26.7188 28.8367V24.5989L30.5391 22.7627C30.762 22.6556 30.9038 22.4302 30.9038 22.1829V21.0951C30.9038 20.6212 30.4089 20.3099 29.9817 20.5152L26.7188 22.0835V18.4635Z" fill="#111111"/>
        </g>
        <circle
          className="process-circle"
          cx="40"
          cy="40"
          r="38"
          transform="rotate(0 40 40)"
          strokeWidth="4"
          stroke="url(#center)"
          fill="none"
          strokeLinecap="round"
          strokeDasharray="170,100"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 40 40"
            to="360 40 40"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </StyledLoading>
  );
};

export default Loading;
