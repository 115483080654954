import React from "react";
import { Helmet } from "react-helmet";
import Advantage from "./advantage";
import Topic from "./topic";
import Tokenomics from "./tokenomics";
import Roadmap from "./roadmap";
import Summary from "./summary";
import Trade from "./to-trade";

function Index() {
  return (
    <div className="space-y-10 relative sm:space-y-2.5">
      <Helmet title="Equation" />
      <Topic />
      <Summary />
      <Advantage />
      <Roadmap />
      <Tokenomics />
    </div>
  );
}

export default Index;
