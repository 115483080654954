import { useMemo, useState } from "react";

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { BLOCK_GRAPH_URL, START_BLOCK_NUMBER } from "config/env";
import moment from "moment";
import { ARBITRUM_ONE_BLOCKS_ADDRESS } from "constants/v3";

import { isLessThan, plus } from "utils";

import { useCurrentChain } from "./useCurrentChain";
import useWeb3Provider from "./useWeb3Provider";

export default function useBlockNumber() {
  const [blockNumber, setBlockNumber] = useState<number>();

  const { currentChainId } = useCurrentChain();
  const provider = useWeb3Provider();

  const refechBlockNumber = () => {
    provider
      .getBlockNumber()
      .then((block) => {
        if (block !== blockNumber) setBlockNumber(block);
      })
      .catch((error) => {
        console.error(
          `Failed to get block number for chainId ${currentChainId}`,
          error
        );
      });
  };

  return {
    blockNumber,
    refechBlockNumber,
  };
}

const GET_BLOCK_NUMBER = gql`
  query BlockNumbers($timestamp: String!) {
    blocks(first: 1, orderBy: timestamp, orderDirection: desc) {
      id
      number
      timestamp
    }
    blocksBefore: blocks(
      first: 1
      where: { timestamp_lte: $timestamp }
      orderBy: timestamp
      orderDirection: desc
    ) {
      timestamp
      number
    }
  }
`;

const GET_BLOCK_NUMBER_FOR_UNI = gql`
  query BlockNumbers(
    $timestampBefore: String!
    $timestampBeforeUpper: String!
  ) {
    blocks(first: 1, orderBy: timestamp, orderDirection: desc) {
      timestamp
      number
    }
    blocksBefore: blocks(
      first: 1
      where: {
        timestamp_lte: $timestampBefore
        timestamp_lt: $timestampBeforeUpper
      }
      orderBy: timestamp
      orderDirection: desc
    ) {
      timestamp
      number
    }
  }
`;

export function useGetBlockNumbers() {
  const { currentChainId, currentChain } = useCurrentChain();
  const [blockLatest, setBlockLatest] = useState<number>(0);
  const [blockLatestTimestamp, setBlockLatestTimestamp] = useState<number>(0);
  const [blockBefore24h, setBlockBefore24h] = useState<number>(0);

  const blockClient = useMemo(() => {
    return new ApolloClient({
      uri: BLOCK_GRAPH_URL[currentChainId],
      cache: new InMemoryCache(),
    });
  }, [currentChainId]);

  const refechBlockNumbers = () => {
    if (currentChain.testnet) {
      return;
    }
    const timeStampBefore24h = moment().subtract(1, "days").unix().toString();
    blockClient
      .query({
        query: GET_BLOCK_NUMBER,
        variables: { timestamp: timeStampBefore24h },
      })
      .then((result) => {
        const blockInfoOfLastest = result.data.blocks[0];
        const blockInfoOfBefore24h = result.data.blocksBefore[0];

        setBlockLatest(blockInfoOfLastest.number);
        setBlockLatestTimestamp(blockInfoOfLastest.timestamp);

        // console.log('blockInfoOfLastest.timestamp: ', blockInfoOfLastest.timestamp);

        if (
          !blockInfoOfBefore24h ||
          isLessThan(
            blockInfoOfBefore24h.number,
            START_BLOCK_NUMBER[currentChainId]
          )
        ) {
          setBlockBefore24h(START_BLOCK_NUMBER[currentChainId]);
        } else {
          setBlockBefore24h(Number(blockInfoOfBefore24h.number));
        }
      })
      .catch((error) => {
        console.error(`Failed to get block numbers: `, error);
      });
  };

  return {
    blockLatest,
    blockLatestTimestamp,
    blockBefore24h,
    refechBlockNumbers,
  };
}

export function useGetBlockNumbersForUni() {
  const { currentChainId } = useCurrentChain();

  const [blockLatest, setBlockLatest] = useState<number>(0);
  const [blockLatestTimestamp, setBlockLatestTimestamp] = useState<number>(0);
  const [blockBefore24h, setBlockBefore24h] = useState<number>(0);

  const uniBlocksClient = useMemo(() => {
    return new ApolloClient({
      uri: ARBITRUM_ONE_BLOCKS_ADDRESS,
      cache: new InMemoryCache(),
    });
  }, [currentChainId]);

  const refechBlockNumbers = () => {
    const timeStampBefore24h = moment().subtract(1, "days").unix().toString();

    uniBlocksClient
      .query({
        query: GET_BLOCK_NUMBER_FOR_UNI,
        variables: {
          timestampBefore: timeStampBefore24h,
          timestampBeforeUpper: plus(timeStampBefore24h, 600),
        },
      })
      .then((result) => {
        const blockInfoOfLastest = result.data.blocks[0];
        const blockInfoOfBefore24h = result.data.blocksBefore[0];

        setBlockLatest(blockInfoOfLastest.number);
        setBlockLatestTimestamp(blockInfoOfLastest.timestamp);

        setBlockBefore24h(Number(blockInfoOfBefore24h.number));
      })
      .catch((error) => {
        console.error(`Failed to get block numbers: `, error);
      });
  };

  return {
    blockLatest,
    blockLatestTimestamp,
    blockBefore24h,
    refechBlockNumbers,
  };
}
