import React from 'react';
import {styled} from '@mui/material';
import {MediumContent} from '../components';


export default function Introduce(){
  return (
    <div className='w-full flex pt-5.25 sm:py-1.5 justify-center'>
      <MediumContent>
        <div className='space-y-1.5 leading-normal'>
          <div className='text-[32px] sm:text-xl font-bold'>
            "My wealth was never truly mine, so I created wealth for everyone."
          </div>   
          <div className='text-lg sm:text-sm text-textSecondary'>
          To some extent, we owe thanks to FTX for shedding light on the drawbacks of CeFi (Centralized Finance) in a "bloody" manner. Of course, we don't intend to dwell on the importance of DeFi (Decentralized Finance) in light of such events, but as true believers in the principles of Bitcoin, we proudly introduce a fully decentralized on-chain perpetual contracts protocol to uphold the core spirit of "Not your key, not your coin."
          </div>   
          <div className='text-[24px] sm:text-[15px] leading-nromal flex sm:flex-col h-4.25 sm:h-auto'>
            <div>
              「 It's better to be a pirate than to join the navy. 」
            </div>
            <div className='text-lg sm:text-sm text-right font-normal relative -bottom-2.25 sm:bottom-0 sm:mt-0.5 text-textSecondary'>
              --As Steve Jobs once said
            </div>   
          </div>   
          <div className='text-lg sm:text-sm text-textSecondary'>
          DeFi brings financial decentralization and an open-source and permissionless technological environment that fuels our passion for shaping a more flexible and powerful crypto protocol.
          </div>   
          <div className='text-lg sm:text-sm text-textSecondary'>
          Equation offers a trading experience similar to centralized exchanges (CEX) but with lower fees compared to those decentralized exchanges (DEX). What's more, Equation takes a Fairlaunch approach for all ecosystem tokens representing its future value potential, with no investors, foundations, or reserved amounts for the founding team.
          </div>   
          <div className='text-lg sm:text-sm text-textSecondary'>
          Equation is like a "pirate" in the DeFi space, making CEX navies spit out our money and then becoming a pirate ourselves-a pirate who hides all treasures in a secret location only we know, and starts hunting "whales" with leverage of up to 200x!
          </div>   
        </div>
      </MediumContent>
    </div>
  )
}

