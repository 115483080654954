import React from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import Logo from "../../components/logo";

const NavsContainer = styled("div")(({ theme }) => ({
  padding: "0px 120px 10px",
  "@media screen and (max-width: 768px)": {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "1.5rem",
  },
  "@media screen and (min-width: 769px) and (max-width: 1199px)": {
    padding: "0px 20px  10px",
    width: "100%",
  },
  "@media screen and (min-width: 1921px)": {
    padding: "0px 240px  10px",
  },
}));

const Footer = () => {
  return (
    <div className="relative w-full flex justify-center items-center pt-2.5 sm:pt-1.5 sm:pb-0.5">
      <NavsContainer className="flex justify-between flex-col items-center w-full pt-0.75 sm:pt-0.75">
        <div className="flex mt-3 w-full justify-center sm:justify-center mb-1 sm:mt-0.5 sm:mb-0.5">
          {/* <div className="flex flex-col w-full sm:w-full sm:justify-center sm:flex-col">
            <div className="flex items-center sm:justify-between sm:items-start">
              <NavLink
                to="/"
                className="fontMedium text-base text-textSecondary hover:opacity-60 sm:text-sm sm:ml-0 sm:mt-0.875"
              >
                Stats
              </NavLink>
              <NavLink
                to="/"
                className="fontMedium text-base text-textSecondary ml-3 hover:opacity-60 sm:text-sm sm:ml-0 sm:mt-0.875"
              >
                Docs
              </NavLink>
              <NavLink
                to="/"
                className="fontMedium text-base text-textSecondary ml-3 hover:opacity-60 sm:text-sm sm:ml-0 sm:mt-0.875"
              >
                Coummity
              </NavLink>
              <NavLink
                to="/"
                className="fontMedium text-base text-textSecondary ml-3 hover:opacity-60 sm:text-sm sm:ml-0 sm:mt-0.875"
              >
                About Us
              </NavLink>
            </div>
          </div> */}
          <Logo size="120" color="#8B919D80" />
        </div>
      </NavsContainer>
    </div>
  );
};
export default Footer;
