export const TelegramIcon = (props: any) => {
  const { color = "white", size = 20, ...restProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clip-path="url(#clip0_31554_19445)">
        <path
          d="M15.559 3.24642L13.2653 14.0638C13.0922 14.8273 12.6409 15.0173 11.9996 14.6576L8.50465 12.0822L6.81825 13.7041C6.63163 13.8908 6.47554 14.0468 6.11587 14.0468L6.36696 10.4874L12.8445 4.63422C13.1261 4.38313 12.7834 4.24401 12.4068 4.4951L4.39893 9.53734L0.951481 8.45831C0.201593 8.22418 0.188021 7.70842 1.10757 7.34875L14.592 2.15382C15.2163 1.91969 15.7626 2.29294 15.559 3.24642Z"
          fill="white"
          fill-opacity="0.9"
        />
      </g>
      <defs>
        <clipPath id="clip0_31554_19445">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.00390625 0.577148)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
