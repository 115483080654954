import React, { useState } from "react";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useAppBreakpoints } from "../../hooks/useAppBreakpoints";
import { Popover, Typography, useTheme } from "@mui/material";

export default function MenuItems(props: any) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (e: any) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const { isMatchMobile } = useAppBreakpoints();
  return (
    <div className="sm:w-full" onMouseLeave={handlePopoverClose}>
      <Typography
        onMouseEnter={handlePopoverOpen}
        ///onMouseLeave={handlePopoverClose}
        className="cursor-pointer flex items-center sm:justify-between sm:w-full"
        id={props.id}
        color={anchorEl ? theme.palette.primary.main : "text.primary"}
      >
        <span>{props.label}</span>
        <KeyboardArrowDown
          color="inherit"
          className={`mt-0.25 ${anchorEl ? "rotate-180" : ""}`}
        />
      </Typography>
      {isMatchMobile ? (
        anchorEl ? (
          <div className="mt-0.5">{props.children}</div>
        ) : null
      ) : (
        <Popover
          onMouseLeave={handlePopoverClose}
          id={props.id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          sx={{
            top: "3px",
            //pointerEvents: "none",
          }}
        >
          {props.children}
        </Popover>
      )}
    </div>
  );
}
