import React from 'react';
import {MediumContent} from '../components';
import Paper from '@mui/material/Paper';


export default function Vision(){
  return (
    <div className='w-full flex py-3.75 sm:py-1.5 sm:pb-3 justify-center'>
      <MediumContent>
        <div className='grid grid-cols-2 sm:grid-cols-1 sm:gap-1.5 gap-2 w-full'>
          <Paper className='px-2 py-2.5 space-y-1.5 sm:space-y-0.75 sm:px-0.75 sm:py-1.5'>
            <div className='text-[40px] sm:text-2xl leading-[1.75] sm:leading-[70px] font-bold'>Vision</div>
            <div className='text-xl leading-normal  opacity-80 sm:text-sm'>Ensure a fair and equitable trading environment through open and transparent trading rules to leverage more DeFi liquidity.</div>
          </Paper>
          <Paper className='px-2 py-2.5 space-y-1.5 sm:space-y-0.75 sm:px-0.75 sm:py-1.5'>
            <div className='text-[40px] sm:text-2xl leading-[1.75] sm:leading-[70px] font-bold'>Mission</div>
            <div className='text-xl leading-normal opacity-80 sm:text-sm'>Create an anonymous, open source, convenient and fully community-driven decentralized perpetual contracts protocol.</div>
          </Paper>
        </div>
      </MediumContent>
    </div>
  )
}

