import React from "react";

import cn from "classnames";
import {
  Box,
  Typography,
  styled,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import advantageIcon1 from "../../assets/index/advantage/advantage-icon-1.png";
import advantageIcon2 from "../../assets/index/advantage/advantage-icon-2.png";

export default function Advantage() {
  const matchSmall = useMediaQuery("(max-width: 768px)");
  const theme = useTheme();
  return (
    <div className="w-full flex justify-center">
      <div className="content flex flex-col">
        <div className="grid grid-cols-1 sm:grid-cols-1 gap-10 sm:gap-2.5">
          <div className="flex justify-between items-center">
            <StyledBox className="w-[630px] bg-contain sm:w-full sm:bg-cover">
              <div className="inner px-11 py-2.75 sm:px-0.5 sm:py-1.75 ">
                {matchSmall && (
                  <div
                    className={cn(
                      "flex-1 flex items-center sm:w-full",
                      "justify-end  sm:justify-center"
                    )}
                  >
                    <img src={advantageIcon1} width={150} height={132} alt="" />
                  </div>
                )}
                <div className="font-medium text-[40px] sm:text-xl sm:text-center sm:mt-1.25">
                  Maximum Capital Efficiency
                </div>
                <Typography
                  className="text-xl leading-normal mt-1.75 opacity-80 sm:text-base sm:mt-0.75 sm:text-center"
                  color="text.primary"
                >
                  The innovative BRMM model brings CEX-like capital efficiency
                  to on-chain perpetuals, enabling us to provide zero trading
                  fees, substantial liquidity, and high leverage.
                </Typography>
              </div>
            </StyledBox>
            {!matchSmall && (
              <div
                className={cn(
                  "flex-1 flex items-center sm:w-full",
                  "justify-end  sm:justify-center"
                )}
              >
                <img src={advantageIcon1} width={313} height={272} alt="" />
              </div>
            )}
          </div>
          <div className="flex justify-between items-center">
            {!matchSmall && (
              <div
                className={cn(
                  "flex-1 flex items-center sm:w-full",
                  "justify-start  sm:justify-center"
                )}
              >
                <img src={advantageIcon2} width={313} height={272} alt="" />
              </div>
            )}
            <StyledBox className="w-[630px] bg-contain sm:w-full sm:bg-cover">
              <div className="inner px-11 py-2.75 sm:px-0.5 sm:py-1.75 ">
                {matchSmall && (
                  <div
                    className={cn(
                      "flex-1 flex items-center sm:w-full",
                      "justify-end  sm:justify-center"
                    )}
                  >
                    <img src={advantageIcon2} width={140} height={112} alt="" />
                  </div>
                )}
                <div className="font-medium text-[40px] sm:text-xl sm:text-center sm:mt-1.75">
                  Fair Launch
                </div>
                <Typography
                  className="text-xl leading-normal mt-1.75 opacity-80 sm:text-base sm:mt-0.5 sm:text-center"
                  color="text.primary"
                >
                  EQU is the native token of Equation, with a maximum supply of
                  10 million, 100% of which is generated through mining,
                  rewarded to community users.
                </Typography>
              </div>
            </StyledBox>
          </div>
        </div>
      </div>
    </div>
  );
}

const StyledBox = styled(Box)`
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.palette.primary.main} 0%,
    transparent 30%,
    transparent 50%,
    transparent 70%,
    ${({ theme }) => theme.palette.primary.main} 100%
  );
  border-radius: 4px;
  padding: 1px;
  .inner {
    background: linear-gradient(79deg, #171717 0%, #24291e 120%);
    border-radius: 4px;
  }
`;
