import React from "react";
import { useOutlet, useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import Header from "./header";
import Footer from "./footer";
import Notify from "pages/index/notify";
import cn from "classnames";

const RootContainer = styled("div")<any>(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  color: theme.palette.text.primary,
  overflowY: "scroll",
  overflowX: "hidden",
  display: "flex",
  flexDirection: "column",
  background: theme.palette.background.default,
  ".evenBg": {
    background: theme.palette.background.default,
  },
  ".oddBg": {
    background: theme.palette.background.default,
  },
  ".block-title": {
    color: theme.palette.text.primary,
    fontSize: 40,
    lineHeight: 1.5,
    "@media screen and (max-width: 768px)": {
      lineHeight: 1.2,
      fontSize: 22,
    },
  },
  ".block-sub-title": {
    fontSize: 20,
    lineHeight: 1.22,
    marginTop: theme.spacing(2),
    color: alpha(theme.palette.common.white, 0.7),
    "@media screen and (max-width: 768px)": {
      marginTop: 12,
      fontSize: 14,
    },
  },
}));

const Content = styled("div")<any>(({ theme, isIndex }) => ({
  color: theme.palette.text.primary,
  position: "relative",
}));

export default function BasicLayout() {
  const outlet = useOutlet();
  const location = useLocation();
  const isIndex = location.pathname === "/";
  return (
    <RootContainer id="app" className={cn("relative flex  flex-col")}>
      <StyledLinearBg>
        <div className="leftLinear"></div>
        <div className="rightLinear"></div>
      </StyledLinearBg>
      {/*<Notify />*/}
      <Header />
      <Content className="flex-grow" isindex={isIndex}>
        {outlet}
      </Content>
      <Footer />
    </RootContainer>
  );
}

export const StyledLinearBg = styled("div")`
  width: 100%;
  .leftLinear {
    width: 652.83px;
    height: 280.77px;
    top: 10px;
    left: -100px;
    transform: rotate(-135deg);
    position: absolute;
    background: linear-gradient(91deg, #3700d5 15.35%, #0ee 79.12%);
    opacity: 0.6;
    filter: blur(179.5px);
    border-radius: 50%;
    @media (max-width: 767px) {
      width: 362.078px;
      height: 155.722px;
      transform: rotate(-135deg);
      filter: blur(100px);
      top: -25px;
      left: -130px;
      border-radius: 0;
    }
  }
  .rightLinear {
    background: linear-gradient(
      101deg,
      rgba(148, 176, 0, 0.7) 24.9%,
      rgba(0, 238, 181, 0.7) 55.08%
    );
    width: 816.07px;
    height: 374.81px;
    opacity: 0.6;
    filter: blur(150px);
    position: absolute;
    top: 20%;
    right: 50px;
    transform: rotate(-144.762deg);
    border-radius: 50%;
    @media (max-width: 767px) {
      width: 359.715px;
      height: 165.214px;
      transform: rotate(-135deg);
      filter: blur(75px);
      top: 100px;
      right: -110px;
      border-radius: 0;
    }
  }
`;
