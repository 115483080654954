import React from 'react';
import {styled} from '@mui/material';

export const Content = styled("div")(({ theme }) => ({
  "@media screen and (max-width: 768px)": {
    paddingTop: `16px`,
    paddingBottom: `0`,
  },
  "@media screen and (min-width: 961px) and (max-width: 1200px)": {
    paddingTop: `60px`,
    paddingBottom: `70px`,
  },
}));

export const MediumContent = styled("div")`
  width: 840px;
  @media screen and (max-width: 768px){
    padding-top: 16px;
    padding-bottom: 0;
    width: 100%;
    padding: 16px;
  }
  @media screen and (min-width: 961px) and (max-width: 1200px){
    padding-top: 60px;
    padding-bottom: 70px;
  };
`