import React from "react";
import { Link } from "react-router-dom";

const Logo = (props: any) => {
  const { size = 185, color = "#C7EE00", ...restProps } = props;
  return (
    <Link className="logo text-xl inline-block" to="/" {...restProps}>
      <svg
        width={size}
        viewBox="0 0 1025 203"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M175.779 49.571L94.1333 91.7066C93.1446 92.2169 91.9702 92.2173 90.9812 91.7078L8.81755 49.3793C6.3272 48.0964 6.3335 44.5336 8.82836 43.2594L90.7564 1.41809C91.7411 0.915199 92.9075 0.916421 93.8911 1.42137L175.772 43.4556C178.261 44.7329 178.264 48.2883 175.779 49.571Z"
          fill={color}
        />
        <path
          d="M1.8492 159.774L79.085 200.023C81.3745 201.216 84.1133 199.555 84.1133 196.973V108.458C84.1133 107.171 83.3945 105.991 82.2506 105.401L5.0148 65.5689C2.72594 64.3885 -0.000526428 66.0501 -0.000526428 68.6254V156.724C-0.000526428 158.006 0.712425 159.181 1.8492 159.774Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.2055 162.711C61.4269 165.78 58.1599 168.706 55.2436 167.155L50.7704 164.776C50.0294 164.382 49.4521 163.738 49.1412 162.958L41.5643 143.962L34.5048 153.045C33.4564 154.394 31.5956 154.804 30.0774 154.021L25.2207 151.516C23.2445 150.496 22.6826 147.931 24.0516 146.179L36.2309 130.591L24.865 102.096C23.6483 99.0451 26.8727 96.1215 29.7899 97.6303L34.2969 99.9615C35.0557 100.354 35.6473 101.007 35.9632 101.801L43.645 121.102L50.8123 111.929C51.8624 110.585 53.7203 110.178 55.2357 110.961L59.9901 113.417C61.9618 114.435 62.5249 116.993 61.1631 118.746L48.9575 134.45L60.2055 162.711Z"
          fill="#111111"
        />
        <path
          d="M183.143 159.761L105.907 200.01C103.618 201.203 100.879 199.542 100.879 196.96V108.445C100.879 107.158 101.598 105.979 102.742 105.389L179.977 65.5563C182.266 64.3758 184.993 66.0374 184.993 68.6127V156.711C184.993 157.993 184.28 159.168 183.143 159.761Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.199 22.0147C102.234 21.4811 103.465 21.4891 104.492 22.0363L110.152 25.05C112.678 26.3947 112.654 30.0227 110.111 31.3342L61.0992 56.6106C60.0644 57.1443 58.8337 57.1362 57.806 56.589L52.1459 53.5754C49.6202 52.2306 49.644 48.6026 52.1871 47.2911L101.199 22.0147ZM126.591 34.7145C127.626 34.1802 128.857 34.1881 129.885 34.7355L135.547 37.7501C138.073 39.0946 138.049 42.7216 135.507 44.0338L85.9012 69.6398C84.8662 70.174 83.6349 70.1662 82.6068 69.6188L76.9448 66.6041C74.4196 65.2596 74.4427 61.6327 76.9848 60.3204L126.591 34.7145Z"
          fill="#111111"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.518 103.269C149.518 100.656 146.789 98.9394 144.434 100.071L139.028 102.669C137.799 103.26 137.017 104.503 137.017 105.867V129.237L115.96 139.358C114.731 139.948 113.949 141.191 113.949 142.555L113.949 148.553C113.949 151.166 116.678 152.882 119.033 151.75L137.017 143.107V163.064C137.017 165.677 139.746 167.393 142.101 166.262L147.507 163.663C148.736 163.073 149.518 161.83 149.518 160.466V137.099L170.586 126.973C171.815 126.382 172.596 125.139 172.596 123.776V117.778C172.596 115.165 169.867 113.449 167.512 114.58L149.518 123.229V103.269Z"
          fill="#111111"
        />
        <path
          d="M274.292 110.506V135.659H335.777V154.752H252.336V48.6832H335.777V67.7756H274.292V92.0198V110.506Z"
          fill={color}
        />
        <path
          d="M458.583 101.708C458.583 116.861 452.526 130.498 442.381 140.348L457.508 156.409H431.248L426.785 151.106C419.365 154.591 411.037 156.409 402.406 156.409C370.759 156.409 346.38 132.014 346.38 101.708C346.38 71.403 370.759 47.0072 402.406 47.0072C434.507 47.0072 458.583 71.7061 458.583 101.708ZM368.942 101.708C368.942 121.255 383.327 136.256 402.406 136.256C406.191 136.256 410.128 135.65 413.308 134.438L397.947 117.058H423.412L428.905 123.377C433.296 117.77 436.021 110.042 436.021 101.708C436.021 81.8583 421.333 67.1603 402.406 67.1603C383.478 67.1603 368.942 82.0099 368.942 101.708Z"
          fill={color}
        />
        <path
          d="M563.262 108.839C563.262 137.781 544.94 156.267 516.321 156.267C487.854 156.267 469.381 138.084 469.381 108.839V48.6832H491.337V108.839C491.337 125.204 501.33 135.963 516.473 135.963C531.615 135.963 541.306 125.204 541.306 108.839V48.6832H563.262V108.839Z"
          fill={color}
        />
        <path
          d="M645.014 132.12H601.253L590.351 154.554H567.487L611.096 48.4855H636.534L679.538 154.554H655.765L645.014 132.12ZM608.067 114.543H638.351L623.361 70.0022L608.067 114.543Z"
          fill={color}
        />
        <path
          d="M728.79 67.8809H706.835H671.099V48.4855H763.996V67.8809H728.79Z"
          fill={color}
        />
        <path
          d="M774.59 48.4855H796.546V154.554H774.59V48.4855Z"
          fill={color}
        />
        <path
          d="M919.374 101.553C919.374 131.555 895.298 156.254 863.197 156.254C831.55 156.254 807.171 131.858 807.171 101.553C807.171 71.2473 831.55 46.8516 863.197 46.8516C895.298 46.8516 919.374 71.5504 919.374 101.553ZM829.733 101.553C829.733 121.1 844.118 136.101 863.197 136.101C882.276 136.101 896.812 121.251 896.812 101.553C896.812 81.7027 882.124 67.0046 863.197 67.0046C844.269 67.0046 829.733 81.8542 829.733 101.553Z"
          fill={color}
        />
        <path
          d="M950.835 48.4855L1003.53 117.127V48.4855H1024.12V154.554H1004.74L950.683 83.6396V154.554H929.939V48.4855H950.835Z"
          fill={color}
        />
        <path
          d="M274.104 111.049L274.104 91.9865L324.317 91.9865L324.317 111.05L274.104 111.049Z"
          fill={color}
        />
        <path
          d="M706.689 67.4424H728.613V154.393L706.688 154.393L706.689 67.4424Z"
          fill={color}
        />
      </svg>
    </Link>
  );
};

export default Logo;
