import { useMemo, useEffect } from "react";
import { useMediaQuery, useTheme, alpha, lighten } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { usePoolsGraph } from "graphql/usePoolsGraph";
import useMarketGraph from "graphql/useMarketGraph";
import { plus, toQuoteAmount } from "utils";
import { isEmpty } from "lodash";
import { useAppSelector } from "state/hooks";
import { globalBaseState } from "state/global/slice";
import useV3MarketGraph from "../../graphql/useV3MarketGraph";

interface SummaryDataProps {
  volume: number | string;
  volume24H: number | string;
  liquidity: number | string;
  riskBufferFund: number | string;
  openInterest: number | string;
}

export default function Summary() {
  const matchSmall = useMediaQuery("(max-width: 768px)");
  const { poolList, refetchPools } = usePoolsGraph();
  const { data: poolsV2 } = useMarketGraph();
  const { data: poolsV3 } = useV3MarketGraph();

  const { appTimer } = useAppSelector(globalBaseState);

  useEffect(() => {
    if (!appTimer) {
      return;
    }
    refetchPools();
  }, [appTimer]);

  const summaryData = useMemo(() => {
    if (isEmpty(poolList) || isEmpty(poolsV2)) {
      return {
        volume: 0,
        openInterest: 0,
        volume24H: 0,
        liquidity: 0,
        markets: 0,
      };
    }

    const poolsV2Data = poolsV2.reduce(
      (all: SummaryDataProps, current: any) => {
        return {
          ...all,
          volume: plus(all.volume, current?.volumeUSD),
          volume24H: plus(all.volume24H, current?.volume24H),
          liquidity: plus(
            all.liquidity,
            current.globalLiquidityPosition.liquidity
          ),
          openInterest: plus(all.openInterest, current.openInterestValue),
        };
      },
      {
        volume: 0,
        openInterest: 0,
        liquidity: 0,
        volume24H: 0,
      }
    );

    const poolsV1Data = poolList.reduce(
      (all: SummaryDataProps, current: any) => {
        return {
          ...all,
          volume: plus(all.volume, current?.volumeUSD),
          volume24H: plus(all.volume24H, current?.volume24H),
          liquidity: plus(
            all.liquidity,
            current.globalLiquidityPosition.liquidity
          ),
          riskBufferFund: plus(all.riskBufferFund, current.riskBufferFund),
          openInterest: plus(all.openInterest, current.openInterestValue),
        };
      },
      {
        volume: 0,
        liquidity: 0,
        riskBufferFund: 0,
        openInterest: 0,
        volume24H: 0,
      }
    );
    const poolsV3Data = poolsV3.reduce(
      (all: SummaryDataProps, current: any) => {
        return {
          ...all,
          volume: plus(all.volume, current?.volumeUSD),
          volume24H: plus(all.volume24H, current?.volume24H),
          liquidity: plus(
            all.liquidity,
            current.globalLiquidityPosition.liquidity
          ),
          openInterest: plus(all.openInterest, current.openInterestValue),
        };
      },
      {
        volume: 0,
        openInterest: 0,
        liquidity: 0,
        volume24H: 0,
      }
    );

    return {
      volume: plus(
        plus(poolsV1Data?.volume, poolsV2Data?.volume),
        poolsV3Data?.volume
      ),
      volume24H: plus(
        plus(poolsV1Data?.volume24H, poolsV2Data?.volume24H),
        poolsV3Data?.volume24H
      ),
      liquidity: plus(
        plus(poolsV1Data?.liquidity, poolsV2Data?.liquidity),
        poolsV3Data?.liquidity
      ),
      openInterest: plus(
        plus(poolsV1Data?.openInterest, poolsV2Data?.openInterest),
        poolsV3Data?.openInterest
      ),
      markets: poolsV3.length || 55,
    };
  }, [poolList, poolsV2, poolsV3]);

  const theme = useTheme();

  return (
    <Box
      className="w-full flex justify-center sm:mt-2.5 relative py-1.25"
      sx={{
        background: matchSmall ? "inherit" : alpha("#FFFFFF", 0.06),
        backdropFilter: "blur(50px)",
      }}
    >
      <div className="content flex justify-center">
        <div className="flex justify-center items-center gap-6.25 sm:grid-cols-1 sm:grid sm:space-x-0 sm:justify-between sm:gap-y-2 sm:gap-0">
          <Typography align="center" className="space-y-0.25 sm:space-y-0">
            <div className="font-[400] text-Golos text-[32px] sm:text-[22px]">
              {summaryData?.volume === 0
                ? "-"
                : `$${toQuoteAmount(summaryData?.volume, 0)}`}
            </div>
            <Typography color="text.secondary" className="sm:text-sm">
              Total Volume
            </Typography>
          </Typography>
          <Typography align="center" className="space-y-0.25 sm:space-y-0">
            <div className="font-[400] text-Golos text-[32px] sm:text-[22px]">
              {summaryData?.volume24H === 0
                ? "-"
                : `$${toQuoteAmount(summaryData?.volume24H, 0)}`}
            </div>
            <Typography color="text.secondary" className="sm:text-sm">
              24h Volume
            </Typography>
          </Typography>
          <Typography align="center" className="space-y-0.25 sm:space-y-0">
            <div className="font-[400] text-[32px] sm:text-[22px] text-Golos">
              {summaryData.liquidity === 0
                ? "-"
                : `$${toQuoteAmount(summaryData?.liquidity, 0)}`}
            </div>
            <Typography
              variant="inherit"
              color="text.secondary"
              className="sm:text-sm"
            >
              Leveraged Liquidity
            </Typography>
          </Typography>
          <Typography align="center" className="space-y-0.25 sm:space-y-0">
            <div className="font-[400] text-[32px] sm:text-[22px] text-Golos">
              {summaryData?.markets ? summaryData?.markets : "-"}
            </div>
            <Typography
              variant="inherit"
              color="text.secondary"
              className="sm:text-sm"
            >
              Markets
            </Typography>
          </Typography>
        </div>
      </div>
    </Box>
  );
}
