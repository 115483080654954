import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  MenuItemProps,
} from "@mui/material";
import cn from "classnames";
import { useLocation, NavLink } from "react-router-dom";

import { styled } from "@mui/material";
import MenuItems from "./menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  ForumIcon,
  DiscordIcon,
  TwitterIcon,
  TelegramIcon,
  MediumIcon,
} from "../../components/svg-icon";

import { useAppBreakpoints } from "hooks/useAppBreakpoints";

const Navs = (props: any) => {
  let location = useLocation();
  let path = location.pathname.replace("/", "");
  const theme = useTheme();
  const { isMatchMobile } = useAppBreakpoints();
  return (
    <Box className={cn("sm:pb-2.75")}>
      <div className="flex w-full sm:flex-grow items-center sm:items-start sm:justify-between sm:flex-col gap-x-2.5 sm:gap-x-0 sm:gap-y-2">
        <StyledNavLink
          href="https://docs.equation.org/"
          target="_blank"
          className="font-medium"
        >
          Whitepaper
        </StyledNavLink>
        <StyledNavLink
          href="https://github.com/EquationDAO"
          target="_blank"
          className="font-medium"
        >
          Github
        </StyledNavLink>
        <StyledNavLink
          href="https://stats.equation.trade/"
          target="_blank"
          className="font-medium"
        >
          Stats
        </StyledNavLink>
        <StyledNavLink
          href="https://snapshot.org/#/equationdao.eth"
          target="_blank"
          className="font-medium"
        >
          Voting
        </StyledNavLink>
        <MenuItems label="Community" id="community">
          <StyledMenuItem
            component="a"
            target="_blank"
            href="https://twitter.com/EquationDAO"
            className="gap-x-0.75"
          >
            <TwitterIcon />
            <span className="text-textPrimary90 text-base sm:text-base">
              Twitter
            </span>
          </StyledMenuItem>
          <StyledMenuItem
            component="a"
            target="_blank"
            href="https://discord.gg/equationdao"
            className="gap-x-0.75"
          >
            <DiscordIcon />
            <span className="text-textPrimary90 text-base sm:text-base">
              Discord
            </span>
          </StyledMenuItem>
          <StyledMenuItem
            component="a"
            target="_blank"
            href="https://t.me/EquationDao"
            className="gap-x-0.75"
          >
            <TelegramIcon />
            <span className="text-textPrimary90 text-base sm:text-base">
              Telegram
            </span>
          </StyledMenuItem>
          <StyledMenuItem
            component="a"
            target="_blank"
            href="https://medium.com/@EquationDAO"
            className="gap-x-0.75"
          >
            <MediumIcon />
            <span className="text-textPrimary90 text-base sm:text-base">
              Medium
            </span>
          </StyledMenuItem>
          <StyledMenuItem
            component="a"
            target="_blank"
            href="https://commonwealth.im/equationdao"
            className="gap-x-0.75 flex items-center"
          >
            <ForumIcon />
            <span className="text-textPrimary90 text-base sm:text-base">
              Forum
            </span>
          </StyledMenuItem>
        </MenuItems>
      </div>
    </Box>
  );
};

const StyledNavLink = styled("a")`
  color: ${({ theme }) => theme.palette.text.primary};
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  @media screen and (max-width: 767px) {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

interface ExtendMenuItemProps extends MenuItemProps {
  component?: "a";
  target?: string;
  href?: string;
}
const StyledMenuItem = styled(MenuItem)<ExtendMenuItemProps>`
  @media screen and (max-width: 767px) {
    color: ${({ theme }) => theme.palette.text.primary};
    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    padding: 6px 0;
    min-height: auto;
  }
`;

export default Navs;
