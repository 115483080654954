import { useEffect, useState } from "react";

import { useMultiTokens, useTokensPrice } from "hooks/useRequest";
import { IPoolItem } from "types";

import { usePoolsQuery } from "./__generated_trade__/types-and-hooks";
import { LiquidityPositionUtil } from "../entities/LiquidityPositionUtil";
import { div, parseUnits, plus, minus, multipliedBy, isPositive } from "utils";
import { useAllTokens } from "hooks/useAllTokens";
import Decimal from "decimal.js";
import { DEFAULT_QUOTE_PRECISION } from "config/constants";
import { useAppSelector } from "state/hooks";
import { globalBaseState } from "state/global/slice";

export function usePoolsGraph() {
  const [loading, setLoading] = useState<boolean>(true);
  const [poolList, setPoolList] = useState<Array<IPoolItem>>([]);
  const [poolMap, setPoolMap] = useState<Map<string, IPoolItem> | null>(null);
  const allTokens = useAllTokens();
  const { blockHeightBefore24h } = useAppSelector(globalBaseState);
  const {
    data,
    loading: isLoading,
    refetch,
  } = usePoolsQuery({
    variables: { blockBefore: Number(blockHeightBefore24h) },
    skip: !isPositive(blockHeightBefore24h),
  });

  const { tokensMultiPrice } = useMultiTokens(
    data?.pools?.map((pool) => pool.token.id) || null
  );

  useEffect(() => {
    if (!isLoading) {
      setLoading(isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!data || !data.pools || !tokensMultiPrice) {
      return;
    }
    const map = new Map<string, IPoolItem>();
    const { pools, poolsBefore1D } = data;

    const list = [] as Array<IPoolItem>;
    pools.forEach((item) => {
      const id = item.id;
      const baseToken = allTokens.get(item.token.id);
      const tokenMultiPriceItem = tokensMultiPrice?.tokens?.find(
        (tokenItem: any) => tokenItem.address.toLowerCase() === item.token.id
      );

      if (!tokenMultiPriceItem || !baseToken) {
        return;
      }

      const before24HData = poolsBefore1D.find((pool) => pool.id === id);

      const indexPriceX96 = tokenMultiPriceItem.index_price_x96;

      const _globalUnrealizedPnl =
        LiquidityPositionUtil.calculateGlobalUnrealizedPnl(
          item.globalLiquidityPosition.side,
          parseUnits(
            plus(
              item.globalLiquidityPosition.netSize,
              item.globalLiquidityPosition.liquidationBufferNetSize
            ),
            baseToken.decimals
          ),
          item.globalLiquidityPosition.entryPriceX96,
          indexPriceX96
        );

      const globalUnrealizedPnl = div(
        _globalUnrealizedPnl,
        Decimal.pow(10, DEFAULT_QUOTE_PRECISION)
      );

      const realizedPnL = minus(
        item.globalRiskBufferFund.riskBufferFund,
        item.globalRiskBufferFund.liquidity
      );

      const openInterestVolumeBase = Math.max(
        Number(item.globalPosition.longSize),
        Number(item.globalPosition.shortSize)
      );
      const openInterestValue = multipliedBy(
        multipliedBy(openInterestVolumeBase, 2),
        tokenMultiPriceItem?.index_price
      );

      const poolItem = {
        ...tokenMultiPriceItem,
        ...item,
        riskBufferFund: plus(
          item.globalRiskBufferFund.riskBufferFund,
          globalUnrealizedPnl
        ),
        openInterestValue,
        indexPrice: tokenMultiPriceItem?.index_price,
        volume24H: minus(item?.volumeUSD, before24HData?.volumeUSD || 0),
      } as IPoolItem;

      map.set(id, poolItem);

      list.push(poolItem);
    });

    setPoolList(list);
    setPoolMap(map);
  }, [data, tokensMultiPrice, allTokens]);

  return {
    poolList,
    poolMap,
    loading,
    refetchPools: refetch,
  };
}
