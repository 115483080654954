export const TwitterIcon = (props: any) => {
  const { color = "white", size = 20, ...restProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clip-path="url(#clip0_31536_18895)">
        <path
          d="M14.0878 5.45782C14.0163 10.8578 10.5631 14.5565 5.40837 14.7887C3.28262 14.8861 1.74259 14.1994 0.402344 13.348C1.97347 13.5987 3.92165 12.9707 4.96358 12.0788C3.42355 11.929 2.51175 11.1451 2.08511 9.88357C2.53012 9.9605 2.99894 9.9401 3.42172 9.85057C2.03203 9.38537 1.03965 8.52646 0.98838 6.72669C1.37835 6.90424 1.78489 7.07114 2.32499 7.10405C1.28496 6.51248 0.51592 4.34996 1.39673 2.91998C2.94028 4.61185 4.797 5.99247 7.84577 6.17923C7.08034 2.90717 11.4164 1.13282 13.2311 3.33197C13.9982 3.18356 14.6226 2.89252 15.2233 2.57573C14.976 3.3356 14.4999 3.86662 13.9196 4.29142C14.5567 4.20541 15.1206 4.04974 15.6023 3.8117C15.3035 4.43245 14.6501 4.98911 14.0878 5.45782Z"
          fill="white"
          fill-opacity="0.9"
        />
      </g>
      <defs>
        <clipPath id="clip0_31536_18895">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.00390625 0.577148)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
