

export const PlusIcon = (props: any) => {
  const { color, size, ...restProps } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 23 25" fill="none" {...restProps}>
      <path d="M22.7974 9.10377V15.6472H0.201172V9.10377H22.7974ZM15.0993 0.5V24.5H7.96721V0.5H15.0993Z" fill="url(#paint0_linear_4071_21394)"/>
      <defs>
      <linearGradient id="paint0_linear_4071_21394" x1="7.91546" y1="0.928571" x2="17.1506" y2="31.863" gradientUnits="userSpaceOnUse">
      <stop stop-color="#C7EE00"/>
      <stop offset="1" stop-color="#0BF34C"/>
      </linearGradient>
      </defs>
    </svg>
  )
}