import React from 'react';
import {styled} from '@mui/material';
import {MediumContent} from '../components';
import Introduce from './introduce';
import Vision from './vision';
import topicSupport from '../../assets/about/topic-support.svg';

export default function About(){
  return (
    <>
      <div className='w-full flex justify-center mt-3.5 sm:mt-2 relative'>
        <MediumContent >
          <div className='grid grid-cols-6 sm:grid-cols-4 gap-1 w-full'>
            <div className='col-span-4 sm:col-span-4'>
              <div className='text-[80px] sm:text-[36px] leading-normal font-bold'>
                It's time to join <div className='text-primary'>Equation!</div>
              </div>
            </div>
            <div className='col-span-2 sm:hidden'><img src={topicSupport} alt='' /></div>
          </div>
        </MediumContent>
      </div>
      <Introduce />
      <Vision />
    </>
  )
}

