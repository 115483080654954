export const ForumIcon = (props: any) => {
  const { color = "white", size = 20, ...restProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clip-path="url(#clip0_31536_18905)">
        <path
          d="M13.5697 11.6921C13.2098 11.6921 12.9153 11.3858 12.9153 11.0113C12.9153 10.6369 13.2098 10.3305 13.5697 10.3305C13.766 10.3305 13.8969 10.1944 13.8969 9.99013V3.07994C13.8969 2.8757 13.766 2.73954 13.5697 2.73954H3.75326C3.55693 2.73954 3.42605 2.8757 3.42605 3.07994V3.42035C3.42605 3.79479 3.13155 4.10115 2.77162 4.10115C2.41168 4.10115 2.11719 3.79479 2.11719 3.42035V3.07994C2.11719 2.12682 2.83706 1.37793 3.75326 1.37793H13.5697C14.4859 1.37793 15.2058 2.12682 15.2058 3.07994V9.99013C15.2058 10.9433 14.4859 11.6921 13.5697 11.6921Z"
          fill="white"
          fill-opacity="0.9"
        />
        <path
          d="M10.9483 4.40723H2.44076C1.52456 4.40723 0.804687 5.15611 0.804688 6.10924V12.3727C0.804688 13.3258 1.52456 14.0747 2.44076 14.0747H2.89886V14.7555C2.89886 15.164 3.12791 15.5384 3.52057 15.7086C3.65145 15.7767 3.78234 15.7767 3.91322 15.7767C4.175 15.7767 4.40405 15.6746 4.60037 15.4703L5.94195 14.0747H10.9483C11.8645 14.0747 12.5844 13.3258 12.5844 12.3727V6.10924C12.5844 5.19015 11.8645 4.40723 10.9483 4.40723ZM8.26518 11.3174H3.55329C3.19335 11.3174 2.89886 11.011 2.89886 10.6366C2.89886 10.2622 3.19335 9.9558 3.55329 9.9558H8.26518C8.62511 9.9558 8.91961 10.2622 8.91961 10.6366C8.91961 11.011 8.62511 11.3174 8.26518 11.3174ZM9.8358 8.56014H3.55329C3.19335 8.56014 2.89886 8.25378 2.89886 7.87934C2.89886 7.50489 3.19335 7.19853 3.55329 7.19853H9.8358C10.1957 7.19853 10.4902 7.50489 10.4902 7.87934C10.4902 8.25378 10.1957 8.56014 9.8358 8.56014Z"
          fill="white"
          fill-opacity="0.9"
        />
      </g>
      <defs>
        <clipPath id="clip0_31536_18905">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.00390625 0.577148)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
