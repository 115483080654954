import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";

export default function Tooltip(props: TooltipProps & { noTooltip?: boolean }) {
  const { children, placement = "top" } = props;

  if (props.noTooltip) {
    return children;
  }

  return (
    <MuiTooltip
      enterTouchDelay={0}
      arrow={true}
      placement={placement}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
}
