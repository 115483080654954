import "./App.scss";
import { useMemo, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import getTheme from "./theme";
import AppRouter from "./route";
import { enableMapSet } from "immer";

import GlobalUpdater from "state/global/updater";
import { Hash } from "@wagmi/core";
import GlobalComponents from "components/GlobalComponents";
import TransactionSnackbar from "components/TransactionSnackbar";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import {
  ApolloClient,
  ApolloProvider,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import ApolloLinkTimeout from "apollo-link-timeout";
import { GRAPH_API_URL } from "config/env";
import { ApolloClientsProvider } from "context/ApolloClientsContext";
import { useCurrentChain } from "hooks/useCurrentChain";

enableMapSet();

declare module "notistack" {
  interface VariantOverrides {
    TransactionSnackbar: {
      description: string;
      txHash: Hash | undefined;
      type: string;
      more?: string;
    };
  }
}

const SnackbarProviderComponent = (props: SnackbarProviderProps) => {
  return (
    <SnackbarProvider
      maxSnack={5}
      Components={{
        TransactionSnackbar,
      }}
      autoHideDuration={8000}
      dense={true}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};
function Updaters() {
  return (
    <>
      <GlobalUpdater />
    </>
  );
}
function App() {
  const [themeMode] = useState("dark");
  const createThemeMode = useMemo(() => {
    const themeConfig = getTheme(themeMode || "dark");
    return themeConfig;
  }, [themeMode]);
  const modeTheme = createTheme(createThemeMode);
  const { currentChainId } = useCurrentChain();
  const defaultOptions: DefaultOptions = useMemo(() => {
    return {
      watchQuery: { fetchPolicy: "no-cache", errorPolicy: "ignore" },
      query: { fetchPolicy: "no-cache", errorPolicy: "all" },
    };
  }, []);

  const timeoutLink = new ApolloLinkTimeout(30000);

  const httpLinkTradeV1 = useMemo(() => {
    return new HttpLink({
      uri: GRAPH_API_URL[currentChainId].Trade,
    });
  }, [currentChainId]);

  const clientTrade = useMemo(() => {
    return new ApolloClient({
      link: concat(timeoutLink, httpLinkTradeV1),
      cache: new InMemoryCache(),
      defaultOptions,
    });
  }, [httpLinkTradeV1]);

  const httpLinkTradeV2 = useMemo(() => {
    return new HttpLink({
      uri: GRAPH_API_URL[currentChainId].TradeV2,
    });
  }, [currentChainId]);
  const clientTradeV2 = useMemo(() => {
    return new ApolloClient({
      link: concat(timeoutLink, httpLinkTradeV2),
      cache: new InMemoryCache(),
      defaultOptions,
    });
  }, [httpLinkTradeV2]);
  const httpLinkTradeV3 = useMemo(() => {
    return new HttpLink({
      uri: GRAPH_API_URL[currentChainId].TradeV3,
    });
  }, [currentChainId]);
  const clientTradeV3 = useMemo(() => {
    return new ApolloClient({
      link: concat(timeoutLink, httpLinkTradeV3),
      cache: new InMemoryCache(),
      defaultOptions,
    });
  }, [httpLinkTradeV2]);

  return (
    <ThemeProvider theme={modeTheme}>
      <ApolloProvider client={clientTrade}>
        <ApolloClientsProvider
          clients={{
            clientTrade,
            clientTradeV2,
            clientTradeV3,
          }}
        >
          <SnackbarProviderComponent>
            {/* <GlobalComponents /> */}
            <Updaters />
            <AppRouter />
          </SnackbarProviderComponent>
        </ApolloClientsProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
